/* eslint-disable no-template-curly-in-string */
export default {
  onboarding: {
    title: 'Nouveauté',
    info_alert: "Bienvenue sur la nouvelle version de votre interface d'administration.",
    intro:
      "Nous avons travaillé sur son développement pendant plusieurs mois et espérons qu'elle réponde à vos besoins et améliore la gestion de votre application au quotidien.",
    tests: 'Nous réalisons encore quelques tests sur cette nouvelle interface.',
    new_features:
      "<strong>Elle sera donc pour le moment utilisée uniquement pour la gestion de nos nouvelles fonctionnalités</strong>, avant de remplacer totalement l'ancienne interface d'administration.",
    more_information:
      'Pour en savoir plus, <a class="underline" target="_blank" href="https://help.neocity.fr/hc/fr/articles/5768664282780-Présentation-du-nouveau-Back-Office-Neocity-/">cliquez ici</a>.',
    participate_design:
      'Vous souhaitez participer à la conception de votre nouvelle interface d\'administration ? <a class="underline" target="_blank" href="https://neocity.typeform.com/to/XY6Wtd9g#client_id=%{clientId}&client_name=%{clientName}&user_email_address=%{userEmail}/">Inscrivez-vous ici.</a>',
    not_warn_anymore: 'Ne plus me prévenir',
    confirm: 'Continuer',
  },
  create_theme: {
    confirm: 'Créer et assigner',
  },
  deleteHeaderImage: {
    title: "Supprimer l'image de l'en-tête",
    text: "Voulez-vous supprimer l'image de l'en-tête ? Cette action est irréversible.",
  },
  deleteFile: {
    titleImage: "Supprimer l'image",
    titleFile: 'Supprimer le fichier',
    textImage: "Voulez-vous supprimer l'image? Cette action est irréversible.",
    textFile: 'Voulez-vous supprimer le fichier? Cette action est irréversible.',
  },
  deleteMessage: {
    title: 'Supprimer un message',
    text: 'Voulez-vous supprimer ce message ? Cette action est irréversible.',
  },
};
