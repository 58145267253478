<template>
  <div class="flex flex-col overflow-hidden bg-neutral-soft" :style="{ minHeight: windowHeight + 'px' }">
    <ToastGroup />

    <Onboarding />

    <Dirty />

    <AppLayout />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useTitle, useWindowSize } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import figlet from 'figlet';
// eslint-disable-next-line
import font from 'figlet/importable-fonts/3D-ASCII.js';
import { set } from 'vue-gtag';
import { useClient } from '@/stores/client';
import { useUser } from '@/stores/user';
import { ZendeskWidgetCommander, ZendeskWidgetSettings } from '@/models/zendesk.model';

declare const APP_VERSION: string;

declare global {
  interface Window {
    zESettings: ZendeskWidgetSettings;
    zE: ZendeskWidgetCommander;
  }
}

const { t } = useI18n();
const clientStore = useClient();
const userStore = useUser();

useTitle(`Neocity - ${t('views.administrationInterface')}`);

const { height: windowHeight } = useWindowSize();

figlet.parseFont('3D-ASCII', font);

const initZendeskWebWidget = async (): Promise<void> => {
  const response = await fetch(`${import.meta.env.VITE_APP_APIV2_URL}zendesk/token`, {
    method: 'GET',
    headers: {
      Authorization: userStore?.authToken,
    },
  });

  const data = await response.json();
  const authenticate: { jwtFn: (callback: (token: string) => void) => void } = {
    jwtFn(callback) {
      callback(data.token);
    },
  };

  const zendeskToken = '64d16380-ffe1-4c5f-a905-fc93ac31c679';
  const script = document.createElement('script');
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskToken}`;
  script.async = true;
  script.id = 'ze-snippet';
  document.body.appendChild(script);

  const zendeskSettings = {
    webWidget: {
      answerBot: {
        avatar: {
          url: 'https://neocitybacko.s3.eu-west-1.amazonaws.com/neocity/logo.png',
          name: { '*': 'Zendesk' },
        },
        title: {
          '*': 'Support',
        },
      },
      authenticate,
    },
  };
  window.zESettings = zendeskSettings;
  setTimeout(() => {
    window.zE('webWidget', 'prefill', { email: { value: data.email } });
  }, 4000);
};

onMounted(() => {
  if (process.env.NODE_ENV === 'production') {
    figlet.text('Neocity', { font: '3D-ASCII' }, (err, data) => {
      if (err) return;
      // eslint-disable-next-line
      console.log(data);
    });
    console.log('Version :', APP_VERSION);
    if (userStore.user?.role.includes('superadmin')) console.log('Client ID :', clientStore?.client?.id);
  }
  // Track custom dimensions for Google Analytics
  const customUserProperties = {
    client_name: clientStore?.client?.name,
    client_id: clientStore?.client?.id,
    app_version: APP_VERSION,
  };
  set({ user_properties: customUserProperties });
  initZendeskWebWidget();
});
</script>
