<template>
  <component :is="layout" v-bind="layoutProps">
    <router-view />
  </component>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'AppLayout',
  setup() {
    const route = useRoute();
    const layout = computed(() => {
      const currentLayout = route?.meta?.layout;
      if (currentLayout) return `${currentLayout}Layout`;
      return 'DefaultLayout';
    });
    const layoutProps = computed(() => {
      const currentLayoutProps = route?.meta?.layoutProps;
      if (currentLayoutProps) return currentLayoutProps;
      return {};
    });
    return { layout, layoutProps };
  },
});
</script>
