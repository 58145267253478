export default {
  common: {
    warning: 'Attention',
    title: 'Une erreur est survenue',
  },
  save: {
    quitWithoutSave: "Vous risquez de perdre les changements que vous n'avez pas sauvegardés. Voulez-vous continuer ?",
    leaveSiteWithoutSave: 'Voulez-vous quitter ce site ? Les modifications que vous avez apportées peuvent ne pas être sauvegardées.',
  },
  pages: {
    '404': {
      title: 'Page introuvable',
      text: "La page demandée n'a pas été trouvée",
      button: "Retour à l'accueil",
    },
    '403': {
      title: 'Accès refusé',
      text: "Vous n'avez pas accès à cette page",
      button: "Retour à l'accueil",
    },
    '401': {
      title: "Vous n'êtes pas authentifié.e",
      text: 'Essayez de vous connecter à nouveau - en cas de problème récurrent, contactez le support de Neocity',
      button: 'Se connecter',
    },
    '400': {
      title: 'Une erreur est survenue',
      text: 'Si le problème persiste, veuillez contacter le support client Neocity.',
      button: "Retourner à l'accueil",
    },
    '500': {
      title: 'Une erreur est survenue',
      text: 'Si le problème persiste, veuillez contacter le support client Neocity.',
      button: "Retourner à l'accueil",
    },
    '502': {
      title: 'Un problème est survenu',
      text: 'Si le problème persiste, veuillez contacter le support client Neocity. <br /> Vous pouvez également consulter <a class="underline" href="https://status.neocity.fr/" target="_blank">cette page</a> qui présente le statut des différents services de Neocity',
      button: "Retourner à l'accueil",
    },
    client_churned: {
      title: 'Abonnement résilié',
      text: "Votre abonnement a été résilié.<br/>Vous n'avez plus accès à cette interface.<br/>Si vous pensez qu'il s'agit d'une erreur, veuillez prendre contact avec le support Neocity.",
      button: 'Déconnexion',
    },
    contract_expired: {
      title: 'Contrat expiré',
      text: "Votre contrat a expiré.<br/>Vous n'avez plus accès à cette interface.<br/>Si vous pensez qu'il s'agit d'une erreur, veuillez prendre contact avec le support Neocity.",
      button: 'Déconnexion',
    },
  },
  forms: {
    inputM: 'Veuillez entrer un {field} valide',
    inputF: 'Veuillez entrer une {field} valide',
    selectM: 'Veuillez sélectionner un {field}',
    selectF: 'Veuillez sélectionner une {field}',
    minChar: 'Le {field} doit contenir au moins {min} caractères | La {field} doit contenir au moins {min} caractères',
    maxChar: 'Le {field} doit contenir au maximum {max} caractères | La {field} doit contenir au maximum {max} caractères',
    betweenChar: 'Le {field} doit contenir entre {min} et {max} caractères | La {field} doit contenir entre {min} et {max} caractères',
  },
};
