export default {
  dashboard: 'Dashboard',
  'theme-create': 'New category',
  'theme-duplicate': 'New category',
  'theme-edit': 'Edit category',
  configuration: 'Configuration',
  consultation: 'Citizen consultation',
  ideas: 'Ideas',
  'ideas-create': 'Add an idea',
  'notification-create': 'New notification',
  'notification-duplicate': 'Duplicated notification',
  garbageCollection: {
    featureName: 'Waste management',
    collections: 'Collections',
    collectionCreate: 'Add a collection',
    collectionEdit: 'Edit a collection',
    collectionDateEdit: 'Edit a collection date',
    deposits: 'Fixed Deposits',
    depositCreate: 'Add an infrastructure',
    depositEdit: 'Edit an infrastructure',
    depositsPick: 'Choose an infrastructure',
    configuration: 'Configuration',
    garbageTypes: 'Garbage types',
    garbageTypeCreate: 'Add a garbage type',
    garbageTypeEdit: 'Edit a garbage type',
    sortingGuide: 'Sorting guide',
    slots: 'Slots',
    zones: 'Zones',
    zoneCreate: 'Add a zone',
    zoneEdit: 'Edit a zone',
  },
  infrastructures: 'Infrastructures',
  infrastructureCreate: 'Add an infrastructure',
  infrastructureEdit: 'Edit an infrastructure',
  notification: 'Edit a notification',
  index: 'Home',
  info_window: 'Info window',
  my_app: 'My App',
  notifications: 'Notifications',
  reports: 'Reports',
  surveys: 'Surveys',
  statistics: 'Statistics',
  pages: 'Pages',
  account: {
    main: 'My account',
    profile: 'My information',
    preferences: 'Preferences',
    notifications: 'Notifications',
    plan: 'Plan',
  },
  permissions: {
    main: 'Roles and users',
    users: 'Users',
    roles: 'Roles management',
    cities: 'Cities management',
  },
  appManagement: {
    main: 'App management',
    homepage: 'Tiles management',
    tree: 'Tree structure',
    stores: 'App stores',
    legal: 'Legal',
  },
  tutorials: {
    main: 'Tutorials and guides',
    tutorials: 'Tutorials and guides',
  },
  zones: 'Zones',
  'zone-create': 'Add a zone',
  'zone-edit': 'Edit a zone',
  preferences: 'Preferences',
};
