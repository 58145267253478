export default {
  actions: {
    add: 'Add a zone',
    edit: 'Edit zone',
    delete: 'Delete zone',
  },
  modals: {
    deleteZone: {
      title: 'Delete zone',
      message: 'Are you sure you want to delete this zone ?',
      warning: 'Deletion is irreversible. All content associated with this zone will be automatically deleted.',
    },
    importZone: {
      title: 'Import zone',
      tooltipCoords: 'Enter GPS coordinates to import the zone',
      fields: {
        coordinates: {
          placeholder: 'GPS coordinates',
        },
      },
      report: {
        title: 'Import report :',
        success: 'Zone imported successfully',
        error: 'Import failed, the zone contains errors',
      },
    },
  },
  tooltips: {
    global: 'You can create your zone directly from the map.</br> Be sure to add a name and color to your zone to distinguish it from other zones.',
  },
  form: {
    sections: {
      details: 'Details',
      geographicPosition: 'Geographic position',
    },
    title: {
      label: 'Zone name',
      placeholder: 'Name',
      errors: {
        required: 'Please enter a name',
      },
    },
    color: {
      label: 'Color',
      errors: {
        required: 'Please select a color',
      },
    },
    features: {
      label: 'Features',
      options: {
        garbage: 'Garbage collection',
      },
    },
    coordinates: {
      label: 'Coordinates',
    },
  },
  list: {
    empty: 'No zone for the moment.',
    noFeaturesAssociated: 'No features associated',
  },
};
