export default {
  common: {
    warning: 'Warning',
    title: 'An error occurred',
  },
  save: {
    quitWithoutSave: 'You may lose the changes you did not save. Do you want to continue?',
    leaveSiteWithoutSave: 'Do you want to leave this site ? The changes you have made may not be saved.',
  },
  pages: {
    '404': {
      title: 'Page not found',
      text: 'The requested page was not found',
      button: 'Back to home page',
    },
    '403': {
      title: 'Access denied',
      text: 'You do not have access to this page',
      button: 'Back to home page',
    },
    '401': {
      title: 'You are not authenticated',
      text: 'Try logging in again - if the problem recurs, contact Neocity support',
      button: 'Log in',
    },
    '400': {
      title: 'An error has occurred',
      text: 'If the problem persists, please contact Neocity Customer Support.',
      button: 'Back to home page',
    },
    '500': {
      title: 'An error has occurred',
      text: 'If the problem persists, please contact Neocity Customer Support.',
      button: 'Back to home page',
    },
    '502': {
      title: 'A problem has occurred',
      text: 'If the problem persists, please contact Neocity Customer Support. <br /> You can also consult <a class="underline" href="https://status.neocity.fr/" target="_blank">this page</a> which presents the status of the various Neocity services',
      button: 'Back to home page',
    },
    client_churned: {
      title: 'Subscription canceled',
      text: 'Your subscription has been canceled.<br/>You no longer have access to this interface.<br/>If you think this is an error, please contact Neocity support.',
      button: 'Logout',
    },
    contract_expired: {
      title: 'Contract expired',
      text: 'Your contract has expired.<br/>You no longer have access to this interface.<br/>If you think this is an error, please contact Neocity support',
      button: 'Logout',
    },
  },
  forms: {
    inputM: 'Please enter a valid {field}',
    inputF: 'Please enter a valid {field}',
    selectM: 'Please select a {field}',
    selectF: 'Please select a {field}',
    minChar: 'The {field} must contain at least {min} characters | The {field} must contain at least {min} characters',
    maxChar: 'The {field} must contain a maximum of {max} characters | The {field} must contain a maximum of {max} characters',
    betweenChar: 'The {field} must contain between {min} and {max} characters | The {field} must contain between {min} and {max} characters',
  },
};
