<template>
  <TransitionRoot appear :show="modelValue" as="template" @keyup.enter="handleOk">
    <Dialog as="div" :unmount="destroyOnClose" class="relative z-50" @close="setIsOpen(false)">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="duration-200 ease-in"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div class="fixed inset-0 bg-neutral-black/20" aria-hidden="true" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-[600px] transform rounded-sm bg-white text-left align-middle shadow-md transition-all"
              :class="paddingClass"
              :style="{ maxWidth: width + 'px' }"
            >
              <div class="mb-6 flex items-center justify-between">
                <div v-if="title" class="text-sm font-semibold uppercase text-neutral-black">{{ title }}</div>
                <Icon v-if="closable" id="x" class="cursor-pointer stroke-neutral-silver" :stroke="2.5" @click="setIsOpen(false)" />
              </div>
              <div :class="{ 'mb-6': cancelText || okText }">
                <Loading v-if="loading" class="py-8" size="large" />
                <slot v-else />
              </div>
              <div class="flex justify-between gap-2">
                <div class="flex items-center">
                  <slot name="footer-extra"></slot>
                </div>
                <div class="flex items-center gap-x-5">
                  <Button v-if="cancelText" v-bind="cancelButtonProps" @click="handleCancel">{{ cancelText }}</Button>
                  <Button v-if="okText" v-bind="{ ...okButtonProps, disabled: loading ? true : okButtonProps.disabled }" @click="handleOk">
                    {{ okText }}
                  </Button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useThrottleFn } from '@vueuse/core';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  okText: {
    type: String,
    default: 'OK',
  },
  okButtonProps: {
    type: Object,
    default: () => ({
      color: 'success',
    }),
  },
  cancelText: {
    type: String,
    default: 'Annuler',
  },
  cancelButtonProps: {
    type: Object,
    default: () => ({
      type: 'link',
      noPadding: true,
    }),
  },
  closable: {
    type: Boolean,
    default: true,
  },
  destroyOnClose: {
    type: Boolean,
    default: true,
  },
  width: {
    type: Number,
    default: 600,
  },
  paddingClass: {
    type: String,
    default: 'p-6',
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const isOpen = ref(false);

const emit = defineEmits(['update:modelValue', 'onOk', 'onCancel', 'onClose']);

const handleOk = (event: Event): void => {
  emit('onOk', event);
};

const handleCancel = (event: Event): void => {
  emit('onCancel', event);
};

const handleClose = (): void => {
  emit('onClose');
};

const setIsOpen = useThrottleFn((value: boolean): void => {
  if (!props.closable && !value) return;
  if (!value) handleClose();
  isOpen.value = value;
  emit('update:modelValue', isOpen.value);
}, 1000);
</script>
