export default {
  cancel: 'Annuler',
  delete: 'Supprimer',
  duplicate: 'Dupliquer',
  login: 'Connexion',
  logout: 'Déconnexion',
  save: 'Enregistrer',
  update: 'Modifier',
  continue: 'Continuer',
  validate: 'Valider',
  back: 'Retour',
  createAndAssign: 'Créer et assigner',
  choose: 'Choisir',
  add: 'Ajouter',
  import: 'Importer',
  retry: 'Réessayer',
};
