/* eslint-disable no-template-curly-in-string */
export default {
  onboarding: {
    title: 'New',
    info_alert: 'Welcome to the new version of your administration interface.',
    intro:
      'We have been working on its development for several months and hope that it meets your needs and improves the management of your application on a daily basis.',
    tests: 'We are still doing some tests on this new interface.',
    new_features:
      '<strong>It will be used for the moment only for the management of our new features</strong>, before completely replacing the old administration interface.',
    more_information:
      'For more information, <a class="underline" target="_blank" href="https://help.neocity.fr/hc/fr/articles/5768664282780-Présentation-du-nouveau-Back-Office-Neocity-/">click here</a>.',
    participate_design:
      'Would you like to participate in the design of your new administration interface ? <a class="underline" target="_blank" href="https://neocity.typeform.com/to/XY6Wtd9g#client_id={clientId}&client_name={clientName}&user_email_address={userEmail}/">Register here.</a>',
    not_warn_anymore: 'Do not warn me anymore',
    confirm: 'Continue',
  },
  create_theme: {
    confirm: 'Create and assign',
  },
  deleteHeaderImage: {
    title: 'Delete the header image',
    text: 'Do you want to delete the header image? This action is irreversible.',
  },
  deleteHeaderFile: {
    titleImage: 'Delete the image',
    titleFile: 'Delete the file',
    textImage: 'Do you want to delete the image? This action is irreversible.',
    textFile: 'Do you want to delete the file? This action is irreversible.',
  },
  deleteMessage: {
    title: 'Delete message',
    text: 'Do you want to delete this message ? This action is irreversible.',
  },
};
