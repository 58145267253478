<template>
  <!-- Desktop menu -->
  <aside
    v-if="isDesktop"
    class="hidden h-screen w-[236px] min-w-[236px] max-w-[236px] flex-col justify-between overflow-y-auto bg-neutral-black px-2.5 py-5 lg:flex"
    :class="{ '!w-[80px] !min-w-[80px] !max-w-[80px]': isMenuMinified }"
  >
    <div class="mb-4 flex flex-col gap-1">
      <div class="flex flex-row items-center justify-between">
        <router-link to="/" :class="{ 'flex w-full justify-center': isMenuMinified }">
          <Logo class="h-[45px] w-full" color="light" :minified="isMenuMinified" @click="updateClient" />
        </router-link>
        <Button v-if="!isMenuMinified" icon="chevrons-left" class="text-white" type="link" @click="isMenuMinified = true"></Button>
      </div>
      <div class="divide-y divide-white/[0.3]">
        <div v-for="(menu, index) in menus" :key="index" class="py-2">
          <Menu :menu="menu" />
        </div>
      </div>
    </div>

    <Button v-if="isMenuMinified" icon="chevrons-right" class="text-white" type="link" @click="isMenuMinified = false"></Button>
    <Button v-else class="mx-2 whitespace-nowrap" type="secondary" size="large" @click="goToOldBackOffice">
      {{ $t('redirection.back_to_old_backo') }}
    </Button>
  </aside>
  <!-- End Desktop menu -->

  <!-- Mobile menu -->
  <aside v-else class="fixed left-0 top-0 z-[-100] flex h-full w-full flex-col justify-between lg:hidden" :class="{ '!z-50': isMenuOpen }">
    <div
      class="z-10 flex h-full w-9/12 translate-x-[-100%] flex-col justify-between overflow-y-auto bg-neutral-black px-2.5 py-5 transition duration-150 ease-in-out sm:w-2/5"
      :class="{ 'translate-x-[0%]': isMenuOpen }"
    >
      <div class="mb-4 flex flex-col gap-1">
        <div class="flex w-full flex-row items-center justify-between">
          <router-link to="/" class="w-fit">
            <Logo class="h-[45px]" color="light" @click="updateClient" />
          </router-link>
          <Button v-if="isMenuOpen" icon="chevrons-left" class="text-white" type="link" @click="isMenuOpen = false"></Button>
        </div>
        <div class="divide-y divide-white/[0.3]">
          <div v-for="(menu, index) in menus" :key="index" class="py-2">
            <Menu :menu="menu" />
          </div>
        </div>
      </div>
      <Button class="mx-10 whitespace-nowrap" type="secondary" size="large" @click="goToOldBackOffice">{{
        $t('redirection.back_to_old_backo')
      }}</Button>
    </div>
    <!-- Backdrop -->
    <div
      class="absolute left-0 top-0 z-0 block h-full w-full bg-black opacity-0 transition duration-150 ease-in-out"
      :class="{ 'opacity-50': isMenuOpen }"
      @click="isMenuOpen = false"
    ></div>
    <!-- End Backdrop -->
  </aside>
  <!-- End Mobile menu -->
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue';
import { IMenuItem } from '@/models/menu.model';
import { useWindowSize } from '@vueuse/core';
import { useClient } from '@/stores/client';
import { useUser } from '@/stores/user';
import { useGarbage } from '@/stores/garbage';
import { event } from 'vue-gtag';

const { width: windowWidth } = useWindowSize();
const isDesktop = computed(() => windowWidth.value >= 1024);

const clientStore = useClient();
const userStore = useUser();
const garbageStore = useGarbage();

const { isMenuOpen, isMenuMinified } = inject<{ isMenuOpen: boolean; isMenuMinified: boolean }>('menu') || {
  isMenuOpen: false,
  isMenuMinified: false,
};

const oldBackOfficeUrl = computed(() => {
  if (import.meta.env.VITE_NEOCITY_BASEURL_BACKO) return import.meta.env.VITE_NEOCITY_BASEURL_BACKO;
  return 'https://app.neocity.fr/';
});

const goToOldBackOffice = (): void => {
  event('click_old_backo_button');
  window.location.href = oldBackOfficeUrl.value;
};

const updateClient = async (): Promise<void> => {
  await clientStore.getClient();
};

const menus = ref<IMenuItem[][]>([
  [
    {
      name: 'routes.dashboard',
      icon: 'layout-dashboard',
      to: '/dashboard',
      validate: (): boolean => true,
    },
    {
      name: 'routes.statistics',
      icon: 'chart-bar',
      to: '/statistics',
      validate: (): boolean =>
        clientStore.hasFeature('statistic') &&
        userStore.hasRight(['superadmin', 'organisation.owner', 'organisation.client.admin', 'admin', 'statistician']),
    },
  ],
  [
    {
      name: 'routes.pages',
      icon: 'files',
      validate: (): boolean => true,
      external: `${oldBackOfficeUrl.value}#/contents`,
    },
    {
      name: 'routes.infrastructures',
      icon: 'map-pin',
      to: '/infrastructures',
      superAdmin: true,
      validate: (): boolean => userStore.hasRight(['superadmin']),
    },
    {
      name: 'routes.zones',
      icon: 'polygon',
      to: '/zones',
      superAdmin: true,
      validate: (): boolean => userStore.hasRight(['superadmin']),
    },
  ],
  [
    {
      name: 'routes.reports',
      icon: 'alert-triangle',
      external: `${oldBackOfficeUrl.value}#/signalements`,
      validate: (): boolean =>
        clientStore.hasFeature('report') &&
        userStore.hasRight([
          'superadmin',
          'organisation.owner',
          'organisation.client.admin',
          'admin',
          'technical',
          'technical_params',
          'technical_stats',
          'technical_export',
          'technical_create',
          'technical_presta',
        ]),
    },
    {
      name: 'routes.notifications',
      icon: 'bell',
      to: '/notifications',
      validate: () =>
        clientStore.hasFeature('push') && userStore.hasRight(['superadmin', 'organisation.owner', 'organisation.client.admin', 'admin', 'push']),
    },
    {
      name: 'routes.surveys',
      icon: 'edit',
      external: `${oldBackOfficeUrl.value}#/surveys`,
      validate: () =>
        clientStore.hasFeature('survey') && userStore.hasRight(['superadmin', 'organisation.owner', 'organisation.client.admin', 'admin', 'editor']),
    },
    {
      name: 'routes.consultation',
      icon: 'messages',
      defaultChildren: 0,
      validate: () =>
        clientStore.hasFeature('consultation') &&
        userStore.hasRight(['superadmin', 'organisation.owner', 'organisation.client.admin', 'admin', 'editor']),
      children: [
        {
          name: 'routes.ideas',
          to: '/consultation/ideas',
          validate: () => true,
        },
        {
          name: 'routes.configuration',
          to: '/consultation/configuration',
          validate: () => true,
        },
      ],
    },
    {
      name: 'routes.garbageCollection.featureName',
      icon: 'trash',
      children: [
        {
          name: 'routes.garbageCollection.collections',
          to: '/garbage/collections',
          validate: () => garbageStore.userCanAccessSubfeature('collections'),
        },
        {
          name: 'routes.garbageCollection.deposits',
          to: '/garbage/deposits',
          validate: () => garbageStore.userCanAccessSubfeature('depositPoints'),
        },
        {
          name: 'routes.garbageCollection.configuration',
          to: '/garbage/configuration',
          validate: () => garbageStore.userCanAccessSubfeature('settings'),
        },
      ],
      validate: () =>
        clientStore.hasFeature('garbage') &&
        userStore.hasRight(['superadmin', 'organisation.owner', 'organisation.client.admin', 'admin', 'garbage']) &&
        ['collections', 'depositPoints', 'settings'].some((subfeature: 'collections' | 'depositPoints' | 'settings') =>
          garbageStore.userCanAccessSubfeature(subfeature)
        ),
    },
    {
      name: 'routes.info_window',
      icon: 'info-circle',
      external: `${oldBackOfficeUrl.value}#/alerts`,
      validate: (): boolean =>
        clientStore.hasFeature('alerts') &&
        userStore.hasRight(['superadmin', 'organisation.owner', 'organisation.client.admin', 'admin', 'editor', 'statistician']),
    },
  ],
]);
</script>
