export default {
  dashboard: 'Tableau de bord',
  'theme-create': 'Nouvelle thématique',
  'theme-duplicate': 'Nouvelle thématique',
  'theme-edit': 'Modifier la thématique',
  configuration: 'Configuration',
  consultation: 'Participation\n citoyenne',
  ideas: 'Idées',
  'ideas-create': 'Ajouter une idée',
  'notification-create': 'Nouvelle notification',
  'notification-duplicate': 'Notification dupliquée',
  garbageCollection: {
    featureName: 'Gestion des déchets',
    collections: 'Collectes',
    collectionCreate: 'Ajouter une collecte',
    collectionEdit: 'Éditer une collecte',
    collectionDateEdit: 'Éditer une date de passage',
    deposits: "Points d'apport volontaire",
    depositCreate: 'Ajouter une infrastructure',
    depositEdit: 'Éditer une infrastructure',
    depositsPick: 'Choisir une infrastructure',
    configuration: 'Configuration',
    garbageTypes: 'Types de déchets',
    garbageTypeCreate: 'Nouveau type de déchets',
    garbageTypeEdit: 'Éditer un type de déchets',
    sortingGuide: 'Guide du tri',
    slots: 'Créneaux',
    zones: 'Zones',
    zoneCreate: 'Ajouter une zone',
    zoneEdit: 'Éditer une zone',
  },
  infrastructures: 'Infrastructures',
  infrastructureCreate: 'Ajouter une infrastructure',
  infrastructureEdit: 'Modifier une infrastructure',
  notification: 'Modifier une notification',
  index: 'Accueil',
  info_window: "Fenêtre d'info",
  my_app: 'Mon App',
  notifications: 'Notifications',
  reports: 'Signalements',
  surveys: 'Sondages',
  statistics: 'Statistiques',
  pages: 'Pages',
  account: {
    main: 'Mon compte',
    profile: 'Mes informations',
    preferences: 'Préférences',
    notifications: 'Notifications',
    plan: 'Abonnement',
  },
  permissions: {
    main: 'Rôles et utilisateurs',
    users: 'Utilisateurs',
    roles: 'Gestion des rôles',
    cities: 'Gestion des villes',
  },
  appManagement: {
    main: "Gestion de l'application",
    homepage: 'Gestion des tuiles',
    tree: 'Arborescence',
    stores: 'Fiches stores',
    legal: 'Légal',
    tutorials: 'Tutoriels et guides',
  },
  tutorials: {
    main: 'Tutoriels et guides',
    tutorials: 'Tutoriels et guides',
  },
  zones: 'Zones',
  'zone-create': 'Ajouter une zone',
  'zone-edit': 'Éditer une zone',
  preferences: 'Préférences',
};
