export default {
  addInfrastructure: 'Add an infrastructure',
  forms: {
    blocks: {
      presentation: {
        title: 'Presentation',
      },
      contact: {
        title: 'Contact',
      },
      schedule: {
        title: 'Schedule',
      },
      informations: {
        title: 'Informations',
      },
    },
    placeholder: {
      infrastructureName: 'Supermarket on Main Avenue',
      infrastructureNameGarbage: 'Garbage dump on Main Avenue',
      category: 'Choose a category',
      chooseAnIcon: 'Choose an icon',
      acceptedWaste: 'List of accepted waste',
      rejectedWaste: 'List of rejected waste',
      landfill: 'Recycling center',
      search: 'Search ...',
    },
    label: {
      infrastructureName: 'Infrastructure name',
      tempInfra: 'Temporary infrastructure',
      displaySchedule: 'Show schedules',
      acceptedWaste: 'Accepted waste',
      rejectedWaste: 'Rejected waste',
      wasteDescription: 'Description',
      addCategory: 'Add a category',
      createCateory: 'Create a category',
      details: 'details',
      title: 'title',
      from: 'From',
      until: 'Until',
      from2: 'From',
      until2: 'Until',
      pickInfrastructruesFromExisitng: 'Choose an infrastructure from your existing ones',
      createNewInfrastructure: 'Create a new infrastructure',
      importInfraViaCsv: 'Import infrastructures via CSV file',
      on: 'On',
      addAnInfrastructure: 'Add an infrastructure',
      addOns: 'Add-ons',
      addOnsOptions: {
        garbage: 'Garbage Collection',
      },
    },
    errors: {
      nameRequired: 'Please enter a valid name',
      categoryRequired: 'Please select a category',
      colorRequired: 'Please select a color',
      iconRequired: 'Please select an icon',
      acceptedWaste: 'Please fill in the list of accepted waste',
      rejectedWaste: 'Please fill in the list of rejected waste',
      saveSchedule: 'Please save the schedules',
    },
  },
  scheduleModes: {
    schedule: 'Schedule',
    exceptionalSchedule: 'Exceptional schedule',
    repeatSchedule: 'Any dates added will be repeated every year.',
    closedOnPublicHolidays: 'Closed on public holidays',
    date: 'Date',
    addADate: 'Add a date',
    addADateRange: 'Add a date range',
    addASchedule: 'Add a schedule',
  },
  empty: {
    no_results: 'No infrastructure at the moment.',
    no_results_with_filters: 'There are no infrastructure that match your search.',
    no_categories: 'No categories',
    no_address: 'No address provided',
  },
  importCsv: {
    importInfrastructures: 'Import infrastructures',
    importTooltip: 'Import your CSV file to create your infrastructures.',
    facilitateInfraImport: 'To facilitate infrastructure imports,',
    downloadXlsx: 'download the default template in .xlsx format',
    convertToCsv: 'Then convert the file to .csv format for import.',
    import: 'Import',
    confirm: 'Confirm',
    importCsvFile: 'Import a csv file',
    importReport: 'Import Report,',
    infrastructuresFound: 'infrastructure found | infrastructures found',
    importedInfrastructures: 'imported infrastructure | imported infrastructures',
    infrastructuresContainingErrors: 'infrastructure containing errors | infrastructures containing errors',
    defaultValueAssign: 'The default value has been assigned to the fields in error.',
    nonImportedInfras: 'Non-imported infrastructure | Non-imported infrastructures',
  },
  toasts: {
    addInfra:
      'You can sort your infrastructures in ascending or descending alphabetical order. Please note that in the mobile application, infrastructures are displayed by default in alphabetical order, by category and then by name.',
  },
  modals: {
    deleteInfrastructure: {
      title: "Supprimer l'infrastructure | Supprimer { count } infrastructures",
      toast: 'Êtes-vous sûr(e) de vouloir supprimer cette infrastructure ? | Êtes-vous sûr(e) de vouloir supprimer ces infrastructures ?',
      description:
        "La suppression est irréversible. L'infrastructure sera supprimée de votre interface d'administration et de votre application citoyenne. | La suppression est irréversible. Les infrastructures seront supprimées de votre interface d'administration et de votre application citoyenne.",
    },
  },
  addOnNames: {
    garbage: 'Garbage Collection',
    parking: 'Parking',
    parkingPMR: 'Parking PRM',
  },
  actions: {
    editInfrastructure: 'Edit infrastructure',
    deleteInfrastructure: 'Delete infrastructure',
  },
};
