export default {
  actions: {
    update: 'Modifier la thématique',
    duplicate: 'Dupliquer la thématique',
    delete: 'Supprimer la thématique',
  },
  forms: {
    errors: {
      titleAlreadyExists: 'Ce nom de thématique est déjà utilisé. Veuillez entrer un nom unique.',
    },
  },
};
