export default {
  actions: {
    update: 'Edit theme',
    duplicate: 'Duplicate theme',
    delete: 'Delete theme',
  },
  forms: {
    errors: {
      titleAlreadyExists: 'This theme name is already used. Please enter a unique name.',
    },
  },
};
