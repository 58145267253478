<template>
  <input
    class="formkit-input font-regular w-full bg-transparent p-2 text-base font-normal text-neutral-black placeholder-neutral-silver outline-none formkit-disabled:cursor-not-allowed formkit-disabled:text-neutral-grey formkit-invalid:text-neutral-black"
    type="number"
    :value="props.context._value"
    @input="handleInput"
  />
  <span class="absolute right-9 top-[50%] translate-y-[-50%] text-sm font-semibold text-neutral-grey">{{
    `${props.context.unit}${props.context._value > 1 ? 's' : ''}`
  }}</span>
</template>

<script setup lang="ts">
const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
});

const handleInput = (e: Event): void => {
  props.context.node.input((e.target as HTMLInputElement).value);
};
</script>
