export default {
  label: {
    anonymousMode: 'Anonymous mode',
    civility: 'Civility',
    color: 'Color',
    description: 'Description',
    email: 'E-mail address',
    phoneNumber: 'Phone number',
    firstname: 'Firstname',
    lastname: 'Lastname',
    icon: 'Icon',
    mandatoryImage: 'Mandatory image',
    moderation: 'Moderation',
    theme: 'Theme',
    type: 'Type',
    title: 'Title',
    voteDuration: 'Vote duration by default',
    image: 'Image',
    from: 'From',
    to: 'To',
    dateRange: 'Date range',
    link: 'Link',
    url: 'URL',
    width: 'Width',
    height: 'Height',
    label: 'Label',
    category: 'Category',
    address: 'Address',
    city: 'City',
    zipCode: 'Zip code',
    lat: 'Latitude',
    long: 'Longitude',
    phone: 'Phone',
    fax: 'Fax',
    website: 'Website',
    startDate: 'Start date',
    endDate: 'End date',
    file: 'File',
    every: 'Every',
    repeatEvery: 'Repeat every',
    dates: 'Dates',
  },

  info: {
    mandatoryImage: 'Participants are required to add an image when submitting their ideas.',
    anonymousMode: 'With the anonymous mode, participants submit their ideas without having to enter their personal information.',
    color: 'Color',
    icon: 'Icon',
    moderation:
      'If moderation is enabled, you must manually set the status to "Voting in progress" to display the proposed ideas on the application.',
    title: 'Title',
  },

  placeholder: {
    chooseTheme: 'Choose a theme',
    chooseCity: 'Select a city',
    chooseType: 'Choose a type',
    chooseCivility: 'Choose a civility',
    civility: 'Civility',
    description: 'Description',
    descriptionContent: 'Content of the description',
    email: "address{'@'}email.com",
    firstname: 'Firstname',
    icon: 'Choose an icon',
    lastname: 'Lastname',
    name: 'Name',
    theme: 'Theme',
    title: 'Title',
    type: 'Type',
    search: 'Search',
    searchEmoji: 'Search an emoji',
    defaultSelect: 'Select',
  },

  help: {
    remainingCharacters: 'remaining character | remaining characters',
  },

  units: {
    day: 'day',
  },

  options: {
    all: 'All',
    anonymous: 'Anonymous',
    user: 'User',
    male: 'Male',
    female: 'Female',
    mr: 'Mr',
    mrs: 'Mrs',
  },

  errors: {
    incomplete: 'Please fill every fields.',
    hexadecimal: 'Please fill a hexadecimal code.',
  },

  empty: {
    noResults: 'No results',
  },

  actions: {
    selectAll: 'Select all',
    unselectAll: 'Unselect all',
    reset: 'Reset',
  },
};
