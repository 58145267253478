<template>
  <VueDatePicker
    :modelValue="context?._value"
    :range="true"
    :textInput="context?.textInput || false"
    :inputClassName="context.classes.input"
    :format="context?.format || 'dd/MM/yyyy'"
    :multiCalendars="context?.multiCalendars || false"
    :position="context?.position || 'center'"
    :clearable="context?.clearable"
    :autoApply="context?.autoApply || false"
    :locale="$i18n.locale"
    :minDate="context?.minDate"
    :maxDate="context?.maxDate"
    :enableTimePicker="context?.enableTimePicker"
    @update:model-value="onInput"
  >
    <template #input-icon>
      <Icon id="calendar" class="ml-2 text-neutral-black" />
    </template>
  </VueDatePicker>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
});

const node = ref(props.context.node);

const onInput = (value): void => {
  node.value.input(value);
};
</script>
