export default {
  actions: {
    add: 'Ajouter une zone',
    edit: 'Modifier la zone',
    delete: 'Supprimer la zone',
  },
  modals: {
    deleteZone: {
      title: 'Supprimer une zone',
      message: 'Êtes-vous sûr(e) de vouloir supprimer cette zone ?',
      warning: 'La suppression est irréversible. Toutes les contenus associés à cette zone seront automatiquement supprimés.',
    },
    importZone: {
      title: 'Importer une zone',
      tooltipCoords: 'Indiquez les coordonnées GPS pour importer la zone.',
      fields: {
        coordinates: {
          placeholder: 'Coordonnées GPS',
        },
      },
      report: {
        title: "Compte-rendu de l'importation :",
        success: 'Zone importée avec succès',
        error: "Échec de l'importation, la zone contient des erreurs",
      },
    },
  },
  tooltips: {
    global:
      'Vous pouvez créer votre zone directement depuis la carte.</br> Veillez à ajouter un nom et une couleur à votre zone pour la distinguer des autres zones.',
  },
  form: {
    sections: {
      details: 'Détails',
      geographicPosition: 'Position géographique',
    },
    title: {
      label: 'Nom de la zone',
      placeholder: 'Nom',
      errors: {
        required: 'Veuillez saisir un nom',
      },
    },
    color: {
      label: 'Couleur',
      errors: {
        required: 'Veuillez sélectionner une couleur',
      },
    },
    features: {
      label: 'Fonctionnalités',
      options: {
        garbage: 'Collecte des déchets',
      },
    },
    coordinates: {
      label: 'Coordonnées',
    },
  },
  list: {
    empty: 'Aucune zone pour le moment.',
    noFeaturesAssociated: 'Aucune fonctionnalitée associée',
  },
};
