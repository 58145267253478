<template>
  <VDropdown class="relative !w-full" theme="picker" placement="bottom-start" :distance="8" :positioningDisabled="isMobile">
    <template #default="{ shown }">
      <div
        class="relative overflow-hidden rounded-sm"
        :class="{
          'border border-base-basic !bg-white': shown && !props.context.iconOnly,
          'border border-neutral-light bg-neutral-light': !shown && !props.context.iconOnly,
        }"
      >
        <NeocityIcon
          v-if="props.context.value"
          :icon="props.context.value"
          class="bg-neutral-light"
          :class="{
            '!bg-neutral-light': shown,
            'h-[36px] w-[36px]': props.context.iconOnly,
            '!text-base-basic': shown && props.context.iconOnly,
            'absolute left-2.5 top-[50%] h-[28px] w-[28px] translate-y-[-50%]': !props.context.iconOnly,
          }"
        />
        <input
          v-if="!props.context.iconOnly"
          class="w-full cursor-pointer bg-transparent px-2 py-2 pr-12 text-base"
          :class="{ 'pl-12': props.context.value, 'bg-white': !props.context.state.valid && props.context.state.submitted }"
          type="text"
          :placeholder="props.context.attrs.placeholder"
          :value="props.context.value"
        />
        <Icon
          v-if="!props.context.iconOnly"
          :id="shown ? 'chevron-up' : 'chevron-down'"
          class="absolute right-2.5 top-[50%] h-5 w-5 translate-y-[-50%] text-neutral-dark"
        />
      </div>
    </template>
    <template #popper="{ hide }">
      <div class="w-full max-w-[370px] rounded-sm bg-white p-4 drop-shadow-md" :class="{ 'relative mx-auto mb-14 drop-shadow-none': isMobile }">
        <div v-if="isMobile" class="absolute right-4 top-4" @click="hide">
          <Icon id="x" class="text-neutral-dark" size="large" />
        </div>
        <div class="mb-3 font-semibold uppercase text-neutral-grey">{{ $t('molecules.iconpicker.title') }}</div>
        <div>
          <div class="mb-3">
            <FormKit v-model="searchInput" type="search" :placeholder="$t('molecules.iconpicker.search_placeholder')" @input="onSearch" />
          </div>
          <div class="mb-3 flex flex-row flex-wrap gap-x-1 gap-y-3">
            <NeocityIcon
              v-for="(icon, index) in filteredIconsPages[currentPage - 1]"
              :key="index"
              class="h-[34px] w-[34px]"
              :icon="icon"
              :currentIcon="props.context.value"
              @click="selectIcon(icon, hide)"
            />
          </div>
          <div class="flex items-center justify-between">
            <Button icon="chevron-left" :disabled="currentPage === 1" @click="previousPage" />
            <span class="text-sm font-semibold text-neutral-dark">
              {{ filteredIconsPages.length ? `${currentPage}/${filteredIconsPages.length}` : $t('molecules.iconpicker.no_result') }}
            </span>
            <Button icon="chevron-right" :disabled="currentPage === filteredIconsPages.length" @click="nextPage" />
          </div>
        </div>
      </div>
    </template>
  </VDropdown>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { useWindowSize } from '@vueuse/core';
import neocity_icons from '@/assets/ts/neocity-icons';

const { width: windowWidth } = useWindowSize();
const isMobile = computed(() => windowWidth.value < 640);

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
});

const searchInput = ref('');
const currentPage = ref(1);
const filteredIconsPages = ref<string[][]>([]);
const pageSize = 45;

const neocityIcons = ref<string[]>(neocity_icons);

const chunkArrayBySize = (array: string[], size: number): string[][] => {
  const chunkedArray: string[][] = [];
  let i = 0;
  while (i < array.length) chunkedArray.push(array.slice(i, (i += size)));
  return chunkedArray;
};

const onSearch = (): void => {
  currentPage.value = 1;
  filteredIconsPages.value = chunkArrayBySize(
    neocityIcons.value.filter((icon: string) => icon.includes(searchInput.value)),
    pageSize
  );
};

const selectIcon = async (icon: string, close): Promise<void> => {
  props.context.node.input(icon);
  close();
};

const previousPage = (): void => {
  if (currentPage.value > 1) currentPage.value--;
};

const nextPage = (): void => {
  if (currentPage.value < filteredIconsPages.value.length) currentPage.value++;
};

onMounted(() => {
  filteredIconsPages.value = chunkArrayBySize(neocityIcons.value, pageSize);
});
</script>
