export default {
  navigation: {
    myAccount: 'Mon compte',
    myInformation: 'Mes informations',
    preferences: 'Préférences',
    notifications: 'Notifications',
    plan: 'Abonnement',
    rolesAndUsers: 'Rôles et utilisateurs',
    users: 'Utilisateurs',
    rolesManagement: 'Gestion des rôles',
    citiesManagement: 'Gestion des villes',
    appManagement: "Gestion de l'application",
    tilesManagement: 'Gestion des tuiles',
    tree: 'Arborescence',
    stores: 'Fiches stores',
    legal: 'Légal',
    tutorials: 'Tutoriels et guides',
  },
};
