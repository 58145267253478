<template>
  <div class="relative w-full overflow-hidden rounded-sm shadow-sm">
    <div class="absolute inset-y-0 left-0 flex w-[38px] cursor-pointer items-center justify-center text-white">
      <Icon id="color-picker" size="large" :stroke-width="1.5" class="absolute" :style="{ color: penColor }" @click="openColorPicker" />
      <input ref="colorInput" type="color" :value="props.context._value" class="cursor-pointer" @input="handleInput" />
    </div>
    <input
      :value="props.context._value"
      type="text"
      class="font-regular w-full bg-neutral-light p-2 pl-12 text-base font-normal text-neutral-black placeholder-neutral-silver outline-none focus-within:bg-white formkit-disabled:cursor-not-allowed formkit-disabled:bg-neutral-cloud formkit-disabled:text-neutral-grey formkit-invalid:bg-white formkit-invalid:text-neutral-black"
      placeholder="#000000"
      @input="handleInput"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import colorable from 'colorable';

const colorInput = ref();

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
});
const node = ref(props.context.node);

const penColor = ref('#000');

const bucketContrast = (baseColor: string): void => {
  if (!baseColor) return;
  const serializedColor = baseColor.length === 9 ? baseColor.slice(0, -2) : baseColor;
  try {
    const { combinations } = colorable([serializedColor, 'white', 'black'], { compact: true, threshold: 0 })[0];
    let bestColor: { hex: string; contrast: number } | undefined;
    combinations.forEach((combination) => {
      if (!bestColor || bestColor.contrast < combination.contrast) bestColor = combination;
    });
    if (!bestColor) throw new Error('No best color found');
    penColor.value = bestColor.hex;
  } catch (error) {
    penColor.value = '#fff';
  }
};

const handleInput = (e: Event): void => {
  node.value.input((e.target as HTMLInputElement).value);
  bucketContrast((e.target as HTMLInputElement).value);
};

const openColorPicker = (): void => {
  colorInput.value.click();
  bucketContrast(colorInput.value);
};

onMounted(() => {
  bucketContrast(colorInput.value);
});
</script>

<style scoped>
input[type='color'] {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  width: 100%;
  height: 100%;
}

input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type='color']::-webkit-color-swatch {
  border: none;
}
</style>
