<template>
  <div class="relative w-fit max-w-full overflow-hidden rounded-sm bg-white">
    <div :class="[tagClasses, fancyTextColorClass, fancyBackgroundColorClass]" :style="{ color: isHexColor(color) ? color : undefined }">
      <div class="relative z-10 flex items-center gap-2" :class="[breakWordClass, { 'max-w-full overflow-hidden text-ellipsis': !allowWordBreaks }]">
        <slot />
        <Icon v-if="icon" :id="icon" :size="size" />
      </div>
    </div>
    <div v-if="isHexColor(color)" class="absolute left-0 top-0 z-0 h-full w-full opacity-[.15]" :style="{ backgroundColor: color }"></div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { getFancyColorClass, isHexColor } from '@/utils/colors';

import type { PropType } from 'vue';
import type { FancyColor } from '@/models/colors.model';

const props = defineProps({
  color: {
    type: String as PropType<string>,
    default: 'green',
  },
  size: {
    type: String,
    default: 'medium',
    validator(value: string) {
      return ['small', 'medium', 'large'].includes(value);
    },
  },
  icon: {
    type: String,
    default: '',
  },
  allowWordBreaks: {
    type: Boolean,
    default: false,
  },
});

const fancyTextColorClass = computed(() => {
  if (isHexColor(props.color)) return undefined;
  return getFancyColorClass(props.color as FancyColor, 'text');
});

const fancyBackgroundColorClass = computed(() => {
  if (isHexColor(props.color)) return undefined;
  return getFancyColorClass(props.color as FancyColor, 'background');
});

const tagClasses = computed(() => ({
  'rounded-sm flex items-center w-fit whitespace-nowrap font-semibold max-w-full': true,
  // Sizes
  'p-2 text-xs': props.size === 'small',
  'px-2 py-1 text-sm': props.size === 'medium',
  'px-2 py-1 text-base': props.size === 'large',
}));

const breakWordClass = computed(() => ({ 'whitespace-pre-wrap break-words line-clamp-2': props.allowWordBreaks }));
</script>
