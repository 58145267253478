import actions from './actions';
import common from './common';
import consultation from './consultation';
import alerts from './alerts';
import atoms from './atoms';
import errors from './errors';
import forms from './forms';
import modals from './modals';
import molecules from './molecules';
import notifications from './notifications';
import organisms from './organisms';
import primevue from './primevue';
import redirection from './redirection';
import routes from './routes';
import themes from './themes';
import views from './views';
import dashboard from './dashboard';
import settings from './settings';
import garbage from './garbage';
import infrastructures from './infrastructures';
import zones from './zones';
import features from './features';
import menu from './menu';

export default {
  common,
  atoms,
  consultation,
  actions,
  alerts,
  errors,
  forms,
  modals,
  molecules,
  notifications,
  organisms,
  primevue,
  redirection,
  routes,
  themes,
  views,
  dashboard,
  settings,
  garbage,
  infrastructures,
  zones,
  features,
  menu,
};
