import { notify } from 'notiwind';

export const alert = (title: string, text: string, type: string, duration = 3000, group = 'notification', closable = false): void => {
  notify(
    {
      group,
      title,
      text,
      type,
      closable,
    },
    duration
  );
};
