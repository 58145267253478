<template>
  <Modal v-model="showPreview" :title="$t('forms.label.image')" cancelText="" okText="" paddingClass="p-4" @close="togglePreview">
    <img class="w-full rounded-sm" :src="file.url" alt="" />
  </Modal>
  <Modal
    v-model="showDeleteModal"
    :title="isImageFileUploader ? $t('modals.deleteFile.titleImage') : $t('modals.deleteFile.titleFile')"
    :okText="$t('actions.delete')"
    :okButtonProps="{ type: 'primary', color: 'danger' }"
    @onCancel="toggleDeleteModal"
    @onOk="deleteFile"
    @close="toggleDeleteModal"
  >
    {{ isImageFileUploader ? $t('modals.deleteFile.textImage') : $t('modals.deleteFile.textFile') }}
  </Modal>
  <div v-if="context.display === 'block'" class="group relative h-full max-w-[350px] overflow-hidden rounded-sm" data-type="file">
    <div class="flex gap-3">
      <div class="relative flex h-[120px] w-[120px] flex-col items-center justify-center gap-3 overflow-hidden rounded-sm bg-neutral-cloud">
        <label
          for="file"
          class="absolute inset-y-0 z-20 inline-block h-full w-full cursor-pointer"
          :class="{ 'pointer-events-none': file.url }"
        ></label>
        <input
          id="file"
          ref="fileInput"
          type="file"
          :accept="authorizedFormats.join(',')"
          :class="{ hidden: file.url }"
          class="h-0 w-0 cursor-pointer opacity-0"
          @input="handleInput"
        />
        <Loading v-if="isLoading" />
        <div
          v-if="!isLoading"
          class="relative z-10 hidden h-[38px] w-[38px] items-center justify-center rounded-sm bg-white text-neutral-black group-hover:flex"
          :class="{ '!flex': !file.url && !isLoading, 'cursor-pointer': file.url }"
          @click="togglePreview"
        >
          <Icon v-if="!file.url" id="edit" class="relative z-10" size="large" />
          <Icon v-else id="search" class="relative z-10" size="large" />
        </div>
        <span v-if="!file.url && !isLoading" class="text-sm font-semibold text-neutral-black">{{ $t('molecules.fileUpload.no_image') }}</span>
        <img v-if="file.url && !isLoading" class="absolute inset-y-0 z-0 h-full w-full object-cover" :src="file.url" alt="" />
      </div>
      <div class="flex flex-col justify-between">
        <div class="flex flex-col gap-2">
          <div class="flex items-center gap-2">
            <Icon id="photo" class="text-neutral-black" />
            <span class="font-semibold text-neutral-black">{{ context.title ? context.title : $t('molecules.fileUpload.defaultTitle') }}</span>
          </div>
          <div class="flex justify-between gap-1">
            <div class="flex flex-col">
              <span class="text-sm font-semibold text-neutral-dark">{{ $t('molecules.fileUpload.size') }} :</span>
              <span class="text-sm text-neutral-dark">{{ file.dimensions || '-' }}</span>
            </div>
            <div class="flex flex-col">
              <span class="text-sm font-semibold text-neutral-dark">{{ $t('molecules.fileUpload.weight') }} :</span>
              <span class="text-sm text-neutral-dark">{{ humanFileSize(file.size, 0) || '-' }}</span>
            </div>
          </div>
        </div>
        <Button v-if="file.url && !isLoading" class="w-fit" size="large" icon="trash" color="danger" type="secondary" @click="toggleDeleteModal" />
      </div>
    </div>
  </div>
  <div
    v-else
    class="relative w-full overflow-hidden rounded-sm border border-neutral-metal"
    :class="file.url ? 'border-solid' : 'border-dashed'"
    data-type="file"
  >
    <input
      ref="fileInput"
      type="file"
      :accept="authorizedFormats.join(',')"
      :class="{ hidden: file.url }"
      class="absolute left-0 z-10 h-full w-full cursor-pointer opacity-0"
      @input="handleInput"
    />
    <div class="h-full w-full p-2.5" :class="context.state.errors ? 'bg-danger-light' : 'bg-neutral-light'">
      <div v-if="file.url" class="flex items-center justify-between">
        <div class="flex items-center">
          <div v-if="isImageFileUploader" class="cursor-pointer" @click="togglePreview">
            <img :src="file.url" alt="" class="h-11 w-11 object-cover" />
          </div>
          <div class="ml-2.5 flex flex-col">
            <p class="text-xs font-semibold text-neutral-black">
              {{ file.name || (isImageFileUploader ? $t('forms.label.image') : $t('forms.label.file')) }}
            </p>
            <p v-if="humanFileSize(file.size, 0)" class="mt-2.5 text-xs font-semibold text-neutral-silver">
              {{ `${$t('common.weight')} : ${humanFileSize(file.size, 0)}` }}
            </p>
          </div>
        </div>
        <Icon id="x" size="large" class="cursor-pointer text-neutral-silver" @click="toggleDeleteModal" />
      </div>

      <div v-else class="flex flex-col items-center justify-center">
        <Icon id="upload" size="large" class="text-neutral-grey" />
        <p class="mt-3 text-xs font-semibold text-neutral-grey">
          {{ props.context.label ? props.context.label : $t('molecules.fileUpload.uploadImage') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref, onBeforeMount, computed } from 'vue';
import axios from 'axios';
import { humanFileSize } from '@/utils/math';
import { getDisplayAllowedTypes } from '@/utils/media';
import { useMedias } from '@/stores/medias';
import { useI18n } from 'vue-i18n';
import type { Media } from '@/models/medias.model';
import { alert } from '@/utils/alerts';

const mediasStore = useMedias();

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
});

const { t } = useI18n();

const isLoading = ref(false);
const showPreview = ref(false);
const showDeleteModal = ref(false);
const isImageFileUploader = ref(true);

const fileInput = ref();

const file = reactive({ url: '', name: '', size: 0, dimensions: '' });

const sizeLimit = computed(() => (props.context.limit ? +props.context.limit : 1048576));
const authorizedFormats = computed(() => (props.context.formats ? props.context.formats : ['image/jpeg', 'image/png']));

const clearFile = (): void => {
  file.url = '';
  file.name = '';
  file.size = 0;
  file.dimensions = '';
  props.context.node.input(null);
  if (fileInput.value) fileInput.value.value = '';
};

const uploadFile = async (fileToUpload: File): Promise<void> => {
  try {
    isLoading.value = true;
    const formData = new FormData();
    formData.append('file', fileToUpload);
    formData.append('name', fileToUpload.name);
    formData.append('size', fileToUpload.size.toString());
    if (props.context.returnFile) return props.context.node.input(fileToUpload);
    const response = props.context.uploadRoute ? await props.context.uploadRoute(formData) : await mediasStore.postMedia(formData);
    if (props.context.returnUrl) return props.context.node.input(response.url);
    return props.context.node.input(response.mediaId || response._id);
  } catch (error) {
    alert(t('alerts.media_upload.error.title'), t('alerts.media_upload.error.message'), 'error', 4000);
    clearFile();
    return error;
  } finally {
    isLoading.value = false;
  }
};

const getFileMeta = (url: string): void => {
  const image = new Image();
  image.src = url;
  image.onload = (event) => {
    const { width, height }: unknown = event.target;
    file.dimensions = `${width}x${height}`;
  };
};

const getFileWeight = async (url: string): Promise<void> => {
  if (url.endsWith('.svg')) return;
  const response = await axios.get(url);
  const imageSize = response.headers['content-length'];
  file.size = parseInt(imageSize, 10);
};

const handleInput = async (e: Event): Promise<void> => {
  const { files } = e.target as HTMLInputElement;
  if (!files) return;
  const fileToUpload = files[0];
  if (fileToUpload.size > sizeLimit.value || !authorizedFormats.value.includes(fileToUpload.type)) {
    alert(
      t('molecules.fileUpload.errors.restrictions.title'),
      t('molecules.fileUpload.errors.restrictions.message', {
        maxSize: humanFileSize(sizeLimit.value),
        formats: getDisplayAllowedTypes(authorizedFormats.value),
      }),
      'error',
      4000
    );
    clearFile();
    return;
  }
  isLoading.value = true;
  file.name = fileToUpload.name;
  file.size = fileToUpload.size;

  const reader = new FileReader();
  reader.readAsDataURL(fileToUpload);
  reader.onload = (event) => {
    file.url = event.target?.result as string;
    getFileMeta(event.target?.result as string);
  };
  await uploadFile(fileToUpload);
};

const getMediaById = async (id: string): Promise<void> => {
  if (!id || isImageFileUploader.value === false) return;
  try {
    isLoading.value = true;
    const media: Media = await mediasStore.getMedia(id);
    if (!media || !media.url) return;
    file.url = media.url;
    getFileMeta(media.url);
    getFileWeight(media.url);
  } catch (error) {
    alert(t('alerts.media_get.error.title'), t('alerts.media_get.error.message'), 'error', 4000);
  } finally {
    isLoading.value = false;
  }
};

const getMediaByUrl = (fileUrl: string): void => {
  file.url = fileUrl;
  getFileMeta(fileUrl);
  getFileWeight(fileUrl);
};

const togglePreview = (): void => {
  if (!file.url) return;
  showPreview.value = !showPreview.value;
};

const toggleDeleteModal = (): void => {
  if (!file.url) return;
  showDeleteModal.value = !showDeleteModal.value;
};

const deleteFile = async (): Promise<void> => {
  try {
    isLoading.value = true;
    const isUrl = props.context._value && typeof props.context._value === 'string' && props.context._value.startsWith('http');
    if (props.context._value && typeof props.context._value === 'string' && !props.context.returnFile && !isUrl)
      await mediasStore.deleteMedia(props.context._value);
    clearFile();
    showDeleteModal.value = false;
  } catch (error) {
    alert(t('alerts.media_delete.error.title'), t('alerts.media_delete.error.message'), 'error', 4000);
  } finally {
    isLoading.value = false;
  }
};

onBeforeMount(async () => {
  if (!props.context.formats.includes('image/jpeg') || !props.context.formats.includes('image/png')) isImageFileUploader.value = false;
  const isUrl = props.context._value && typeof props.context._value === 'string' && props.context._value.startsWith('http');
  if (props.context._value && isUrl) getMediaByUrl(props.context._value);
  else if (props.context._value) await getMediaById(props.context._value);
});
</script>
