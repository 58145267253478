<template>
  <header class="relative z-40 flex w-full flex-col items-center justify-between gap-x-2 border-b border-neutral-cloud lg:bg-white lg:px-5">
    <!-- Mobile & Tablet only -->
    <div class="flex h-[56px] w-full flex-row items-center justify-start gap-x-2 bg-neutral-black px-4 lg:hidden lg:px-0">
      <Button class="h-fit text-white" type="link" icon="menu-2" @click="isMenuOpen = true" />
      <router-link to="/">
        <Logo class="w-full max-w-[135px]" color="light" />
      </router-link>
    </div>
    <!-- End Mobile & Tablet -->
    <!-- Desktop, tablet & mobile -->
    <div class="flex h-[60px] w-full items-center justify-between bg-white px-4 lg:flex lg:h-[80px] lg:px-0">
      <div v-if="(isSuperAdmin || isOrganisationOwner) && clients && clients.length" class="flex flex-row items-center gap-4">
        <FormKit
          :value="currentCityId"
          wrapperClass="w-[240px]"
          outerClass="flex items-center"
          type="dropdown"
          name="currentCity"
          :placeholder="$t('forms.placeholder.chooseCity')"
          :options="clients"
          :toggleSearch="true"
          :searchPlaceholder="$t('forms.placeholder.name')"
          :ghost="true"
          valueClasses="text-lg font-bold leading-snug"
          @input="onSelectClient"
        />
        <ClientStatus />
      </div>
      <div v-else class="text-lg font-bold text-neutral-black">{{ currentCityName }}</div>
      <!-- Desktop only -->
      <div class="hidden flex-row items-center gap-4 lg:flex">
        <div v-if="isLocalEnv">
          <FormKit v-model="$i18n.locale" type="select" :options="availableLocales" @input="changeLanguage" />
        </div>
        <!-- Indicate current dev environnement -->
        <EnvIndicator />
        <!-- End Indicate current dev environnement -->
        <IconBlock iconId="help" size="large" url="https://help.neocity.fr/hc/fr" target="_blank" />

        <!-- Notification center -->
        <!-- <IconBlock class="hidden sm:flex" iconId="bell" /> -->
        <!-- End Notification center -->

        <DropdownMenu :items="dropdownMenuItems">
          <Avatar class="cursor-pointer" :firstname="currentUser?.firstname" :lastname="currentUser?.lastname" />
        </DropdownMenu>
      </div>
      <!-- End Desktop only -->

      <!-- Mobile & Tablet only -->
      <DropdownMenu class="lg:hidden" :items="dropdownMenuItems">
        <Button size="medium" type="link" icon="dots-vertical" />
      </DropdownMenu>
      <!-- End Mobile & Tablet only -->
    </div>
    <!-- End Desktop, tablet & mobile -->
  </header>
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue';
import { useUser } from '@/stores/user';
import { useClient } from '@/stores/client';
import { useRoute, useRouter } from 'vue-router';
// import { useWindowSize } from '@vueuse/core';
import type { DropdownMenuItem } from '@/models/organisms.model';
import dayjs from 'dayjs';

// const { width: windowWidth } = useWindowSize();
// const isDesktop = computed(() => windowWidth.value >= 1024);

const userStore = useUser();
const clientStore = useClient();

const { isMenuOpen } = inject<{ isMenuOpen: boolean }>('menu') || {
  isMenuOpen: false,
};

const router = useRouter();
const currentRoute = useRoute();

const isLocalEnv = window.location.hostname === 'localhost';

const isSuperAdmin = computed(() => userStore?.user?.role.includes('superadmin'));
const isOrganisationOwner = computed(() => userStore?.user?.role.includes('organisation.owner'));

interface Client {
  id: string;
  name: string;
  code: string;
}

const clients = computed(
  () =>
    clientStore?.clients?.map((client: Client) => ({
      label: client.name,
      value: client.id,
      attrs: { code: client.code },
    }))
);

const currentCityName = computed(() => clientStore.client?.name);
const currentCityId = computed(() => clientStore.client?.id);
const currentUser = computed(() => userStore.user);

const dropdownMenuItems = ref<DropdownMenuItem[]>([
  // Uncomment the items when pages are ready
  // {
  //   label: 'organisms.header.dropdownMenu.items.account',
  //   iconId: 'user',
  //   callback: () => router.push({ name: 'account.main' }),
  // },
  // {
  //   label: 'organisms.header.dropdownMenu.items.users',
  //   iconId: 'users',
  //   callback: () => router.push({ name: 'permissions.main' }),
  // },
  // {
  //   label: 'organisms.header.dropdownMenu.items.appSettings',
  //   iconId: 'device-mobile',
  //   callback: () => router.push({ name: 'appManagement.main' }),
  // },
  // {
  //   label: 'organisms.header.dropdownMenu.items.tutorials',
  //   iconId: 'book-2',
  //   disabled: true,
  //   callback: () => router.push({ name: 'tutorials.main' }),
  // },
  // {
  //   label: 'organisms.header.dropdownMenu.items.helpCenter',
  //   iconId: 'help',
  //   hidden: isDesktop.value,
  //   callback: () => window.open('https://help.neocity.fr/hc/fr', '_blank'),
  // },
  // {
  //   isSeparator: true,
  // },
  {
    label: 'actions.logout',
    iconId: 'logout',
    color: 'danger-basic',
    callback: async () => {
      await userStore.logout();
      router.push({ name: 'login' });
    },
  },
]);

const availableLocales = ref([
  { label: '🇫🇷', value: 'fr-FR' },
  { label: '🇬🇧', value: 'en-US' },
]);

const changeLanguage = (locale: string): void => {
  if (locale === 'fr-FR') dayjs.locale('fr');
  else dayjs.locale('en');
};

const onSelectClient = async (clientId: string): Promise<void> => {
  await clientStore.getClient(clientId);
  userStore.$patch({ clientId });
  if (currentRoute.name === 'dashboard') router.go(0);
  else router.push({ name: 'dashboard' });
};
</script>
