export default {
  administrationInterface: "Interface d'administration",

  consultations: {
    createIdea: {
      details: {
        title: 'Détails',
      },
      contributor: {
        title: 'Participant.e',
      },
    },
    idea: {
      details: {
        blockTitle: 'Détails',
        title: 'Titre',
        description: 'Description',
      },

      followup: {
        blockTitle: "Suivi sur l'application",
      },

      participant: {
        blockTitle: 'Participant.e',
        civility: 'Civilité',
        email: 'Adresse e-mail',
        firstname: 'Prénom',
        lastname: 'Nom',
        type: 'Type',
      },
    },
    createCategory: {
      details: {
        title: 'Détails',
      },

      ideasRecipient: {
        title: 'Destinataires des nouvelles idées',
      },
    },
    configuration: {
      list: {
        columns: {
          actions: 'Actions',
          color: 'Couleur',
          icon: 'Icône',
          name: 'Nom de la thématique',
          recipients: 'Destinataires',
        },
      },
      settings: {
        title: 'Paramètres',
      },
      recipients: {
        title: 'Destinataires par défaut des nouvelles idées',
        subtitle: 'Ces destinataires recevront les nouvelles idées qui ne sont associées à aucune thématique',
        tooltip:
          "Si une idée ne comporte pas de thématique ou si la thématique n'a pas de destinataires, les destinataires par défaut seront utilisés.",
      },
      tabs: {
        settings: 'Paramètres',
        themes: 'Thématiques',
      },
    },
  },

  notifications: {
    tabs: {
      past: 'Passées',
      inComing: 'À venir',
    },
  },

  login: {
    title: 'Bienvenue sur Beta Admin',
    subtitle: 'Veuillez-vous connecter pour accéder à cet espace',
    email: {
      label: 'Email',
      placeholder: 'Votre email',
    },
    password: {
      label: 'Mot de passe',
      placeholder: 'Votre mot de passe',
      forgottenPassword: 'Mot de passe oublié ?',
    },
    successfulLogin: 'Connexion réussie',
    invalidCredentials: 'Combinaison non reconnue',
  },

  forgottenPassword: {
    title: 'Mot de passe oublié ?',
    subtitle: 'Entrez votre adresse mail pour changer votre mot de passe',
    email: {
      label: 'Email',
      placeholder: 'Votre email',
      incorrectEmail: 'Adresse email incorrecte',
      emptyEmail: 'Veuillez renseigner votre adresse email.',
      unknownEmail: 'Adresse email inconnue',
    },
  },

  forgottenPasswordSuccess: {
    title: 'Votre demande a bien été prise en compte',
    subtitle: 'Si un compte existe à cette adresse, un email de réinitialisation vous sera envoyé.',
    returnToLogin: 'Retour au formulaire de connexion',
  },

  resetPassword: {
    title: 'Nouveau mot de passe',
    password: {
      label: 'Nouveau mot de passe',
      placeholder: 'mot de passe',
    },
    confirmPassword: {
      label: 'Confirmation du nouveau mot de passe',
      placeholder: 'confirmation du mot de passe',
      passwordsNotMatching: 'Les mots de passe ne correspondent pas',
    },
    invalidLink: 'Le lien de réinitialisation est invalide ou a expiré.',
    returnToForgottenPassword: 'Retour au formulaire de mot de passe oublié',
    returnToLogin: 'Retour au formulaire de connexion',
    passwordInvalid: 'Votre mot de passe doit contenir au moins 10 caractères dont une lettre, un chiffre et un caractère spécial',
  },

  resetPasswordSuccess: {
    title: 'Votre mot de passe à été réinitialisé !',
    subtitle: 'Vous pouvez de nouveau vous connecter à votre espace en utilisant votre nouveau mot de passe.',
    returnToLogin: 'Retour au formulaire de connexion',
  },
};
