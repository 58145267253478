import { defineStore } from 'pinia';

import * as Sentry from '@sentry/vue';

import { useUser } from '@/stores/user';

import { axiosNeocityInstanceV3 } from '@/api/axios';

import { GooglePrediction, GooglePlaceDetails } from '@/models/google.model';

type Range = 'total' | 'yesterday' | 'lastWeek' | 'lastMonth';

type DateRange = {
  startDate: string;
  endDate: string;
};

type DimensionValue = {
  value: number | undefined;
  last_update: number | undefined;
};

interface Dimension {
  total: DimensionValue | undefined;
  yesterday: DimensionValue | undefined;
  lastWeek: DimensionValue | undefined;
  lastMonth: DimensionValue | undefined;
}

interface State {
  downloads: Dimension;
  sessions: Dimension;
  pageViews: Dimension;
  sessionDuration: Dimension;
}

export const useGoogle = defineStore({
  id: 'google',
  state: (): State => ({
    downloads: {
      total: {
        value: undefined,
        last_update: undefined,
      },
      yesterday: {
        value: undefined,
        last_update: undefined,
      },
      lastWeek: {
        value: undefined,
        last_update: undefined,
      },
      lastMonth: {
        value: undefined,
        last_update: undefined,
      },
    },
    sessions: {
      total: {
        value: undefined,
        last_update: undefined,
      },
      yesterday: {
        value: undefined,
        last_update: undefined,
      },
      lastWeek: {
        value: undefined,
        last_update: undefined,
      },
      lastMonth: {
        value: undefined,
        last_update: undefined,
      },
    },
    pageViews: {
      total: {
        value: undefined,
        last_update: undefined,
      },
      yesterday: {
        value: undefined,
        last_update: undefined,
      },
      lastWeek: {
        value: undefined,
        last_update: undefined,
      },
      lastMonth: {
        value: undefined,
        last_update: undefined,
      },
    },
    sessionDuration: {
      total: {
        value: undefined,
        last_update: undefined,
      },
      yesterday: {
        value: undefined,
        last_update: undefined,
      },
      lastWeek: {
        value: undefined,
        last_update: undefined,
      },
      lastMonth: {
        value: undefined,
        last_update: undefined,
      },
    },
  }),
  getters: {
    clientId(): string | undefined {
      const userStore = useUser();
      return userStore.$state.clientId;
    },
  },
  actions: {
    async getPeriodTotal(response): Promise<number> {
      let total = 0;
      await response.data[0].rows.forEach((row) => {
        total += parseInt(row.metricValues[0].value, 10);
      });
      return total;
    },
    async getPeriodAverage(response): Promise<number> {
      let total = 0;
      await response.data[0].rows.forEach((row) => {
        total += parseInt(row.metricValues[0].value, 10);
      });
      return total / response.data[0].rows.length;
    },
    async getRealAverageSessionDuration(response): Promise<number> {
      let duration = 0;
      let users = 0;
      await response.data[0].rows.forEach((row) => {
        duration += parseInt(row.metricValues[0].value, 10);
        users += parseInt(row.metricValues[1].value, 10);
      });
      if (!users || !duration) return 0;
      return duration / users;
    },
    getDownloads(dateRange: DateRange, period: Range): Promise<number> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .post(`google/analytics/${this.clientId}`, {
            dimensions: [{ name: 'date' }],
            metrics: [{ name: 'newUsers' }],
            dateRanges: [dateRange],
            orderBys: [{ dimension: { orderType: 'ALPHANUMERIC', dimensionName: 'date' } }],
          })
          .then(async (response) => {
            const total = await this.getPeriodTotal(response);
            if (period) {
              this.$state.downloads[period].value = total;
              this.$state.downloads[period].last_update = Date.now();
            }
            resolve(total);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
    getSessions(dateRange: DateRange, period: Range): Promise<number> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .post(`google/analytics/${this.clientId}`, {
            dimensions: [{ name: 'date' }],
            metrics: [{ name: 'sessions' }],
            dateRanges: [dateRange],
            orderBys: [{ dimension: { orderType: 'ALPHANUMERIC', dimensionName: 'date' } }],
          })
          .then(async (response) => {
            const total = await this.getPeriodTotal(response);
            if (period) {
              this.sessions[period].value = total;
              this.sessions[period].last_update = Date.now();
            }
            resolve(total);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
    getPageViews(dateRange: DateRange, period: Range): Promise<number> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .post(`google/analytics/${this.clientId}`, {
            dimensions: [{ name: 'date' }],
            metrics: [{ name: 'screenPageViews' }],
            dateRanges: [dateRange],
            orderBys: [{ dimension: { orderType: 'ALPHANUMERIC', dimensionName: 'date' } }],
          })
          .then(async (response) => {
            const total = await this.getPeriodTotal(response);
            if (period) {
              this.pageViews[period].value = total;
              this.pageViews[period].last_update = Date.now();
            }
            resolve(total);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
    getAverageSessionDuration(dateRange: DateRange, period: Range): Promise<number> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .post(`google/analytics/${this.clientId}`, {
            dimensions: [{ name: 'date' }],
            metrics: [{ name: 'userEngagementDuration' }, { name: 'activeUsers' }],
            dateRanges: [dateRange],
            orderBys: [{ dimension: { orderType: 'ALPHANUMERIC', dimensionName: 'date' } }],
          })
          .then(async (response) => {
            const average = await this.getRealAverageSessionDuration(response);
            if (period) {
              this.sessionDuration[period].value = average;
              this.sessionDuration[period].last_update = Date.now();
            }
            resolve(average);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
    getGooglePlacesAutocomplete(
      queryString: string,
      types: string[],
      latitude?: number,
      longitude?: number,
      radius?: number
    ): Promise<GooglePrediction[]> {
      const params: { input: string; types?: string; location?: string; radius?: number; strictbounds?: boolean } = { input: queryString };
      if (types.length) params.types = types.join('|');
      if (latitude && longitude) params.location = `${latitude},${longitude}`;
      if (radius) params.radius = radius;
      if (latitude && longitude && radius) params.strictbounds = true;
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .get(`/google/autocomplete`, { params })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getGooglePlaceDetails(placeId: string): Promise<GooglePlaceDetails> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .get(`/google/placedetails?place_id=${placeId}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  persist: true,
});
