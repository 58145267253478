import global from './global.js';
import datatable from './datatable/index';
import paginator from './paginator/index';
import multiselect from './multiselect/index';

export default {
  global,
  datatable,
  paginator,
  multiselect,
};
