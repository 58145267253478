export default {
  actions: 'Actions',
  activated: 'Enabled',
  deactivated: 'Disabled',
  open: 'Open',
  closed: 'Closed',
  displayed: 'Displayed',
  hidden: 'Hidden',
  firstname: 'Firstname',
  lastname: 'Lastname',
  user: 'User',
  weight: 'Weight',
  undefined: 'Undefined',

  states: {
    default: 'Default',
    custom: 'Custom',
  },

  civility: {
    female: 'Ms.',
    male: 'Mr.',
  },

  userType: {
    admin: 'Admin',
    anonymous: 'Anonymous',
    user: 'User',
  },

  days: {
    monday: 'Monday',
    1: 'Monday',
    tuesday: 'Tuesday',
    2: 'Tuesday',
    wednesday: 'Wednesday',
    3: 'Wednesday',
    thursday: 'Thursday',
    4: 'Thursday',
    friday: 'Friday',
    5: 'Friday',
    saturday: 'Saturday',
    6: 'Saturday',
    sunday: 'Sunday',
  },

  environments: {
    local: 'Local environment',
    dev: 'Development environment',
  },

  profile: 'Profile',

  settings: 'Settings',

  helpCenter: 'Help center',

  platforms: {
    android: 'ANDROID',
    ios: 'IOS',
    web: 'Web',
  },

  CGUAppleBanner: {
    title: 'IMPORTANT - Update of your Apple Developer account CGU',
    message:
      "It is essential that you accept these new CGUs so that your mobile application continues to be available on the App Store, and so that we can make all the necessary updates for the proper functioning of your application ! <br/>In order to accept these new CGUs:<br/><ul><li>- Log into your Apple Developer account via the following link: <a href='https://developer.apple.com/account' style='text-decoration: underline;'>https://developer.apple.com/account</a></li><li>- Click on the “Review” button, then on “Accept”</li><li>- And that's it!</li></ul><br/><i>(Please disregard this information if you have already accepted the new CGUs.)</i><br/>As a reminder, your community is responsible for and owns its Apple Developer account. Neocity does not have the rights to accept these new CGUs.<br/>If you have any questions, please contact your Project Manager.",
  },

  clientStatus: {
    client: 'Client',
    prospect: 'Prospect',
    archived: 'Archived',
    test: 'Test',
    churned: 'Churned',
    unknown: 'Unknown',
  },
  fancyColors: {
    purple: 'Purple',
    cyan: 'Cyan',
    aqua: 'Aqua',
    blue: 'Blue',
    navy: 'Navy',
    grey: 'Grey',
    apricot: 'Apricot',
    pink: 'Pink',
    red: 'Red',
    mustard: 'Mustard',
    black: 'Black',
    brown: 'Brown',
    green: 'Green',
  },
};
