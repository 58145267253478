export default {
  administrationInterface: 'Administration interface',

  consultations: {
    createIdea: {
      details: {
        title: 'Details',
      },
      contributor: {
        title: 'Contributor',
      },
    },
    idea: {
      details: {
        blockTitle: 'Details',
        title: 'Title',
        description: 'Description',
      },

      followup: {
        blockTitle: 'Follow-up on the application',
      },

      participant: {
        blockTitle: 'Attendee',
        civility: 'Civility',
        email: 'E-mail address',
        firstname: 'Firstname',
        lastname: 'Lastname',
        type: 'Type',
      },
    },
    createCategory: {
      details: {
        title: 'Details',
      },

      ideasRecipient: {
        title: 'Recipients of new ideas',
      },
    },
    configuration: {
      list: {
        columns: {
          actions: 'Actions',
          color: 'Color',
          icon: 'Icon',
          name: 'Name of the theme',
          recipients: 'Recipients',
        },
      },
      settings: {
        title: 'Settings',
      },
      recipients: {
        title: 'Default recipients of new ideas',
        subtitle: 'These recipients will receive new ideas that are not associated with any theme',
        tooltip:
          "Si une idée ne comporte pas de thématique ou si la thématique n'a pas de destinataires, les destinataires par défaut seront utilisés.",
      },
      tabs: {
        settings: 'Settings',
        themes: 'Themes',
      },
    },
  },

  notifications: {
    tabs: {
      past: 'Past',
      inComing: 'Incoming',
    },
  },

  login: {
    title: 'Welcome on Beta Admin',
    subtitle: 'Please login to continue',
    email: {
      label: 'Email',
      placeholder: 'Your email',
    },
    password: {
      label: 'Password',
      placeholder: 'Your password',
      forgottenPassword: 'Forgotten password ?',
    },
    successfulLogin: 'Successful login',
    invalidCredentials: 'Invalid credentials',
  },

  forgottenPassword: {
    title: 'Forgotten password ?',
    subtitle: 'Please enter your email address to receive a link to reset your password',
    email: {
      label: 'Email',
      placeholder: 'Your email',
      incorrectEmail: 'Incorrect email format',
      emptyEmail: 'Email is required.',
      unknownEmail: 'Unknown email address',
    },
  },

  forgottenPasswordSuccess: {
    title: 'Your request has been taken into account',
    subtitle: 'You will receive an email shortly to reset your password',
    returnToLogin: 'Return to login',
  },

  resetPassword: {
    title: 'New password',
    password: {
      label: 'New password',
      placeholder: 'password',
    },
    confirmPassword: {
      label: 'Confirm password',
      placeholder: 'confirm password',
      passwordsNotMatching: 'Passwords do not match',
    },
    invalidLink: 'Invalid or expired link.',
    returnToForgottenPassword: 'Return to forgotten password form',
    returnToLogin: 'Return to login',
    passwordInvalid: 'Password must be at least 10 characters long and contain at least one letter, one number and one special character.',
  },

  resetPasswordSuccess: {
    title: 'Your password has been reset',
    subtitle: 'You can now login with your new password',
    returnToLogin: 'Return to login',
  },
};
