export default {
  fileUpload: {
    uploadImage: 'Téléverser une image',
    weight: 'Poids',
    size: 'Taille',
    no_image: 'Aucune image',
    defaultTitle: "Image d'en tête",
    errors: {
      restrictions: {
        title: "Impossible de téléverser l'image",
        message: 'Merci de téléverser une image de moins de {maxSize} au format {formats}.',
      },
    },
  },

  iconpicker: {
    title: 'Icône',
    search_placeholder: 'Rechercher une icône',
    no_result: 'Aucun résultat',
  },

  timelineitem: {
    no_history: 'Aucun historique à afficher',
  },

  statistic: {
    error: 'Erreur.',
    periods: {
      yesterday: 'hier',
      lastWeek: 'sur 7 jours',
      lastMonth: 'sur 30 jours',
    },
  },

  doughnutChart: {
    total: 'au total',
  },
};
