const neocityIcons = [
  'celcius',
  'cloud-double',
  'cloud-rainbow',
  'day-cloud',
  'day-hail',
  'day-rain-thunder',
  'day-rain',
  'day-snow-thunder',
  'day-snow',
  'day-thunder-storm',
  'drought',
  'earthquake',
  'fahrenheit',
  'flag-buoy',
  'flood',
  'hail-thunder',
  'hail',
  'hurricane-1',
  'hurricane',
  'moon-waning-crescent',
  'moon-waning-gibbous',
  'moon-waxing-crescent',
  'moon-waxing-gibbous',
  'night-cloud',
  'night-hail',
  'night-rain-thunder',
  'night-rain',
  'night-snow-thunder',
  'night-snow',
  'night-thunder-storm',
  'nighttime',
  'rain-thunder',
  'rain',
  'rainbow',
  'snow-thunder',
  'snow',
  'snowflake',
  'storm',
  'sunny',
  'sunrise',
  'sunset',
  'temperature-decrease',
  'temperature-increase',
  'thermometer-cold',
  'thermometer-hot',
  'thermometer-negative',
  'thermometer-positive',
  'thermometer',
  'thunder-storm',
  'thunderstorm',
  'umbrella-close',
  'umbrella-open',
  'uv-ray',
  'water-level-down',
  'water-level-up',
  'wind-flag',
  'center-point',
  'cross-over',
  'expand-1',
  'expand-2',
  'expand-3',
  'expand-4',
  'expand-all-1',
  'expand-corner',
  'expand-diagonal-1',
  'expand-diagonal-2',
  'expand-diagonal-3',
  'expand-diagonal-4',
  'expand-diagonal-5',
  'expand-diagonal-6',
  'expand-horizontal-1',
  'expand-horizontal-2',
  'expand-horizontal-3',
  'expand-horizontal-4',
  'expand-horizontal-5',
  'expand-horizontal-6',
  'expand-horizontal-7',
  'expand-vertical-1',
  'expand-vertical-2',
  'expand-vertical-3',
  'expand-vertical-4',
  'expand-vertical-5',
  'expand-vertical-6',
  'flip-down-1',
  'flip-down-2',
  'flip-up-1',
  'flip-up-2',
  'focus-1',
  'focus-2',
  'launch',
  'move-back',
  'move-bottom',
  'move-down-1',
  'move-down-2',
  'move-left-1',
  'move-left-2',
  'move-left-right',
  'move-right-1',
  'move-right-2',
  'move-top',
  'move-up-1',
  'move-up-2',
  'shrink-1',
  'shrink-all-1',
  'shrink-diagonal-1',
  'shrink-diagonal-2',
  'shrink-horizontal-1',
  'shrink-horizontal-2',
  'shrink-horizontal-3',
  'shrink-vertical-1',
  'touch-up-1',
  'touch-up-2',
  'brightness-decrease',
  'brightness-increase',
  'cog-check',
  'cog-double-1',
  'cog-double-2',
  'cog-lightbulb',
  'cog-magnify',
  'cog-play',
  'cog-square',
  'cog',
  'gauge',
  'hammer-1',
  'hammer-2',
  'screwdriver',
  'settings-1',
  'settings-2',
  'settings-3',
  'slider-1',
  'slider-2',
  'swtich-down',
  'swtich-up',
  'toolbox',
  'wave',
  'wrench-double',
  'wrench',
  'logo-aim',
  'logo-amazon-web-service',
  'logo-app-store',
  'logo-apple',
  'logo-baidu',
  'logo-brightkite',
  'logo-circle-apple',
  'logo-circle-appstore',
  'logo-circle-baidu',
  'logo-circle-delicious',
  'logo-circle-design-bump',
  'logo-circle-design-float',
  'logo-circle-designmoo',
  'logo-circle-email',
  'logo-circle-facebook',
  'logo-circle-gabbr',
  'logo-circle-google-talk',
  'logo-circle-google',
  'logo-circle-gowalla-pin',
  'logo-circle-icq',
  'logo-circle-identica',
  'logo-circle-imessage',
  'logo-circle-instagram',
  'logo-circle-memori',
  'logo-circle-meta-cafe',
  'logo-circle-mixx',
  'logo-circle-mobile-me',
  'logo-circle-mr-wong',
  'logo-circle-twitter-bird',
  'logo-circle-twitter',
  'logo-circle-vimeo',
  'logo-circle-windows',
  'logo-circle-youtube-clip',
  'logo-circle-youtube',
  'logo-delicious',
  'logo-design-bump',
  'logo-design-float',
  'logo-dribbble',
  'logo-dropbox',
  'logo-facebook',
  'logo-gabbr',
  'logo-google-drive',
  'logo-google-play-store',
  'logo-google-talk',
  'logo-google',
  'logo-imessage',
  'logo-instagram',
  'logo-itunes',
  'logo-linkedin',
  'logo-memori',
  'logo-mobile-me',
  'logo-msn',
  'logo-new-circle-google-plus',
  'logo-new-google-plus',
  'logo-odnoklassniki',
  'logo-openid',
  'logo-orkut',
  'logo-osx',
  'logo-rss',
  'logo-tumblr',
  'logo-twitter-bird',
  'logo-twitter',
  'logo-viddler',
  'logo-vimeo',
  'logo-whatsapp',
  'logo-windows',
  'logo-you-tube',
  'logo-youtube-clip',
  'arrow-double-down',
  'arrow-box-down-1',
  'arrow-box-down-2',
  'arrow-box-down-3',
  'arrow-box-down-4',
  'arrow-box-down-5',
  'arrow-box-down-left-1',
  'arrow-box-down-left-2',
  'arrow-box-down-right-1',
  'arrow-box-down-right-2',
  'arrow-box-left-1',
  'arrow-box-left-2',
  'arrow-box-left-3',
  'arrow-box-left-4',
  'arrow-box-left-5',
  'arrow-box-right-1',
  'arrow-box-right-2',
  'arrow-box-right-3',
  'arrow-box-right-4',
  'arrow-box-right-5',
  'arrow-box-up-1',
  'arrow-box-up-2',
  'arrow-box-up-3',
  'arrow-box-up-4',
  'arrow-box-up-5',
  'arrow-circle-down-1',
  'arrow-circle-down-2',
  'arrow-circle-down-3',
  'arrow-circle-down-4',
  'arrow-circle-down-5',
  'arrow-circle-down-6',
  'arrow-circle-down-left-1',
  'arrow-circle-down-left-2',
  'arrow-circle-down-left-3',
  'arrow-circle-down-right-1',
  'arrow-circle-down-right-2',
  'arrow-circle-down-right-3',
  'arrow-circle-left-1',
  'arrow-circle-left-2',
  'arrow-circle-left-3',
  'arrow-circle-left-4',
  'arrow-circle-left-5',
  'arrow-circle-left-6',
  'arrow-circle-right-1',
  'arrow-circle-right-2',
  'arrow-circle-right-3',
  'arrow-circle-right-4',
  'arrow-circle-right-5',
  'arrow-circle-right-6',
  'arrow-circle-up-1',
  'arrow-circle-up-2',
  'arrow-circle-up-3',
  'arrow-circle-up-4',
  'arrow-circle-up-5',
  'arrow-circle-up-6',
  'arrow-delete',
  'arrow-double-left',
  'arrow-double-right',
  'arrow-double-up',
  'arrow-down-1',
  'arrow-down-2',
  'arrow-down-3',
  'arrow-down-4',
  'arrow-down-5',
  'arrow-down-6',
  'arrow-down-7',
  'arrow-down-8',
  'arrow-down-9',
  'arrow-down-10',
  'arrow-down-11',
  'arrow-down-12',
  'arrow-down-13',
  'arrow-down-left-1',
  'arrow-down-left-2',
  'arrow-down-left-3',
  'arrow-down-left-4',
  'arrow-down-left-5',
  'arrow-down-left-6',
  'arrow-down-left-7',
  'arrow-down-right-1',
  'arrow-down-right-2',
  'arrow-down-right-3',
  'arrow-down-right-4',
  'arrow-down-right-5',
  'arrow-down-right-6',
  'arrow-down-right-7',
  'arrow-forward-delete',
  'arrow-left-1',
  'arrow-left-2',
  'arrow-left-3',
  'arrow-left-4',
  'arrow-left-5',
  'arrow-left-6',
  'arrow-left-7',
  'arrow-left-8',
  'arrow-left-9',
  'arrow-left-10',
  'arrow-left-11',
  'arrow-left-12',
  'arrow-left-13',
  'arrow-right-1',
  'arrow-right-2',
  'arrow-right-3',
  'arrow-right-4',
  'arrow-right-5',
  'arrow-right-6',
  'arrow-right-7',
  'arrow-right-8',
  'arrow-right-9',
  'arrow-right-10',
  'arrow-right-11',
  'arrow-right-12',
  'arrow-right-13',
  'arrow-triple-down',
  'arrow-triple-left',
  'arrow-triple-right',
  'arrow-triple-up',
  'arrow-up-1',
  'arrow-up-2',
  'arrow-up-3',
  'arrow-up-4',
  'arrow-up-5',
  'arrow-up-6',
  'arrow-up-7',
  'arrow-up-8',
  'arrow-up-9',
  'arrow-up-10',
  'arrow-up-11',
  'arrow-up-12',
  'arrow-up-13',
  'notepad-1',
  'notepad-2',
  'notepad-add',
  'notepad-check',
  'notepad-delete',
  'notepad-download',
  'notepad-edit',
  'notepad-favorite-heart',
  'notepad-favorite-star',
  'notepad-question',
  'notepad-refresh',
  'notepad-search',
  'notepad-setting',
  'notepad-share',
  'notepad-subtract',
  'notepad-timeout',
  'notepad-upload',
  'notepad-warning',
  'file-checklist-edit',
  'file-checklist-quill',
  'file-checklist',
  'task-1',
  'task-2',
  'task-add',
  'task-block',
  'task-cash',
  'task-check-1',
  'task-check-2',
  'task-checklist',
  'task-delete',
  'task-download-1',
  'task-download-2',
  'task-edit',
  'task-favorite-heart',
  'task-favorite-star',
  'task-lock',
  'task-question',
  'task-refresh',
  'task-setting',
  'task-share',
  'task-stubtract',
  'task-timeout',
  'task-upload-1',
  'task-upload-2',
  'task-view',
  'task-warning',
  'file-acrobat',
  'file-notepad-1',
  'file-notepad-2',
  'file-pdf-1',
  'file-pdf-2',
  'file-rtf-1',
  'file-rtf-2',
  'file-stamp',
  'file-table',
  'file-text-chart',
  'file-words-1',
  'file-words-2',
  'file-zip',
  'folder-1',
  'folder-2',
  'folder-add',
  'folder-block-1',
  'folder-block-2',
  'folder-bookmark-1',
  'folder-bookmark-2',
  'folder-cash-1',
  'folder-cash-2',
  'folder-check',
  'folder-code',
  'folder-delete',
  'folder-document',
  'folder-download',
  'folder-edit-1',
  'folder-edit-2',
  'folder-favorite-heart',
  'folder-favorite-star',
  'folder-files-edit',
  'folder-files-favorite',
  'folder-files-lock',
  'folder-files',
  'folder-hierarchy',
  'folder-home-1',
  'folder-home-2',
  'folder-lock-1',
  'folder-lock-2',
  'folder-medium',
  'folder-music-1',
  'folder-music-2',
  'folder-network-1',
  'folder-network-2',
  'folder-new',
  'folder-picture-1',
  'folder-picture-2',
  'folder-question',
  'folder-refresh',
  'folder-setting',
  'folder-share',
  'folder-small',
  'folder-subtract',
  'folder-swap',
  'folder-timeout',
  'folder-upload',
  'folder-video-1',
  'folder-video-2',
  'folder-view-1',
  'folder-view-2',
  'folder-warning',
  'folder-zip',
  'copy-1',
  'copy-2',
  'copy-3',
  'file-add-1',
  'file-add-2',
  'file-badge',
  'file-block-1',
  'file-block-2',
  'file-bookmark',
  'file-check-1',
  'file-check-2',
  'file-delete-1',
  'file-delete-2',
  'file-download-1',
  'file-download-2',
  'file-edit-1',
  'file-edit-2',
  'file-favorite-heart-1',
  'file-favorite-heart-2',
  'file-favorite-heart-3',
  'file-favorite-star-1',
  'file-favorite-star-2',
  'file-hand-1',
  'file-hand-2',
  'file-home-1',
  'file-home-2',
  'file-information',
  'file-landscape-new',
  'file-landscape-picture',
  'file-landscape-text',
  'file-lock-1',
  'file-lock-2',
  'file-module-1',
  'file-new-1',
  'file-new-2',
  'file-new-3',
  'file-question-1',
  'file-question-2',
  'file-refresh-1',
  'file-refresh-2',
  'file-secure',
  'file-setting-1',
  'file-setting-2',
  'file-setting-3',
  'file-share-1',
  'file-share-2',
  'file-shredder',
  'file-subtract-1',
  'file-subtract-2',
  'file-swap',
  'file-sync-1',
  'file-sync-2',
  'file-timeout2',
  'file-upload-1',
  'file-upload-2',
  'file-view-1',
  'file-view-2',
  'file-warning-1',
  'file-warning-2',
  'files-1',
  'files-2',
  'files-3',
  'files-landscape',
  'school-book',
  'school-grade-a',
  'school-grade-f',
  'school-graduation',
  'school-international-book',
  'school-listening-book',
  'school-math-blackboard',
  'school-math-laptop',
  'school-view-book',
  'science-atom-1',
  'science-beaker',
  'science-brain',
  'science-chemical-test-tube',
  'science-chemical',
  'science-chemistry-equipment',
  'science-chemistry',
  'science-dna',
  'science-formula',
  'science-lightbulb',
  'science-proton',
  'science-robot-1',
  'science-robot-2',
  'science-test-tube-holder',
  'science-test-tube',
  'science-the-core',
  'science-wormhole',
  'christmas-ball',
  'christmas-candle',
  'christmas-tree',
  'party-hat',
  'santa-sleigh',
  'snow-crystal',
  'snow-globe',
  'snowman',
  'star-wand',
  'baby-apron',
  'baby-bed',
  'baby-hanging-toy',
  'baby-milk-bottle',
  'baby-pacifier',
  'baby-stacker',
  'baby-stroller',
  'baby',
  'letter-blocks',
  'remote-car',
  'rocking-horse',
  'slider',
  'teddy-bear',
  'cupid-bow',
  'engagement-anniversary',
  'heart-arrow',
  'heart-balloons',
  'heart-care',
  'heart-coffee-cup',
  'heart-death',
  'heart-lockon',
  'heart-protect',
  'heart-search',
  'heart-wand',
  'lips',
  'love-couple',
  'love-music',
  'love-temperature',
  'phone-love-message',
  'romance-flowers',
  'romance-love-calendar',
  'romance-rose',
  'teardrop',
  'wedding-cake',
  'wedding-car',
  'wedding-certificate',
  'wedding',
  'whale-heart',
  'angry-bird',
  'bomberman-boxing-glove',
  'bomberman-fire',
  'bomberman-kick-bomb',
  'cookie-monster',
  'eskimo',
  'fire-bucket',
  'flappy-bird',
  'furby',
  'game-controller-1',
  'game-controller-2',
  'game-controller-3',
  'game-controller-wireless',
  'gameboy-advance',
  'gameboy-snake',
  'gameboy',
  'gaming-console',
  'gaming-flowers',
  'ghost',
  'gordo',
  'gorilla',
  'kirby-cloud',
  'kirby-knight-mode',
  'kirby-knight',
  'kirby-monster-mushroom',
  'kirby-monster-tree-1',
  'kirby-monster-tree-2',
  'korby-flying',
  'loot-bag-gem',
  'mario-cloud',
  'mario-flame-flower-1',
  'mario-flame-flower-2',
  'mario-flower-monster-fire',
  'mario-flower-monster',
  'mario-monster-mushroom',
  'mario-mushroom',
  'mario-question-box',
  'mario',
  'megaman',
  'monster-bird',
  'monster-fire',
  'mr-potato',
  'pacman-ghost',
  'pacman-run',
  'pacman',
  'pokeball',
  'scarfy',
  'sonic-run',
  'space-invader-1',
  'space-invader-2',
  'space-invader-3',
  'symbol-steam',
  'tamagochi',
  'tetris',
  'turtle',
  'wall-e',
  'wario',
  'wizard',
  'yoshi',
  'zelda',
  'zombie',
  'balloon',
  'cards-1',
  'cards-2',
  'cards-diamond',
  'cards-hand',
  'cards-heart',
  'cards-spade',
  'casino-chip',
  'chairs-umbrella',
  'checkers',
  'chess-piece',
  'circus-tent-1',
  'circus-tent-2',
  'clown',
  'dice-1',
  'dice-2',
  'disco-ball',
  'dj-booth',
  'domino',
  'ferris-wheel',
  'firework-rocket',
  'flag-festival',
  'gift-sharing',
  'gold-hole-flag',
  'harpoon',
  'kite',
  'lamp-lava',
  'magic-rabbit',
  'mask-double',
  'mask-frown',
  'mask-happy',
  'merry-go-round',
  'party-mask',
  'party-popper',
  'roller-coaster',
  'show-curtain',
  'slot-machine-number-seven',
  'slot-machine',
  'sunscreen',
  'tent',
  'tic-tac-toe',
  'ticket-1',
  'ticket-2',
  'ticket-3',
  'ticket-4',
  'water-gun',
  'boots-1',
  'boots-2',
  'boots-3',
  'bowtie',
  'button',
  'cap-police',
  'cap',
  'cleats',
  'clothes-hanger',
  'dress',
  'glasses-1',
  'glasses-2',
  'glasses-3',
  'glasses-4',
  'glasses-round-1',
  'glasses-round-2',
  'glasses-round-3',
  'glasses-square',
  'gloves',
  'hat-1',
  'hat-beanie-1',
  'hat-beanie-2',
  'hat-charlie-chaplin',
  'hat-cowboy',
  'hat-lady',
  'hat-magician',
  'hat-snow',
  'hat-sombrero',
  'hat-tall',
  'helmet-mining',
  'high-heels',
  'life-jacket',
  'long-sleeve-t-shirt',
  'mannequin',
  'monocle',
  'moutache-monocle',
  'necktie',
  'pants',
  'polo-shirt',
  'sandals',
  'shirt-folded',
  'shirt-tie',
  'shirt',
  'shorts-1',
  'shorts-2',
  'skirt',
  'slippers',
  'sneakers',
  'sock',
  'suit',
  't-shirt',
  'tank-top',
  'vest',
  'walking-cane',
  'apron',
  'barbecue-stove',
  'blending-machine',
  'boil',
  'bottle-opener',
  'cheese-shredder',
  'chef-hat',
  'cooker-hood',
  'cooking-gloves',
  'cooking-timer',
  'cutting-knife-1',
  'cutting-knife-2',
  'dining-set',
  'food-dome',
  'fork-knife',
  'fork-spoon',
  'frying-machine',
  'frying-pan',
  'gas-burner',
  'grill-rack',
  'juicer',
  'kitchen-basket',
  'kitchen-scale',
  'knife-rack',
  'ladle',
  'microwave',
  'mixing-machine',
  'oven',
  'pan-fry',
  'refrigerator',
  'rice-cooker',
  'rolling-pin',
  'seasoning',
  'snow-crystal2',
  'spatula',
  'stainer',
  'steam',
  'temperature-high',
  'temperature-low',
  'temperature-medium',
  'turning-spatula',
  'whisks',
  'wine-opener',
  'apple-1',
  'apple-2',
  'banana',
  'barbecue',
  'bellpepper',
  'birthday-cake',
  'boiled-egg',
  'bread-french',
  'bread-loaf',
  'bread-slice',
  'broccoli',
  'butter-spread',
  'cake-slice',
  'candy-cane',
  'candy',
  'carrot',
  'cheese',
  'cheeseburger',
  'cherry',
  'chicken-leg',
  'chilly',
  'chocolate-bar',
  'citrus',
  'cookie',
  'corn',
  'crab',
  'croissant-coffee',
  'cupcake',
  'dining-set2',
  'donut',
  'dumplin',
  'egg-pan',
  'egg-sunny',
  'fast-food',
  'fish-1',
  'fish-2',
  'fish-sushi',
  'flour-powder',
  'food-plate',
  'food-truck',
  'french-fries',
  'gingerbread-man',
  'grape',
  'hamburger',
  'hazelnut',
  'honey-jar-1',
  'honey-jar-2',
  'honey-scoop',
  'hotdog',
  'ice-cream-1',
  'ice-cream-2',
  'ice-cream-3',
  'ice-cream-4',
  'ice-cream-5',
  'ice-cream-trolley',
  'jam-jar',
  'menu',
  'noodle-bowl',
  'noodle-cup',
  'pear',
  'pepper-grinder',
  'pie',
  'pizza',
  'popcorn',
  'pretzel',
  'radish',
  'rice-porridge',
  'salt-bottle',
  'salt-pepper',
  'sardines',
  'sausage-fork',
  'spaghetti',
  'squid',
  'steak',
  'strawberry',
  'sugar-icing',
  'taco-1',
  'taco-2',
  'toast',
  'watermelon',
  'wheat',
  'barrel-ale',
  'beer-glass-1',
  'beer-glass-2',
  'bottle-1',
  'bottle-2',
  'bottle-3',
  'bottle-4',
  'bottle-favorite',
  'can-soda-1',
  'can-soda-2',
  'can-soda-3',
  'cask',
  'cocktail-1',
  'cocktail-2',
  'cocktail-3',
  'cocktail-4',
  'cocktail-olives',
  'cocktail-shaker',
  'coconut-juice',
  'coffee-bean-jar',
  'coffee-cup-1',
  'coffee-cup-2',
  'coffee-cup-3',
  'coffee-mug-1',
  'coffee-mug-2',
  'cooking-oil-bottle',
  'espresso-machine-1',
  'espresso-machine-2',
  'flask',
  'french-press',
  'glass-soda',
  'glass-water',
  'ice-drink-cup',
  'milk-canister',
  'milk-carton',
  'organic-coffee',
  'organic-liquid-glass',
  'organic-liquid',
  'organic-milk',
  'organic-tea',
  'soda-bottle',
  'tea-pot-1',
  'tea-pot-2',
  'water-mug',
  'whiskey',
  'wine-bottle-glass-sparkling',
  'wine-bottle-sparkling',
  'wine-bucket-sparkling',
  'wine-glass-sparkling',
  'wine-glass',
  'wine-sparkling-cheers',
  'wine-vintage',
  'ambulance',
  'blood-drop',
  'bone-broken',
  'cannabis-leaf',
  'condom',
  'crutch',
  'dental-shield',
  'dentis',
  'emergency-patient',
  'first-aid-plaster',
  'heart-beat',
  'helipad-circle',
  'helipad-square',
  'hospital-1',
  'hospital-2',
  'medical-bed',
  'medical-box',
  'medical-cast',
  'medical-certificate',
  'medical-cross-snake',
  'medical-cross',
  'medical-notepad',
  'medical-pills-bottle',
  'medical-pills',
  'medical-scissors',
  'medical-shield',
  'medical-walker',
  'medicine-capsule-1',
  'medicine-capsule-2',
  'medicine',
  'mortar-pestle',
  'nurse-cap',
  'pilse-signal-3',
  'pulse-check',
  'pulse-signal-1',
  'pulse-signal-2',
  'saline',
  'sign-hospital-1',
  'sign-hospital-2',
  'sperm',
  'stethoscope',
  'syringe',
  'tooth-refresh',
  'weight-scale',
  'x-ray',
  'bulletproof-armor',
  'cc-camera-1',
  'cc-camera-2',
  'fire-extinguisher',
  'fire-sprinkler',
  'footprint',
  'gas-mask',
  'handcuffs',
  'police-cap',
  'radar-screen',
  'safety-buoy',
  'sherif-star',
  'shield-fire',
  'shield-skull',
  'shield-viking',
  'sign-wanted',
  'candle',
  'chandeliers',
  'fire-camp',
  'fire-lamp-1',
  'fire-lamp-2',
  'fire-lighter',
  'flame',
  'flashlight',
  'gas-tank',
  'hanging-lamp-1',
  'hanging-lamp-2',
  'lamp-1',
  'lamp-2',
  'lamp-3',
  'lamp-4',
  'lamp-5',
  'lamp-6',
  'lamp-7',
  'lamp-candle',
  'lamp-table',
  'lightbulb-1',
  'lightbulb-2',
  'lightbulb-3',
  'lightbulb-4',
  'matchstick',
  'arrows',
  'back-pack',
  'battery',
  'brick-wall',
  'can',
  'charm-doll',
  'coat-rack',
  'crystal-raw',
  'crytal-shiny',
  'diamond-ring',
  'diamond',
  'drinking-fountain',
  'greeting-card',
  'hand-fan',
  'ladder',
  'lawn-mower',
  'message-bottle',
  'necklace-cross',
  'needle-thread',
  'oil-can',
  'paper-boat',
  'pipe',
  'rope',
  'safety-pin',
  'scale',
  'sewing-machine',
  'shield-1',
  'shield-2',
  'shield-3',
  'shield-4',
  'shield-5',
  'shield-6',
  'shield-7',
  'shield-8',
  'shield-9',
  'sign-barber',
  'sign-caution',
  'sign-fragile',
  'small-air-conditioner',
  'spray-bottle',
  'swiss-army-knife',
  'thread-roll',
  'tissue',
  'toilet-paper',
  'toilet-seat',
  'treasure-chest-close',
  'treasure-chest-open',
  'vacuum-cleaner',
  'washing-machine',
  'water-faucet-1',
  'water-faucet-2',
  'weather-cock',
  'weight',
  'caution-fence',
  'measuring-tape',
  'mining-cart',
  'tools-amperemeter',
  'tools-axe',
  'tools-boox',
  'tools-clamps',
  'tools-double-ratchet',
  'tools-driller',
  'tools-gardening',
  'tools-hammer-1',
  'tools-hammer-2',
  'tools-hammer-anvil',
  'tools-pickaxe',
  'tools-pincher',
  'tools-plunger',
  'tools-screw',
  'tools-shovel',
  'tools-trowel',
  'tools-vice',
  'tools-wheel-brace',
  'tools-wood-saw',
  'tools-wrench-screwdriver',
  'tools-wrench',
  'traffic-cone',
  'armchair-1',
  'armchair-2',
  'bench',
  'chair-1',
  'chair-2',
  'chair-3',
  'chair-4',
  'chair-5',
  'chair-6',
  'chair-office',
  'computer-desk',
  'couch',
  'cupboard-1',
  'cupboard-2',
  'cupboard-3',
  'dining-table',
  'drawer',
  'grill',
  'hanging-chair',
  'lamp-cabinet',
  'lockers',
  'sofa',
  'table',
  'television-stand',
  'toilet-sink',
  'angel',
  'demon',
  'dharma-wheel',
  'egypt-cross',
  'graveyard',
  'hand-eye',
  'pyramid-eye',
  'religion-bible',
  'religion-christianity',
  'religion-hinduism',
  'religion-islam',
  'religion-judaism',
  'religion-shield-1',
  'religion-shield-2',
  'religion-taosim',
  'share-christianity-1',
  'share-christianity-2',
  'share-religion-eye-1',
  'share-religion-eye-2',
  'shrine-gate',
  'sign-peace',
  'ying-yang',
  'bird-cage',
  'bird-house',
  'cat-play-ground',
  'chicken-feet',
  'dog-house',
  'pet-award-1',
  'pet-award-2',
  'pet-award-3',
  'pet-bell',
  'pet-bone',
  'pet-box',
  'pet-brush',
  'pet-cat-litter-tray',
  'pet-cat',
  'pet-collar-1',
  'pet-collar-2',
  'pet-comb',
  'pet-dog-walk',
  'pet-fish-bone',
  'pet-food-1',
  'pet-food-2',
  'pet-goldfish',
  'pet-litter-scoop',
  'pet-love',
  'pet-paw',
  'pet-paw-2',
  'pet-search',
  'pet-shampoo',
  'pet-toy-mouse',
  'pet-trophy',
  'pet-yarn-ball',
  'sign-lost-pet',
  'tick-lice',
  'alien-1',
  'alien-2',
  'astronaut-1',
  'astronaut-2',
  'comet',
  'earth-moon-orbit',
  'eclipse-lunar',
  'eclipse-solar',
  'laser-space-gun',
  'planet-ring-1',
  'planet-ring-2',
  'R2D2',
  'rocket-orbit',
  'rocket',
  'satellite-truck',
  'solar-system',
  'space-rocket',
  'space-shuttle-1',
  'space-shuttle-2',
  'space-shuttle-3',
  'star-constellation',
  'ufo',
  'backhoe-loader',
  'bulldozer-1',
  'bulldozer-2',
  'cement-mixer',
  'cement-trowel',
  'construction-blueprint-1',
  'construction-blueprint-2',
  'construction-blueprint',
  'construction-crane',
  'construction-hook',
  'construction-shovel',
  'construction-wall-1',
  'construction-wall-2',
  'helmet-construction',
  'lifting-crane',
  'rock-drill',
  'truck-cement-mixer',
  'truck-dump',
  'truck',
  'wheelbarrow',
  'wood-saw',
  'wrecking-ball-crane',
  'airballoon',
  'airplane-1',
  'airplane-2',
  'airplane-3',
  'airplane-4',
  'airplane',
  'airship-1',
  'airship-2',
  'battery-jumper-head',
  'bicycle-cruiser',
  'bicycle-mountain',
  'bicycle-road',
  'bicycle-vintage',
  'boat-1',
  'boat-2',
  'boat-3',
  'boat-4',
  'boat-5',
  'boat-sail-1',
  'boat-sail-2',
  'boat-steering-wheel',
  'boat-transport-1',
  'boat-transport-2',
  'bullet-train',
  'bus-1',
  'bus-2',
  'bus-3',
  'bus-4',
  'bus-5',
  'bus-sail-3',
  'cable-car',
  'camper-trailer',
  'canoe',
  'car-1',
  'car-2',
  'car-3',
  'car-4',
  'car-5',
  'car-6',
  'car-7',
  'car-8',
  'car-9',
  'car-10',
  'car-11',
  'car-battery-1',
  'car-battery-2',
  'car-block',
  'car-check-1',
  'car-check-2',
  'car-checklist',
  'car-fire',
  'car-fix-twentyfour-hours',
  'car-fix',
  'car-four-wheel',
  'car-fuel',
  'car-gate',
  'car-key',
  'car-lock',
  'car-music',
  'car-park',
  'car-pickup',
  'car-police',
  'car-protect',
  'car-setting',
  'car-signal',
  'car-sports',
  'car-wash',
  'car-wheel',
  'delivery-motorcycle',
  'fuel-empty-1',
  'fuel-empty-2',
  'fuel-full-1',
  'fuel-full-2',
  'gear-automatic',
  'gearbox',
  'headlight-high',
  'headlight-low',
  'helicopter-1',
  'helicopter-2',
  'helmet-motorcycle',
  'icecream-bicycle',
  'inflator-pump',
  'jet-plane',
  'monocycle',
  'motorbike-1',
  'oil-barrel',
  'oil-derrick',
  'oil-gauge',
  'oil-jerry-can',
  'oil-station',
  'road-circle',
  'road-overpass',
  'road-straight',
  'road-tunnel',
  'sea-buoy',
  'seatbelt-lock',
  'segway',
  'sign-u-turn',
  'sign-zigzag',
  'signal-warning',
  'speed-gauge',
  'steering-wheel',
  'submarine',
  'taxi-1',
  'taxi-2',
  'traffic-light-1',
  'traffic-light-2',
  'train-1',
  'train-2',
  'train-3',
  'train-tunnel',
  'tram',
  'tricycle',
  'truck-1',
  'truck-2',
  'truck-3',
  'truck-ambulance',
  'truck-emergency',
  'truck-fire-1',
  'truck-fire-2',
  'truck-tow',
  'tuktuk',
  'van-camper',
  'van',
  'vespa-2',
  'vespa',
  'wagon',
  'windshield',
  'ball',
  'american-football',
  'ball-badminton',
  'ball-basketball',
  'ball-football',
  'ball-golf',
  'ball-snooker-pool',
  'ball-soccer',
  'ball-volleyball',
  'baseball',
  'basketball-hoop',
  'bowling-pins',
  'bowling',
  'boxing-glove',
  'boxing-mask',
  'canoe2',
  'cycling-time',
  'dumbbell-1',
  'dumbbell-2',
  'fencing-sword',
  'fishing-bait',
  'fishing-rod',
  'fishing',
  'hand-grip',
  'hand-squeezer',
  'helmet-baseball',
  'helmet-bicycle',
  'helmet-football',
  'hockey',
  'iceskate',
  'janga',
  'jetski',
  'kendo-mask',
  'martial-art-robe',
  'muscle',
  'othello',
  'oxygen-tank',
  'pingpong',
  'power-drink',
  'protein-powder',
  'raft-boat',
  'rollerblade',
  'skateboard',
  'ski',
  'soccer-court',
  'soccer-goal',
  'stationary-bicycle',
  'tennis-court',
  'tennis',
  'tic-tac-toe2',
  'weight-lifting',
  'animal-hands',
  'animal-shield',
  'axe-wood-stump',
  'barn',
  'butterfly',
  'cactus',
  'carbon-dioxide',
  'clover',
  'cycling-energy',
  'dam',
  'dolphin',
  'eclipse',
  'eco-car-1',
  'eco-car-2',
  'eco-car-charge',
  'eco-energy-1',
  'eco-energy-2',
  'eco-factory',
  'eco-fuel-barrel',
  'eco-fuel',
  'eco-globe-1',
  'eco-globe-2',
  'eco-house',
  'eco-lightbulb',
  'eco-mind',
  'eco-nature',
  'eco-nuclear-plant',
  'eco-paper',
  'eco-tag',
  'eco-view',
  'field-sun',
  'fire',
  'fish-dead',
  'flower-1',
  'flower-2',
  'flower-3',
  'flower-4',
  'flower-5',
  'flower-6',
  'flower-7',
  'forest-1',
  'forest-2',
  'forest-fire',
  'hand-seed',
  'honeycomb',
  'hose-nozzle',
  'jellyfish',
  'leaf',
  'map-mountain-forest',
  'night-sky',
  'no-flame',
  'oxygen',
  'plant-tree',
  'plant',
  'radioactive-bin',
  'radioactive-truck',
  'recharge-energy',
  'recycle-bag',
  'recycle-bin',
  'recycle-hand',
  'scarecrow',
  'sign-recycle',
  'sign-reuse',
  'solar-panel',
  'sun-ocean',
  'target-skull',
  'tool-gardening',
  'tree-big-1',
  'tree-big-2',
  'tree-big-3',
  'tree-big-4',
  'tree-big-5',
  'tree-house',
  'tree-small-1',
  'tree-small-2',
  'volcanoe',
  'wall-plant',
  'water-drop',
  'water-faucet',
  'water-reuse',
  'water-save',
  'water-tower',
  'watering-can',
  'wind-fan',
  'wind-turbine',
  'windmill-paper',
  'wood-log',
  'wood-stump-1',
  'wood-stump-2',
  'beard',
  'comb',
  'cream',
  'flower-essence',
  'flower-hand',
  'lipstick',
  'make-up-brush',
  'make-up-powder-1',
  'make-up-powder-2',
  'mascara',
  'mirror-small',
  'mustache-1',
  'mustache-2',
  'nail-polish',
  'perfume',
  'shaver',
  'sign-spa-1',
  'sign-spa-2',
  'spa-aroma-candle',
  'spa-aroma',
  'spa-bamboo',
  'spa-facial',
  'spa-flower-lotus',
  'spa-lotion',
  'spa-rock',
  'spa-towel',
  'spa-vapor',
  'sunscreen-lotion',
  'toothbrush',
  'water-hand',
  'air-conditioner',
  'air-heater',
  'bath-tub',
  'bed-double',
  'bed-single',
  'building-hotel',
  'car-key2',
  'cloth-hanger-towel',
  'door-sign',
  'electric-fan',
  'fire-alarm',
  'fireplace',
  'fitness-center',
  'five-stars',
  'food-dome-serving-1',
  'food-dome-serving-2',
  'food-trolley',
  'hair-dryer',
  'hotel-five-stars',
  'luggage-carrier',
  'luggage-key-1',
  'luggage-key-2',
  'mirror',
  'pet-allow',
  'pet-not-allow',
  'room-sauna',
  'shower',
  'spa-robe',
  'swimming-pool',
  'television-no-signal',
  'television',
  'toilet',
  'disable-sign',
  'elevator-1',
  'elevator-2',
  'escalator-down',
  'escalator-up',
  'garage',
  'no-food-allow-1',
  'no-food-allow-2',
  'no-picture-allow',
  'reception-table-1',
  'reception-table-2',
  'shower-1',
  'shower-2',
  'sign-toilet-1',
  'sign-toilet-2',
  'smoke-free-area',
  'smoking-area',
  'staircase-down-1',
  'staircase-down-2',
  'staircase-up-1',
  'staircase-up-2',
  'swimming-pool2',
  'ticket-gate',
  'airplane-12',
  'airplane-22',
  'airplane-arrival-cancel',
  'airplane-arrival-check',
  'airplane-arrival',
  'airplane-departure-cancel',
  'airplane-departure-check',
  'airplane-departure',
  'airplane-flight',
  'airplane-square',
  'airport-watchtower',
  'beach',
  'boat',
  'flippers',
  'island',
  'luggage-weight-scale',
  'metal-detector-gate',
  'passport-1',
  'passport-2',
  'plane-boarding-pass',
  'radar-tower',
  'scuba-diving',
  'sign-no-entry',
  'snorkel-mask',
  'stair-truck',
  'sun-bath',
  'tent2',
  'travel-bag-1',
  'travel-bag-2',
  'world-flight',
  'house-add',
  'house-block',
  'house-book',
  'house-favorite-heart',
  'house-favorite-star',
  'house-search-1',
  'house-search-2',
  'house-setting',
  'house-sold',
  'house-subtract',
  'house-warning',
  'sign-for-rent-2',
  'sign-for-sale-2',
  'sign-for-sale-3',
  'sign-sold-1',
  'sign-sold-2',
  'house-heart',
  'anchor',
  'bank-1',
  'bank-2',
  'barn-silo',
  'bridge-1',
  'bridge-2',
  'bridge-3',
  'bridge-4',
  'buddha-statue',
  'building-1',
  'building-2',
  'building-3',
  'building-4',
  'building-5',
  'building-6',
  'building-7',
  'building-8',
  'building-9',
  'building-10',
  'building-11',
  'building-12',
  'building-13',
  'building-14',
  'building-15',
  'building-16',
  'building-17',
  'building-18',
  'building-19',
  'building-20',
  'building-pisa-tower',
  'building-tower',
  'building-twin-tower',
  'castle-1',
  'castle-2',
  'castle-japanese',
  'church',
  'clock-tower',
  'colosseum',
  'direction-sign',
  'door-simple',
  'eiffel-tower',
  'electric-pole',
  'factory',
  'garage2',
  'gate-of-triumph',
  'globe-2',
  'globe-3',
  'glove-all-direction',
  'greek-column',
  'home-1',
  'home-2',
  'home-3',
  'home-4',
  'home-5',
  'home-6',
  'home-fire-1',
  'home-fire-2',
  'home-hurricane',
  'igloo',
  'jesus-statue',
  'light-house-1',
  'lighthouse-2',
  'map-treasure',
  'mosque',
  'mount-fuji',
  'mountains',
  'pagoda',
  'park',
  'pyramid',
  'shrine-gate-1',
  'shrine-gate-2',
  'shrine-japanese',
  'statue-of-liberty',
  'stone-heads',
  'stonehendge',
  'taj-mahal',
  'temple',
  'tent3',
  'totem-pole',
  'transmission-tower-1',
  'transmission-tower-2',
  'volcano',
  'washington-monument',
  'water-fountain',
  'water-tower2',
  'water-well',
  'wooden-door',
  'wooden-fence',
  'ziggurat',
  'city-day',
  'city-night',
  'compass-1',
  'compass-2',
  'compass-3',
  'compass-east',
  'compass-north',
  'compass-south',
  'compass-west',
  'globe-1',
  'globe-22',
  'globe-add',
  'globe-block',
  'globe-cash',
  'globe-check',
  'globe-download',
  'globe-edit',
  'globe-favorite-heart',
  'globe-favorite-star',
  'globe-lock',
  'globe-pin',
  'globe-question',
  'globe-refresh',
  'globe-remove',
  'globe-search',
  'globe-setting',
  'globe-share',
  'globe-subtract',
  'globe-sync',
  'globe-timeout',
  'globe-upload',
  'globe-warning',
  'location-barn',
  'location-bridge',
  'location-computer',
  'location-map',
  'location-oil-rig',
  'location-pin-1',
  'location-pin-2',
  'location-pin-3',
  'location-pin-4',
  'location-pin-5',
  'location-pin-6',
  'location-pin-7',
  'location-pin-8',
  'location-pin-9',
  'location-pin-10',
  'location-pin-11',
  'location-pin-12',
  'location-pin-13',
  'location-pin-14',
  'location-pin-15',
  'location-pin-add-1',
  'location-pin-add-2',
  'location-pin-add-3',
  'location-pin-add-4',
  'location-pin-add-5',
  'location-pin-airport-1',
  'location-pin-airport-2',
  'location-pin-bank-1',
  'location-pin-bank-2',
  'location-pin-bar-1',
  'location-pin-bar-2',
  'location-pin-block',
  'location-pin-camera-1',
  'location-pin-camera-2',
  'location-pin-check-1',
  'location-pin-check-2',
  'location-pin-church-1',
  'location-pin-church-2',
  'location-pin-dining-1',
  'location-pin-direction-1',
  'location-pin-direction-2',
  'location-pin-direction-3',
  'location-pin-exclamation-1',
  'location-pin-exclamation-2',
  'location-pin-favorite-1',
  'location-pin-favorite-2',
  'location-pin-favorite-3',
  'location-pin-favorite-4',
  'location-pin-flash-1',
  'location-pin-flash-2',
  'location-pin-garage-1',
  'location-pin-garage-2',
  'location-pin-gas-station-1',
  'location-pin-gas-station-2',
  'location-pin-globe-1',
  'location-pin-globe-2',
  'location-pin-group',
  'location-pin-gym-1',
  'location-pin-gym-2',
  'location-pin-home-1',
  'location-pin-home-2',
  'location-pin-hospital-1',
  'location-pin-hospital-2',
  'location-pin-information-1',
  'location-pin-information-2',
  'location-pin-library-1',
  'location-pin-library-2',
  'location-pin-medium',
  'location-pin-refresh',
  'location-pin-remove-1',
  'location-pin-remove-2',
  'location-pin-remove-3',
  'location-pin-remove-4',
  'location-pin-restaurant-2',
  'location-pin-shopping-1',
  'location-pin-shopping-2',
  'location-pin-skull-1',
  'location-pin-skull-2',
  'location-pin-subtract-1',
  'location-pin-subtract-2',
  'location-pin-subtract-3',
  'location-pin-subtract-4',
  'location-pin-subtract-5',
  'location-pin-subway-1',
  'location-pin-subway-2',
  'location-pin-target-1',
  'location-pin-target-2',
  'location-pin-unknown-1',
  'location-pin-unknown-2',
  'location-pin-user-1',
  'location-pin-user-2',
  'location-pin-wifi-1',
  'location-pin-wifi-2',
  'location-pins',
  'location-user',
  'map-1',
  'map-direction',
  'map-pin-1',
  'map-pin-2',
  'map-search',
  'map-target-1',
  'map-target-2',
  'map-target-none',
  'road-pin',
  'share-hand-location',
  'align-center',
  'align-justify',
  'align-landscape',
  'align-left',
  'align-portrait',
  'align-right',
  'border-all-1',
  'border-all-2',
  'border-bottom',
  'border-inside-horizontal',
  'border-inside',
  'border-left',
  'border-none',
  'border-outside',
  'border-right',
  'border-top',
  'indent-decrease-1',
  'indent-decrease-2',
  'indent-increase-1',
  'indent-increase-2',
  'indent-left',
  'indent-right',
  'layout-1',
  'layout-2',
  'layout-3',
  'layout-4',
  'layout-5',
  'layout-6',
  'layout-7',
  'layout-8',
  'layout-9',
  'layout-10',
  'layout-11',
  'layout-12',
  'layout-none',
  'line-spacing',
  'paragraph-spacing',
  'arrow-bottom-left',
  'arrow-bottom-right',
  'arrow-down',
  'arrow-enter',
  'arrow-left',
  'arrow-next',
  'arrow-previous',
  'arrow-return',
  'arrow-right',
  'arrow-shift',
  'arrow-top-left',
  'arrow-top-right',
  'arrow-up',
  'asterisk-1',
  'asterisk-2',
  'button-alt',
  'button-backspace',
  'button-delete',
  'button-direction',
  'button-eject',
  'button-empty',
  'button-enter',
  'button-escape',
  'button-option',
  'button-shift',
  'command',
  'page-down',
  'page-up',
  'all-caps',
  'angle-brackets',
  'arrange-letter',
  'arrange-number',
  'bold',
  'copy-paste',
  'eraser',
  'font-color',
  'font-size',
  'format-clear',
  'hash',
  'italic',
  'list-add',
  'list-bullets-1',
  'list-bullets-2',
  'list-bullets-3',
  'list-number',
  'list-subtract',
  'paragraph-left-to-right',
  'paragraph-normal',
  'paragraph-right-to-left',
  'paragraph-two-columns',
  'paragraph',
  'printer-view',
  'quote-1',
  'quote-2',
  'redo',
  'scale-horizontal',
  'scale-vertical',
  'sigma',
  'small-caps',
  'spelling-check-1',
  'spelling-check-2',
  'strikethrough-1',
  'strikethrough-2',
  'subscript',
  'superscript',
  'text-bar',
  'text-input-1',
  'text-input-2',
  'text-input-area',
  'text-vector',
  'text',
  'translate',
  'underline',
  'undo',
  'wrap-text-around',
  'wrap-text-side',
  'wrap-text',
  'bluetooth-connecting',
  'bluetooth-signal',
  'network-add',
  'network-block',
  'network-download',
  'network-favorite-heart',
  'network-favorite-star',
  'network-forward',
  'network-information',
  'network-live',
  'network-location',
  'network-lock',
  'network-mobile-phone',
  'network-notebook-share',
  'network-protect',
  'network-question',
  'network-refresh',
  'network-remove',
  'network-search',
  'network-setting',
  'network-share',
  'network-subtract',
  'network-sync',
  'network-timeout',
  'network-upload',
  'network-warning',
  'network-web',
  'network-world',
  'wifi-add',
  'wifi-block',
  'wifi-cash',
  'wifi-check',
  'wifi-coffee',
  'wifi-download',
  'wifi-favorite-heart',
  'wifi-favorite-star',
  'wifi-favorite',
  'wifi-lock',
  'wifi-question',
  'wifi-refresh',
  'wifi-remove',
  'wifi-search',
  'wifi-setting',
  'wifi-subtract',
  'wifi-timeout',
  'wifi-upload',
  'wifi-warning',
  'window-1',
  'window-2',
  'window-add',
  'window-application-1',
  'window-application-2',
  'window-application-3',
  'window-application-4',
  'window-application-5',
  'window-application-6',
  'window-application-8',
  'window-bookmark',
  'window-code-1',
  'window-code-2',
  'window-code-3',
  'window-download-1',
  'window-link',
  'window-list-1',
  'window-list-2',
  'window-list-user',
  'window-lock-1',
  'window-lock-2',
  'window-maximize',
  'window-media-player',
  'window-medium',
  'window-menu',
  'window-minimize',
  'window-module',
  'window-number',
  'window-password',
  'window-pie-graph',
  'window-pulse-1',
  'window-pulse-2',
  'window-warning',
  'window-website',
  'android-download',
  'android-refresh',
  'android-setting',
  'android-upload',
  'android',
  'compatible-devices-1',
  'compatible-devices-2',
  'compatible-screen-1',
  'compatible-screen-2',
  'compatible-screen-3',
  'computer-bar-graph',
  'computer-bug-1',
  'computer-bug-2',
  'computer-line-graph',
  'computer-media',
  'computer-pie-graph',
  'computer-protection',
  'computer-share',
  'css-three',
  'cursor-devices',
  'firewall',
  'flowchart-edit',
  'flowchart',
  'hand-devices',
  'html-five',
  'jigsaw',
  'login-arrow',
  'login-check',
  'login-wrong',
  'modules-1',
  'modules-2',
  'programming-binary',
  'programming-css',
  'programming-html',
  'programming-website',
  'protection-check',
  'protection-setting-1',
  'protection-setting-2',
  'reel',
  'script',
  'touchscreen',
  'typing',
  'video-editing',
  'windows-expand',
  'bitcoin-cloud',
  'bitcoin-notebook',
  'bitcoin-window',
  'bitcoin',
  'dollar-sign-badge',
  'dollar-sign-circle',
  'dollar-sign-ticket',
  'dollar-sign',
  'euro-decrease',
  'euro-increase',
  'euro-network',
  'euro-sign-badge',
  'euro-sign-circle',
  'euro-sign-ticket',
  'euro-sign',
  'exchange-bitcoin',
  'exchange-chip',
  'bank-notes-1',
  'bank-notes-2',
  'bank-notes-3',
  'bank-notes-atm',
  'cheque',
  'coin-bank-note',
  'coin-receive',
  'coins-1',
  'coins-2',
  'coins-3',
  'credit-card-amex',
  'credit-card-hand',
  'credit-card-lock',
  'credit-card-master-card',
  'credit-card-visa',
  'credit-card',
  'money-bag-euro',
  'piggy-bank',
  'purse-1',
  'purse-2',
  'wallet-1',
  'wallet-2',
  'wallet-3',
  'box-remove',
  'box-return',
  'box-storehouse',
  'box-subtract',
  'box-time',
  'box-truck-delievery',
  'box-unknown',
  'box-upload',
  'box-view-setting',
  'box-view',
  'box-warning',
  'crate-trolley-1',
  'crate-trolley-2',
  'crate',
  'delivery-man',
  'forklift',
  'fragile-1',
  'fragile-2',
  'shopping-cart-bag',
  'shopping-cart-box',
  'this-way-up-sign',
  'truck-12',
  'truck-22',
  'truck-delivery-time',
  'abacus',
  'auction',
  'bank-12',
  'bank-22',
  'business-bag-cash',
  'business-maze',
  'business-scale',
  'business-trade',
  'calculator-1',
  'calculator-2',
  'calculator-3',
  'circle-bar-graph',
  'circle-cash',
  'circle-line-graph',
  'circle-people',
  'circle-percent',
  'circle-pie-graph',
  'circle-speech-bubble',
  'circle-view',
  'credit-card-block',
  'credit-card-check',
  'credit-card-refresh',
  'credit-card-remove',
  'credit-card2',
  'find-job',
  'gold-nugget',
  'golden-egg',
  'graph-bar-1',
  'graph-bar-2',
  'graph-bar-3',
  'graph-bar-3d',
  'graph-bar-decrease',
  'graph-bar-increase',
  'graph-bar-line',
  'graph-bar-movement',
  'graph-line-1',
  'graph-line-2',
  'graph-line-3',
  'graph-line-4',
  'graph-line-5',
  'graph-line-6',
  'graph-pie-1',
  'graph-pie-2',
  'hand-diamond',
  'hot-cash-note',
  'network-cash',
  'network-people',
  'network-unknown',
  'parking-booth',
  'percent-1',
  'percent-2',
  'percent-badge',
  'percent-box',
  'percent-circle',
  'percent-increase',
  'presentation-bar-graph-1',
  'presentation-bar-graph-2',
  'presentation-code',
  'presentation-hierarchy',
  'presentation-location',
  'presentation-media',
  'presentation-picture',
  'presentation-pie-graph',
  'presentation-setting',
  'presentation-shopping-cart',
  'presentation-speech',
  'presentation-timeout',
  'price-down',
  'price-rise',
  'projector-screen',
  'receipt',
  'safe-1',
  'safe-2',
  'scale-1',
  'scale-2',
  'speech-stand',
  'stock-bear-decreasing',
  'stock-bear-increasing',
  'stock-bear',
  'stock-bull-decreasing',
  'stock-bull-increasing',
  'stock-bull',
  'target-cash',
  'view-cash-1',
  'view-cash-2',
  'barcode-scan',
  'barcode-sticker',
  'basket-1',
  'basket-2',
  'basket-3',
  'basket-add',
  'basket-block',
  'basket-check',
  'basket-checkout',
  'basket-edit',
  'basket-favorite-heart',
  'basket-favorite-star',
  'basket-refresh',
  'basket-remove',
  'basket-subtract',
  'basket-view',
  'coin-point',
  'coin-star',
  'coupon-discount',
  'cut-here',
  'digital-scale',
  'frame-stand',
  'free-sticker-circle',
  'free-sticker-square',
  'hand-bag-1',
  'hand-bag-2',
  'hand-bag-3',
  'new-sticker-circle',
  'new-sticker-square',
  'online-payment',
  'price-tag',
  'qr-code',
  'receipt-1',
  'receipt-2',
  'receipt-3',
  'receipt-4',
  'register-machine',
  'shopping-bag-1',
  'shopping-bag-2',
  'shopping-bag-3',
  'shopping-bag-4',
  'shopping-bag-add',
  'shopping-bag-barcode',
  'shopping-bag-check',
  'shopping-bag-duty-free',
  'shopping-bag-favorite',
  'shopping-bag-media',
  'shopping-cart-1',
  'shopping-cart-2',
  'shopping-cart-3',
  'shopping-cart-4',
  'shopping-cart-add-2',
  'shopping-cart-block-2',
  'shopping-cart-check-2',
  'shopping-cart-checkout-2',
  'shopping-cart-down-2',
  'shopping-cart-down',
  'shopping-cart-favorite-heart-2',
  'shopping-cart-favorite-star-2',
  'shopping-cart-full',
  'shopping-cart-remove-2',
  'shopping-cart-subtract-2',
  'shopping-cart-up-2',
  'shopping-cart-up',
  'shopping-cart-view-2',
  'sign',
  'store-close',
  'store-open',
  'store-sale',
  'store',
  'smiley-angry-1',
  'smiley-angry-2',
  'smiley-angry-3',
  'smiley-confused',
  'smiley-dizzy',
  'smiley-evil-smile',
  'smiley-frown-1',
  'smiley-frown-2',
  'smiley-frown-3',
  'smiley-love',
  'smiley-money',
  'smiley-poker-face',
  'smiley-sad-1',
  'smiley-sad-2',
  'smiley-shy-1',
  'smiley-shy-2',
  'smiley-sigh',
  'smiley-silence-1',
  'smiley-silence-2',
  'smiley-smile-1',
  'smiley-smile-2',
  'smiley-smile-3',
  'smiley-smile-4',
  'smiley-smile-5',
  'smiley-smile-6',
  'smiley-smile-7',
  'smiley-smile-8',
  'smiley-smile-9',
  'smiley-smirk-1',
  'smiley-smirk-2',
  'smiley-smirk-3',
  'smiley-smirk-4',
  'smiley-smirk-5',
  'smiley-surprise',
  'smiley-thinking',
  'smiley-wink',
  'smiley-woops',
  'smiley-worry',
  'smiley-yawn',
  'audio-jack',
  'cassette-player-1',
  'cassette-player-2',
  'cassette-recorder',
  'cassette-roll',
  'cassette-tape',
  'cd-box',
  'cd-player',
  'cd-touch',
  'cd',
  'clarinet',
  'djembe-drum',
  'drum-1',
  'drum-2',
  'drum-3',
  'ear-listening',
  'eject-button',
  'equaliser-1',
  'equaliser-2',
  'equaliser-3',
  'fast-forward-button',
  'gramophone',
  'grandpiano',
  'guitar',
  'harp',
  'headphone-1',
  'headphone-2',
  'headphone-pulse',
  'headphone-wifi',
  'iphone-speaker',
  'ipod-1',
  'ipod-2',
  'maracas',
  'marimba',
  'microhpone-1',
  'microphone-2',
  'microphone-3',
  'microphone-4',
  'microphone-off',
  'music-cd-1',
  'music-cd-2',
  'music-note-1',
  'music-note-2',
  'music-note-3',
  'music-shopping-basket',
  'next-button',
  'pause-button',
  'piano',
  'play-button-1',
  'play-button-2',
  'playlist-1',
  'playlist-2',
  'playlist-3',
  'previous-button',
  'radio-1',
  'radio-2',
  'radio-3',
  'radio-4',
  'radio-5',
  'radio-on-air',
  'record-button',
  'rewind-button',
  'saxophone',
  'singing-vocal',
  'smart-phone-playing',
  'sound-equalizer',
  'speakers-1',
  'speakers-2',
  'speakers-3',
  'speakers-4',
  'speakers-5',
  'stop-button',
  'trumpet',
  'turntable',
  'violin',
  'volume-down-1',
  'volume-down-2',
  'volume-low-1',
  'volume-low-2',
  'volume-max-1',
  'volume-max-2',
  'volume-medium-1',
  'volume-medium-2',
  'volume-off-1',
  'volume-off-2',
  'volume-off-3',
  'volume-up-1',
  'volume-up-2',
  'xylophone',
  'd-glasses',
  'd-screen',
  'd-sync',
  'camera-cc',
  'camera-fast-forward',
  'camera-next',
  'camera-off',
  'camera-pause',
  'camera-play',
  'camera-previous',
  'camera-record',
  'camera-rewind',
  'camera-stop',
  'clapboard-fast-forward',
  'clapboard-next',
  'clapboard-pause',
  'clapboard-play',
  'clapboard-previous',
  'clapboard-record',
  'clapboard-rewind',
  'clapboard-stop',
  'control-eject',
  'control-fast-forward',
  'control-next',
  'control-pause',
  'control-play',
  'control-previous',
  'control-record',
  'control-rewind',
  'control-stop',
  'disk-player',
  'film',
  'handheld-recorder',
  'handycam',
  'movie-clapboard',
  'movie-film',
  'play-button',
  'screen-4k',
  'screen-8k',
  'screen-hd',
  'screen-uhd',
  'television-1',
  'television-2',
  'television-3',
  'television-truck',
  'video-camera-1',
  'video-camera-2',
  'video-camera-3',
  'video-clip-1',
  'video-clip-2',
  'video-clip-3',
  'video-dvd',
  'video-player',
  'adjust-brightness',
  'back-camera',
  'camera-1',
  'camera-2',
  'camera-3',
  'camera-4',
  'camera-5',
  'camera-box',
  'camera-brightness-auto',
  'camera-brightness',
  'camera-circle',
  'camera-daylight-1',
  'camera-daylight-2',
  'camera-e',
  'camera-film-1',
  'camera-film-2',
  'camera-flash-1',
  'camera-flash-auto',
  'camera-flash-off',
  'camera-flash-on',
  'camera-flash',
  'camera-lens-1',
  'camera-lens-2',
  'camera-live-view-off',
  'camera-live-view-on',
  'camera-night-mode',
  'camera-polaroid',
  'camera-screen',
  'camera-shade',
  'camera-sync-1',
  'camera-sync-2',
  'camera-top-view',
  'camera-tripod',
  'camera-user',
  'camera-vintage-1',
  'camera-vintage-2',
  'crop',
  'exposure-level-add-subtract',
  'exposure-level',
  'flash-on',
  'flash-tripod',
  'focus-12',
  'focus-22',
  'focus-3',
  'focus-auto',
  'focus-face',
  'focus-manual',
  'focus-off',
  'front-camera',
  'hdr-off',
  'hdr-on',
  'image-navigator',
  'image-resolution-1',
  'image-resolution-2',
  'image-stabilizer',
  'landscape-mode',
  'macro-mode',
  'night-mode',
  'panorama-horizontal',
  'panorama-vertical',
  'panorama-wide-angle',
  'picasso',
  'picture-1',
  'picture-2',
  'picture-3',
  'picture-frame-1',
  'picture-frame-2',
  'picture-landscape',
  'picture-layer-1',
  'picture-layer-2',
  'picture-macro',
  'picture-people',
  'picture-portrait',
  'picture-view-all',
  'portrait-mode-1',
  'timer-3sec',
  'timer-10sec',
  'timer-mode',
  'timer-no',
  'adhesive-tape',
  'book-shelf',
  'check-list',
  'document-shredder',
  'glue',
  'hole-puncher',
  'paper-binder',
  'paper-cutter',
  'paper-pin',
  'photocopy-machine',
  'podium',
  'projector-front',
  'projector',
  'stapler',
  'sticky-note',
  'crop-1',
  'crop-2',
  'expand-12',
  'expand-22',
  'expand-32',
  'expand-42',
  'flip-down',
  'flip-up',
  'intersect',
  'minus-front',
  'move-down',
  'move-to-back',
  'move-to-front',
  'move-up',
  'new-circle',
  'new-square',
  'reflect-copy-left',
  'reflect-copy-right',
  'reflect-left',
  'reflect-right',
  'rotate-left',
  'rotate-right',
  'shear-left',
  'shear-right',
  'shrink-12',
  'shrink-2',
  'unite',
  'angle-tool',
  'art-board',
  'art-knife',
  'blur-linear',
  'bring-to-front',
  'circle',
  'color-bucket',
  'color-contrast-off',
  'color-contrast-on',
  'color-jar',
  'color-palette',
  'color-tube',
  'compasses',
  'computer-graphic',
  'cone',
  'crayons',
  'crop-picture',
  'cube',
  'design-mug',
  'eraser2',
  'eye-dropper-1',
  'eye-dropper-2',
  'eye-dropper-3',
  'gradient',
  'grid',
  'highlight',
  'hot-blue',
  'layers',
  'magic-wand-1',
  'magic-wand-2',
  'magnet-tool',
  'measuring-tool',
  'origami-paper-bird',
  'paint-brush-1',
  'paint-brush-2',
  'paint-color-tube',
  'paint-equipment',
  'paint-palette',
  'paint-roll',
  'painting-canvas',
  'pen-tablet',
  'pen-tool-1',
  'pen-tool-2',
  'pencil-ruler',
  'polygon',
  'prism',
  'pyramid2',
  'quill',
  'rgb-color',
  'ruler-1',
  'ruler-2',
  'ruler-3',
  'select-area',
  'send-to-back',
  'spray-paint',
  'tube',
  'vector-app',
  'vector-circle',
  'vector-handle',
  'vector-line-curve',
  'vector-line',
  'vector-pen',
  'vector-resize',
  'vector-square-1',
  'vector-square-2',
  'vector-triangle',
  'view-add',
  'view-lock',
  'view-subtract',
  'window-design',
  'file-arborescence',
  'flow-chart-1',
  'flow-chart-2',
  'flow-chart-3',
  'graph',
  'hierarchy-1',
  'hierarchy-2',
  'hierarchy-3',
  'hierarchy-4',
  'bookmark-1',
  'bookmark-2',
  'bookmark-3',
  'bookmark-4',
  'bookmark-5',
  'bookmark-add',
  'bookmark-all-1',
  'bookmark-all-2',
  'bookmark-article',
  'bookmark-block',
  'bookmark-cash',
  'bookmark-check',
  'bookmark-download',
  'bookmark-edit',
  'bookmark-favorite-heart',
  'bookmark-favorite-star',
  'bookmark-lock',
  'bookmark-refresh',
  'bookmark-remove',
  'bookmark-setting',
  'bookmark-share',
  'bookmark-subtract',
  'bookmark-upload',
  'bookmark-view',
  'bookmark-warning',
  'arrow-curve',
  'button-power-1',
  'button-power-2',
  'button-power-3',
  'crop-area',
  'cross-over2',
  'loop-back',
  'loop-infinite-1',
  'loop-infinite-2',
  'loop-infinite-3',
  'refresh-one',
  'split',
  'zigzag-1',
  'zigzag-3',
  'zigziag-2',
  'add-1',
  'add-2',
  'add-box',
  'add-circle-1',
  'add-circle-2',
  'add-circle-double',
  'add-tab',
  'remove-1',
  'remove-2',
  'remove-box',
  'remove-circle-1',
  'remove-circle-2',
  'remove-tab',
  'subtract-1',
  'subtract-2',
  'subtract-box',
  'subtract-circle-1',
  'subtract-circle-2',
  'subtract-tab',
  'server-1',
  'server-2',
  'server-3',
  'server-add-1',
  'server-add-2',
  'server-block-1',
  'server-block-2',
  'server-bug-1',
  'server-bug',
  'server-cash-1',
  'server-cash-2',
  'server-check-1',
  'server-check-2',
  'server-download-1',
  'server-download-2',
  'server-edit-1',
  'server-edit-2',
  'server-error',
  'server-favorite-heart-1',
  'server-favorite-heart-2',
  'server-favorite-star-1',
  'server-favorite-star-2',
  'server-flash',
  'server-key',
  'server-lock-1',
  'server-lock-2',
  'server-network-1',
  'server-network-2',
  'server-network-3',
  'server-network-4',
  'server-network-5',
  'server-network-6',
  'server-network-7',
  'server-network-add',
  'server-network-alert',
  'server-network-check',
  'server-network-close',
  'server-network-computer-1',
  'server-network-computer-2',
  'server-network-computer-3',
  'server-network-download',
  'server-network-edit',
  'server-network-favorite-star',
  'server-network-information',
  'server-network-lock',
  'server-network-mail',
  'server-network-mobile-phone',
  'server-network-network',
  'server-network-question',
  'server-network-refresh',
  'server-network-setting',
  'server-network-share',
  'server-network-subtract',
  'server-network-sync',
  'server-network-timeout',
  'server-network-upload',
  'server-network-view',
  'server-question-1',
  'server-question-2',
  'server-refresh-1',
  'server-refresh-2',
  'server-remove-1',
  'server-remove-2',
  'server-setting-1',
  'server-setting-2',
  'server-share-1',
  'server-share-2',
  'server-share-3',
  'server-share-4',
  'server-subtract-1',
  'server-subtract-2',
  'server-sync-1',
  'server-sync-2',
  'server-timeout-1',
  'server-timeout-2',
  'server-upload-1',
  'server-upload-2',
  'server-view-1',
  'server-view-2',
  'server-warning-1',
  'server-warning-2',
  'synchronize-1',
  'synchronize-2',
  'synchronize-3',
  'synchronize-4',
  'synchronize-5',
  'synchronize-6',
  'synchronize-7',
  'synchronize-8',
  'synchronize-9',
  'synchronize-lock',
  'synchronize-time',
  'synchronize-triangle',
  'synchronize-view',
  'synchronize-warning-1',
  'synchronize-warning-2',
  'cloud-add',
  'cloud-audio',
  'cloud-block',
  'cloud-cash',
  'cloud-check',
  'cloud-disable',
  'cloud-download-computer',
  'cloud-download',
  'cloud-favorite',
  'cloud-file',
  'cloud-flash',
  'cloud-folder',
  'cloud-lock',
  'cloud-mobile-download',
  'cloud-mobile-upload',
  'cloud-photo',
  'cloud-picture',
  'cloud-protect',
  'cloud-question',
  'cloud-refresh',
  'cloud-remove',
  'cloud-search',
  'cloud-server-1',
  'cloud-server-2',
  'cloud-setting',
  'cloud-signal',
  'cloud-subtract',
  'cloud-sync-1',
  'cloud-sync-2',
  'cloud-sync-phone',
  'cloud-text',
  'cloud-time',
  'cloud-transfer-finish',
  'cloud-transfer-half',
  'cloud-transfer-start',
  'cloud-transfer',
  'cloud-upload-computer',
  'cloud-upload-home',
  'cloud-upload',
  'cloud-warning',
  'cloud',
  'bluetooth',
  'checker-flag-1',
  'checker-flag-2',
  'download-1',
  'download-2',
  'download-3',
  'download-4',
  'download-5',
  'download-6',
  'download-7',
  'download-box-1',
  'download-box-2',
  'download-box-3',
  'download-box-4',
  'download-box-5',
  'download-circle',
  'download-computer',
  'download-harddrive-1',
  'download-harddrive-2',
  'download-notebook',
  'ethernet-cable',
  'firewire-1',
  'firewire-2',
  'network-business',
  'network-cable-1',
  'network-cable-2',
  'network-computer-lock',
  'network-computer',
  'network-computers-1',
  'network-computers-2',
  'network-connecting',
  'network-laptop',
  'network-signal',
  'network-user',
  'network',
  'notebook-lock-network',
  'screen-wireless',
  'sign-upload-download',
  'syncing',
  'transfer-1',
  'transfer-2',
  'transfer-3',
  'transfer-4',
  'transfer-arrows',
  'transfer-box-1',
  'transfer-box-2',
  'transfer-box-3',
  'transfer-camera-notebook',
  'transfer-circle',
  'transfer-computer-satellite',
  'transfer-computers-1',
  'transfer-computers-2',
  'transfer-file',
  'transfer-folder',
  'transfer-ftp',
  'transfer-phone-computer',
  'transfer-phone-satellite',
  'transfer-users',
  'upload-1',
  'upload-2',
  'upload-3',
  'upload-4',
  'upload-box-1',
  'upload-box-2',
  'upload-box-3',
  'upload-box-4',
  'upload-box-5',
  'upload-circle',
  'upload-computer',
  'upload-harddrive-1',
  'upload-harddrive-2',
  'upload-notebook',
  'usb',
  'wifi-computer-1',
  'wifi-computer-2',
  'wifi-laptop-1',
  'wifi-laptop-2',
  'wifi-modem-1',
  'wifi-modem-2',
  'wifi-modem-3',
  'world-refresh',
  'help-circle',
  'infomation-circle',
  'loading-1',
  'loading-2',
  'loading-3',
  'loading-4',
  'radioactive-1',
  'radioactive-2',
  'report-problem-circle',
  'report-problem-diamond',
  'report-problem-triangle',
  'sign-stop',
  'trending-down',
  'trending-up',
  'window-loading',
  'window-speedtest',
  'megaphone-1',
  'megaphone-2',
  'rss-feed-box',
  'rss-feed',
  'satellite-dish',
  'satellite',
  'share-box-forward-1',
  'share-box-forward-2',
  'share-box',
  'share-favorite',
  'share-location',
  'share-setting',
  'share',
  'signal-antenna-1',
  'signal-antenna-2',
  'signal-around',
  'signal-person',
  'signal-tower-1',
  'signal-tower-2',
  'lock-circle',
  'lock-close-1',
  'lock-close-2',
  'lock-close-3',
  'lock-close-4',
  'lock-close-5',
  'lock-close-6',
  'lock-door',
  'lock-key',
  'lock-open-1',
  'lock-open-2',
  'lock-open-3',
  'lock-shield',
  'lock-wheel',
  'badge-1',
  'badge-2',
  'badge-3',
  'badge-4',
  'badge-5',
  'badge-6',
  'badge-7',
  'badge-8',
  'badge-check-1',
  'badge-check-2',
  'badge-check',
  'badge-exclamation',
  'badge-heart',
  'badge-like-1',
  'badge-like-2',
  'badge-number-one-1',
  'badge-number-one-2',
  'badge-one-hundred-1',
  'badge-one-hundred-2',
  'badge-plus',
  'badge-question',
  'badge-star-1',
  'badge-star-2',
  'badge-star',
  'certificate-1',
  'certificate-2',
  'crown-1',
  'crown-2',
  'crown-3',
  'flag-1',
  'flag-2',
  'flag-3',
  'flag-death-skull',
  'flag-heart',
  'flag-square-1',
  'flag-square-2',
  'flag-square-3',
  'flag-star',
  'flag-triangle-1',
  'flag-triangle-2',
  'flags',
  'google-plus-plus-one',
  'google-plus',
  'heart-add',
  'heart-angel',
  'heart-block',
  'heart-broken',
  'heart-check',
  'heart-circle',
  'heart-delete',
  'heart-download',
  'heart-edit',
  'heart-favorite',
  'heart-list',
  'heart-lock',
  'heart-refresh',
  'heart-setting',
  'heart-share',
  'heart-subtract',
  'heart-upload',
  'heart-view',
  'heart-warning',
  'heart',
  'hot-topic-box',
  'hot-topic-circle',
  'hot-topic',
  'medal-1',
  'medal-2',
  'medal-3',
  'medal-4',
  'pedestal',
  'pedestial-winners',
  'present-box',
  'rank-army-1',
  'rank-army-2',
  'rank-army-3',
  'rank-army-4',
  'rank-army-5',
  'rank-army-badge-1',
  'rank-army-badge-2',
  'rank-army-heart-badge',
  'rank-army-star-1',
  'rank-army-star-2',
  'rank-army-star-badge-1',
  'rank-army-star-badge-2',
  'rank-army-star-badge-3',
  'rank-army-star-badge-4',
  'rank-army-star-badge-5',
  'share-heart',
  'share-star',
  'star-center',
  'star-decrease',
  'star-half',
  'star-increase',
  'thumbs-down-1',
  'thumbs-down-2',
  'thumbs-up-1',
  'thumbs-up-2',
  'torch',
  'trophy-1',
  'trophy-2',
  'trophy-3',
  'trophy-oscar',
  'trophy-star-1',
  'trophy-star-2',
  'trophy-user',
  'book-1',
  'book-2',
  'book-address',
  'book-audio',
  'book-bookmarked',
  'book-contact-1',
  'book-contact-2',
  'book-disk',
  'book-download-1',
  'book-download-2',
  'book-edit',
  'book-favorite-1',
  'book-favorite-2',
  'book-favorite-heart',
  'book-image',
  'book-location',
  'book-open-1',
  'book-open-2',
  'book-open-3',
  'book-open-4',
  'book-open-bookmarked-1',
  'book-open-bookmarked-2',
  'book-phone-1',
  'book-phone-2',
  'book-playlist',
  'book-share',
  'book-upload',
  'book-video',
  'book-view',
  'books-encyclopedia-1',
  'books-encyclopedia-2',
  'books-library',
  'box-1',
  'box-2',
  'box-3',
  'box-full',
  'boxes-1',
  'boxes-2',
  'briefcase',
  'clipboard-1',
  'clipboard-2',
  'clipboard-add',
  'clipboard-check',
  'clipboard-edit',
  'clipboard-subtract',
  'drawer-1',
  'drawer-2',
  'drawer-3',
  'filter-1',
  'filter-2',
  'filter-3',
  'filter-text',
  'folder-binders',
  'layers-hide',
  'layers-show',
  'new-document-check',
  'new-document-layer',
  'new-document-text',
  'new-document',
  'new-notepad-checklist',
  'new-notepad-layer',
  'new-notepad-text',
  'new-notepad',
  'news-article',
  'newspaper',
  'notebook-bookmarked',
  'notebook',
  'sketch-pad',
  'view-agenda',
  'view-array',
  'view-carousel',
  'view-column',
  'view-content',
  'view-headline',
  'view-list',
  'view-module-1',
  'view-module-2',
  'clear-all',
  'filter-12',
  'filter-22',
  'filter-32',
  'filter-4',
  'filter-5',
  'filter-6',
  'filter-7',
  'filter-8',
  'filter-9',
  'filter-10',
  'filter-android',
  'filter-audio',
  'filter-film',
  'filter-heart',
  'filter-mail',
  'filter-picture',
  'filter-plus-one',
  'filter-plus',
  'filter-text2',
  'filter-video',
  'navigation-before-1',
  'navigation-before-2',
  'navigation-before-3',
  'navigation-before-4',
  'navigation-circle-down',
  'navigation-circle-left',
  'navigation-circle-right',
  'navigation-circle-up',
  'navigation-down-1',
  'navigation-down-2',
  'navigation-drawer-1',
  'navigation-drawer-2',
  'navigation-left-1',
  'navigation-left-2',
  'navigation-next-1',
  'navigation-next-2',
  'navigation-next-3',
  'navigation-next-4',
  'navigation-right-1',
  'navigation-right-2',
  'navigation-show-more-1',
  'navigation-show-more-2',
  'navigation-up-1',
  'navigation-up-2',
  'area-circle',
  'crosshair-1',
  'crosshair-2',
  'crosshair-3',
  'cursor-1',
  'cursor-2',
  'cursor-add',
  'cursor-double-click-1',
  'cursor-double-click-2',
  'cursor-double',
  'cursor-down',
  'cursor-finger',
  'cursor-hand-1',
  'cursor-hand-2',
  'cursor-hold-1',
  'cursor-hold-2',
  'cursor-information',
  'cursor-move-1',
  'cursor-move-2',
  'cursor-move-3',
  'cursor-move-left-right-1',
  'cursor-move-left-right-2',
  'cursor-move-left',
  'cursor-move-right',
  'cursor-move-up-down-1',
  'cursor-move-up-down-2',
  'cursor-move-up',
  'cursor-move',
  'cursor-question',
  'cursor-select-area',
  'cursor-tap',
  'cursor-touch-1',
  'cursor-touch-2',
  'direction-button-1',
  'direction-button-2',
  'direction-button-3',
  'frame-1',
  'frame-2',
  'frame-3',
  'target',
  'headphone',
  'video-camera',
  'video-meeting-circle',
  'video-meeting-computer',
  'video-meeting-group',
  'video-meeting-laptop',
  'video-meeting-phone',
  'video-meeting-square',
  'controller',
  'google-nest',
  'hand-kindle',
  'hand-remote',
  'hand-tablet',
  'ipod',
  'kindle',
  'mp3-player',
  'remote-television',
  'smartphone-iphone',
  'smartphone-pen',
  'smartwatch-1',
  'smartwatch-2',
  'smartwatch-circle-alarm',
  'smartwatch-square-alarm',
  'smartwatch-square-brightness',
  'smartwatch-square-chat',
  'smartwatch-square-mail-2',
  'smartwatch-square',
  'tablet-ipad-1',
  'tablet-ipad-2',
  'touchpad-wireless',
  'android-logo',
  'battery-charge',
  'battery-eco',
  'battery-full',
  'battery-high',
  'battery-low',
  'battery-medium',
  'flash-sync',
  'flash',
  'mobile-phone-1',
  'mobile-phone-2',
  'mobile-phone-3',
  'mobile-phone-4',
  'mobile-phone-add-contact',
  'mobile-phone-alarm',
  'mobile-phone-at-sign',
  'mobile-phone-audio',
  'mobile-phone-block',
  'mobile-phone-broken',
  'mobile-phone-cash',
  'mobile-phone-check',
  'mobile-phone-code',
  'mobile-phone-credit-card',
  'mobile-phone-download',
  'mobile-phone-edit-1',
  'mobile-phone-edit-2',
  'mobile-phone-edit-3',
  'mobile-phone-flash',
  'mobile-phone-heart',
  'mobile-phone-home',
  'mobile-phone-information',
  'mobile-phone-landscape',
  'mobile-phone-list',
  'mobile-phone-location',
  'mobile-phone-lock-landscape',
  'mobile-phone-lock-portrait',
  'mobile-phone-navigation',
  'mobile-phone-picture',
  'mobile-phone-portrait',
  'mobile-phone-power',
  'mobile-phone-protect',
  'mobile-phone-qr-code',
  'mobile-phone-question',
  'mobile-phone-record',
  'mobile-phone-recycle-bin',
  'mobile-phone-refresh',
  'mobile-phone-remove-contact',
  'mobile-phone-rotate-1',
  'mobile-phone-rotate-2',
  'mobile-phone-rotate-lock',
  'mobile-phone-setting',
  'mobile-phone-share',
  'mobile-phone-shopping',
  'mobile-phone-silent',
  'mobile-phone-star',
  'mobile-phone-sync-1',
  'mobile-phone-sync-2',
  'mobile-phone-sync-remove',
  'mobile-phone-text-message-1',
  'mobile-phone-text-message-2',
  'mobile-phone-time',
  'mobile-phone-up-down',
  'mobile-phone-upload',
  'mobile-phone-vibrate',
  'mobile-phone-video-call',
  'mobile-phone-video',
  'mobile-phone-view-1',
  'mobile-phone-view-2',
  'mobile-phone-warning',
  'mobile-phone-wifi',
  'qr-code-scan',
  'roaming-3g',
  'roaming-4g',
  'roaming-5g',
  'roaming-lte',
  'signal-3g',
  'signal-4g',
  'signal-5g',
  'signal-lte',
  'fax-machine',
  'phone-1',
  'phone-2',
  'phone-3',
  'phone-4',
  'phone-5',
  'phone-book',
  'phone-booth',
  'phone-call-1',
  'phone-call-2',
  'phone-call-24-hours',
  'phone-call-add',
  'phone-call-bluetooth',
  'phone-call-food',
  'phone-call-forward',
  'phone-call-incoming',
  'phone-call-list',
  'phone-call-lock',
  'phone-call-merge',
  'phone-call-outgoing',
  'phone-call-pause',
  'phone-call-pending',
  'phone-call-record',
  'phone-call-refresh',
  'phone-call-reject',
  'phone-call-silent',
  'phone-call-split',
  'phone-call-subtract',
  'phone-call-timeout',
  'phone-circle',
  'phone-miss-call',
  'phone-ringing',
  'phone-signal-full',
  'phone-signal-high',
  'phone-signal-low',
  'phone-signal-medium',
  'phone-signal-none',
  'phone-signal-very-low',
  'phone-silent-circle',
  'sd-card-download',
  'battery-charge2',
  'computer-chip-2-core',
  'computer-chip-4-core',
  'computer-chip-8-core',
  'computer-chip-32-bit',
  'computer-chip-64-bit',
  'computer-chip-fire',
  'computer-chip-flash',
  'computer-chip-protect',
  'computer-chip',
  'computer-disk-drive-1',
  'computer-disk-drive-2',
  'computer-harddisk',
  'computer-imac-1',
  'computer-imac-2',
  'computer-notebook-1',
  'computer-notebook-2',
  'computer-pc-1',
  'computer-pc-2',
  'computer-pc-3',
  'computer-pc-4',
  'computer-power-supply',
  'computer-ram',
  'computer-screen-1',
  'computer-screen-2',
  'computer-screen-3',
  'disk-1',
  'disk-2',
  'disk-3',
  'disk-broken',
  'disk-burn',
  'electricity-outlet-1',
  'electricity-outlet-2',
  'electricity-outlet-3',
  'floppy-disk',
  'keyboard-1',
  'keyboard-2',
  'keyboard-wireless-1',
  'keyboard-wireless-2',
  'modem',
  'motherboard',
  'mouse-wireless-1',
  'mouse-wireless-2',
  'mouse',
  'plug-1',
  'plug-2',
  'plug-3',
  'port-keyboard',
  'ram',
  'scanner',
  'sim-card',
  'usb-1',
  'usb-2',
  'usb-lock',
  'webcam',
  'bubble-chat-1',
  'bubble-chat-2',
  'bubble-chat-3',
  'bubble-chat-add-1',
  'bubble-chat-add-2',
  'bubble-chat-add-3',
  'bubble-chat-block-1',
  'bubble-chat-block-2',
  'bubble-chat-block-3',
  'bubble-chat-check-1',
  'bubble-chat-check-2',
  'bubble-chat-check-3',
  'bubble-chat-download-1',
  'bubble-chat-download-2',
  'bubble-chat-download-3',
  'bubble-chat-edit-1',
  'bubble-chat-edit-2',
  'bubble-chat-edit-3',
  'bubble-chat-exclamation-1',
  'bubble-chat-exclamation-2',
  'bubble-chat-favorite-heart-1',
  'bubble-chat-favorite-heart-2',
  'bubble-chat-favorite-heart-3',
  'bubble-chat-favorite-star-1',
  'bubble-chat-favorite-star-2',
  'bubble-chat-favorite-star-3',
  'bubble-chat-forwar-all-1',
  'bubble-chat-forward-1',
  'bubble-chat-forward-2',
  'bubble-chat-forward-all-2',
  'bubble-chat-graph-1',
  'bubble-chat-graph-2',
  'bubble-chat-heart-1',
  'bubble-chat-heart-2',
  'bubble-chat-information-1',
  'bubble-chat-information-2',
  'bubble-chat-list-1',
  'bubble-chat-lock-1',
  'bubble-chat-lock-2',
  'bubble-chat-lock-3',
  'bubble-chat-picture-1',
  'bubble-chat-picture-2',
  'bubble-chat-question-1',
  'bubble-chat-question-2',
  'bubble-chat-quote-1',
  'bubble-chat-quote-2',
  'bubble-chat-refresh-1',
  'bubble-chat-refresh-2',
  'bubble-chat-refresh-3',
  'bubble-chat-remove-1',
  'bubble-chat-remove-2',
  'bubble-chat-remove-3',
  'bubble-chat-setting-1',
  'bubble-chat-setting-2',
  'bubble-chat-setting-3',
  'bubble-chat-share-1',
  'bubble-chat-share-2',
  'bubble-chat-share-3',
  'bubble-chat-smiley-1',
  'bubble-chat-smiley-2',
  'bubble-chat-subtract-1',
  'bubble-chat-subtract-2',
  'bubble-chat-subtract-3',
  'bubble-chat-text-1',
  'bubble-chat-text-2',
  'bubble-chat-typing-1',
  'bubble-chat-typing-2',
  'bubble-chat-typing-3',
  'bubble-chat-upload-1',
  'bubble-chat-upload-3',
  'bubble-chat-upoad-2',
  'bubble-chat-view-1',
  'bubble-chat-view-2',
  'bubble-chat-view-3',
  'bubble-chat-warning-1',
  'bubble-chat-warning-2',
  'bubble-chat-warning-3',
  'bubble-chat-wink-1',
  'bubble-chat-wink-2',
  'bubble-thought',
  'chat-double-bubble-1',
  'chat-double-bubble-2',
  'chat-double-bubble-3',
  'chat-double-bubble-4',
  'user-chat-1',
  'user-chat-2',
  'user-chat-3',
  'user-chat-4',
  'user-chat-5',
  'user-chat-6',
  'user-chat-7',
  'user-chat-8',
  'user-chat-9',
  'user-chat-10',
  'user-chat-alert-1',
  'user-chat-alert-2',
  'user-chat-alert-question',
  'user-chat-check',
  'user-chat-dollar',
  'user-chat-group',
  'user-chat-heart',
  'user-chat-idea',
  'user-chat-question',
  'user-chat-skull',
  'user-chat-star',
  'user-chat-timeout',
  'artist',
  'astronaut',
  'bellboy',
  'business-man-1',
  'business-man-2',
  'business-man-3',
  'chef',
  'detective',
  'doctor-1',
  'doctor-2',
  'farmer',
  'female-bow',
  'gentleman',
  'graduate',
  'king',
  'lady-1',
  'lady-2',
  'magician',
  'man',
  'miner',
  'nurse',
  'operator-female',
  'police-officer',
  'prisoner',
  'scuba-diver',
  'sport-player',
  'surgeon',
  'telemarketer',
  'thief',
  'group-add-1',
  'group-add-2',
  'group-block',
  'group-cash',
  'group-chat',
  'group-check',
  'group-download',
  'group-edit',
  'group-favorite-heart',
  'group-favorite-star',
  'group-flag',
  'group-flash',
  'group-information',
  'group-list',
  'group-lock',
  'group-mail',
  'group-protect',
  'group-refresh',
  'group-remove',
  'group-setting',
  'group-share',
  'group-subtract-1',
  'group-subtract-2',
  'group-timeout',
  'group-unknown',
  'group-upload',
  'group-view',
  'group-warning',
  'person-1',
  'person-2',
  'person-add-1',
  'person-add-2',
  'person-block-1',
  'person-block-2',
  'person-cash-1',
  'person-cash-2',
  'person-chat-1',
  'person-chat-2',
  'person-check-1',
  'person-check-2',
  'person-download-1',
  'person-download-2',
  'person-edit-1',
  'person-edit-2',
  'person-favorite-heart-1',
  'person-favorite-heart-2',
  'person-favorite-star-1',
  'person-favorite-star-2',
  'person-flag-1',
  'person-flag-2',
  'person-flash-1',
  'person-flash-2',
  'person-information-1',
  'person-information-2',
  'person-list-1',
  'person-list-2',
  'person-lock-1',
  'person-lock-2',
  'person-mail-1',
  'person-mail-2',
  'person-protect-1',
  'person-protect-2',
  'person-refresh-1',
  'person-refresh-2',
  'person-remove-1',
  'person-remove-2',
  'person-setting-1',
  'person-setting-2',
  'person-share-1',
  'person-share-2',
  'person-subtract-1',
  'person-subtract-2',
  'person-timeout-1',
  'person-timeout-2',
  'person-unknown-1',
  'person-unknown-2',
  'person-upload-1',
  'person-upload-2',
  'person-view-1',
  'person-view-2',
  'person-warning-1',
  'person-warning-2',
  'account-check',
  'account-circle-1',
  'account-circle-2',
  'account-code',
  'account-dollar',
  'account-expand',
  'account-favorite',
  'account-files',
  'account-find-1',
  'account-find-2',
  'account-flag',
  'account-flash',
  'account-group-1',
  'account-group-2',
  'account-group-3',
  'account-group-4',
  'account-group-5',
  'account-group-circle',
  'account-home-1',
  'account-home-2',
  'account-lock',
  'account-music',
  'account-protection',
  'account-square',
  'account-star',
  'account-target',
  'account-television',
  'account-text',
  'account-up-down',
  'account',
  'business-check',
  'business-decrease',
  'business-female-briefcase',
  'business-female-chat',
  'business-female-flag',
  'business-female-heart',
  'business-female-mail',
  'business-female-megaphone',
  'business-female-money',
  'business-female-shield',
  'business-female-shopping-bag',
  'business-female-time',
  'business-hierarchy',
  'business-idea',
  'business-increase',
  'business-male-briefcase',
  'business-male-chat',
  'business-male-flag',
  'business-male-heart',
  'business-male-mail',
  'business-male-megaphone',
  'business-male-money',
  'business-male-shield',
  'business-male-shopping-bag',
  'business-male-time',
  'business-man-12',
  'business-man-22',
  'business-man-speech',
  'business-money',
  'business-question',
  'business-setting',
  'business-shield',
  'business-statistics-1',
  'business-statistics-2',
  'business-statistics-3',
  'business-strategy',
  'business-target',
  'business-time',
  'female',
  'gender-female-female',
  'gender-female',
  'gender-male-female',
  'gender-male-male',
  'gender-male',
  'group-chat2',
  'group-global',
  'group-wifi',
  'hierarchy-business',
  'id-card-1',
  'id-card-2',
  'id-card-3',
  'id-card-4',
  'id-card-double',
  'id-picture-1',
  'id-picture-2',
  'male',
  'phone-book-1',
  'phone-book-2',
  'profile-picture-1',
  'at-sign-circle',
  'at-sign-stamp',
  'at-sign',
  'computer-email',
  'email-2',
  'email-add',
  'email-at-sign',
  'email-audio-video',
  'email-block',
  'email-check-1',
  'email-check-2',
  'email-circle',
  'email-compose',
  'email-download',
  'email-favorite-1',
  'email-favorite-2',
  'email-forward',
  'email-inbox-1',
  'email-lock',
  'email-love-letter',
  'email-outbox-1',
  'email-picture',
  'email-read-1',
  'email-read-2',
  'email-read-later',
  'email-receipt',
  'email-refresh-1',
  'email-refresh-2',
  'email-remove',
  'email-reply-all',
  'email-reply',
  'email-send-1',
  'email-send-2',
  'email-send-3',
  'email-setup',
  'email-share',
  'email-target',
  'email-text',
  'email-upload',
  'email-warning',
  'email',
  'envelope-1',
  'envelope-2',
  'envelope-3',
  'forward-box',
  'forward',
  'inbox-1',
  'inbox-audio-video',
  'inbox-dollar',
  'inbox-heart',
  'inbox-home',
  'inbox-music',
  'inbox-picture',
  'inbox-skull',
  'inbox-text',
  'inbox',
  'laptop-email',
  'mailbox-1',
  'mailbox-2',
  'mailbox-3',
  'mailbox-4',
  'outbox',
  'reply-all',
  'reply',
  'stamp',
  'ups-document',
  'alarm-check',
  'alarm-clock',
  'alarm-close',
  'alarm-silent',
  'alarm-snooze',
  'alarm-sound',
  'alarm-timeout',
  'alarm',
  'calendar-1',
  'calendar-2',
  'calendar-add-1',
  'calendar-add-2',
  'calendar-alert',
  'calendar-block',
  'calendar-check-1',
  'calendar-check-2',
  'calendar-close-1',
  'calendar-close-2',
  'calendar-dollar-1',
  'calendar-dollar-2',
  'calendar-download',
  'calendar-edit-1',
  'calendar-edit-2',
  'calendar-graduation',
  'calendar-heart-1',
  'calendar-heart-2',
  'calendar-home',
  'calendar-information',
  'calendar-lock-1',
  'calendar-lock-2',
  'calendar-question',
  'calendar-refresh-1',
  'calendar-setting',
  'calendar-share',
  'calendar-star-1',
  'calendar-star-2',
  'calendar-subtract',
  'calendar-sync',
  'calendar-timeout',
  'calendar-upload',
  'calendar-view-1',
  'calendar-view-2',
  'clock-1',
  'clock-2',
  'clock-3',
  'clock-fire',
  'hourglass-1',
  'hourglass-2',
  'share-time',
  'stopwatch',
  'timer-full-1',
  'timer-full-2',
  'timer-half-1',
  'timer-half-2',
  'timer-half-3',
  'timer-half-4',
  'timer-quarter-1',
  'timer-quarter-2',
  'timer-three-quarter-1',
  'timer-three-quarter-2',
  'watch-1',
  'watch-2',
  'computer-check',
  'cursor-dial',
  'dial-pad',
  'door-enter',
  'door-exit',
  'door-lock',
  'door',
  'exit-sign',
  'fingerprint-login',
  'key-1',
  'key-2',
  'key-hole-1',
  'key-hole-2',
  'keys',
  'lock-square',
  'login-1',
  'login-2',
  'login-3',
  'login-key',
  'login-lock',
  'login',
  'logout-1',
  'logout-2',
  'logout-3',
  'tap-check',
  'tap-lock',
  'tap-single',
  'user-login',
  'check-1',
  'check-2',
  'check-badge',
  'check-box-1',
  'check-box-2',
  'check-circle-1',
  'check-circle-2',
  'check-double-1',
  'check-double-2',
  'check-shield',
  'checklist',
  'close-badge',
  'close-shield',
  'close',
  'cursor-choose',
  'atomic-bomb',
  'bin-1',
  'bin-2',
  'binoculars',
  'checklist-pen',
  'delete-1',
  'delete-2',
  'flash-1',
  'flash-2',
  'flash-off',
  'hide',
  'link-1',
  'link-2',
  'link-3',
  'link-broken-1',
  'link-broken-2',
  'notebook-pencil',
  'paperclip-1',
  'paperclip-2',
  'pen-1',
  'pen-2',
  'pen-3',
  'pen-4',
  'pen-paint-brush',
  'pen-write-1',
  'pen-write-2',
  'pen-write-3',
  'pencil-1',
  'pencil-2',
  'pencil-3',
  'pencil-circle',
  'pencil-fill',
  'pencil-pens',
  'pencil-write-1',
  'pencil-write-2',
  'pin-1',
  'pin-2',
  'pocket-pen',
  'print-picture',
  'print-text',
  'quill-circle',
  'quill-ink',
  'quill-write',
  'scissors-1',
  'scissors-2',
  'search',
  'typewriter-1',
  'typewriter-2',
  'view-1',
  'view-2',
  'zoom-area-in',
  'zoom-area-out',
  'frelon',
];

export default neocityIcons;
