<template>
  <Modal
    v-model="showOnboarding"
    :title="$t('modals.onboarding.title')"
    cancelText=""
    :okText="$t('modals.onboarding.confirm')"
    :okButtonProps="{ type: 'primary', color: 'default' }"
    paddingClass="p-4"
    @onOk="confirmOnboarding"
  >
    <Toast
      :notification="{
        group: 'notification',
        type: 'info',
        title: $t('modals.onboarding.info_alert'),
      }"
    />
    <div class="mb-3 flex flex-col gap-3 text-sm leading-5 tracking-[1.45%]">
      <p>{{ $t('modals.onboarding.intro') }}</p>
      <p>{{ $t('modals.onboarding.tests') }}</p>
      <p v-html="$t('modals.onboarding.new_features')"></p>
      <p v-html="$t('modals.onboarding.more_information')"></p>
    </div>
    <div
      class="rounded-sm bg-neutral-soft p-2.5 text-sm leading-6 tracking-wide text-neutral-dark"
      v-html="$t('modals.onboarding.participate_design', { clientId: client?.id, clientName: client?.name, userEmail: user?.email })"
    ></div>
    <template #footer-extra>
      <FormKit v-model="hideOnboardingNextTime" type="checkbox" :label="$t('modals.onboarding.not_warn_anymore')"></FormKit>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { onMounted, ref } from 'vue';
import { useCookies } from '@vueuse/integrations/useCookies';
import { useEventBus } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { useUser } from '@/stores/user';
import { useClient } from '@/stores/client';
// eslint-disable-next-line import/no-unresolved
import confetti from 'https://esm.run/canvas-confetti@1';

const cookies = useCookies(['onboarding_seen']);

const userStore = useUser();
const clientStore = useClient();

const { user } = storeToRefs(userStore);
const { client } = storeToRefs(clientStore);

const currentRoute = useRoute();

const showOnboarding = ref(false);
const hideOnboardingNextTime = ref(false);

const confirmOnboarding = (): void => {
  if (hideOnboardingNextTime.value) cookies.set('onboarding_seen', true);
  showOnboarding.value = false;
  confetti({
    particleCount: 200,
    spread: 120,
  });
};

const onBoardingEvent = useEventBus<string>('onboarding');

onMounted(() => {
  onBoardingEvent.on((_) => {
    if (_ === 'showOnboardingModal') {
      setTimeout(() => {
        if (currentRoute.name !== 'error') showOnboarding.value = true;
      }, 500);
    }
  });
});
</script>
