import { createTooltip, destroyTooltip } from 'floating-vue';
import { FormKitNode, FormKitTypeDefinition } from '@formkit/core';

const legends = ['checkbox_multi', 'radio_multi', 'repeater', 'transferlist'];

interface ExtendedFormKitTypeDefinition extends FormKitTypeDefinition {
  schemaMemoKey?: string;
}

export const addAsteriskPlugin = (node: FormKitNode): void => {
  if (['button', 'submit', 'hidden', 'group', 'list', 'meta'].includes(node.props.type)) return;

  node.on('created', () => {
    const legendOrLabel = legends.includes(`${node.props.type}${node.props.options ? '_multi' : ''}`) ? 'legend' : 'label';

    const definition = node.props.definition as ExtendedFormKitTypeDefinition;
    if (definition?.schemaMemoKey) definition.schemaMemoKey += `${node.props.options ? '_multi' : ''}_add_asterisk`;

    const schemaFn = node?.props?.definition?.schema;
    if (!node?.props?.definition) return;
    // eslint-disable-next-line no-param-reassign, consistent-return, @typescript-eslint/no-explicit-any
    node.props.definition.schema = (sectionsSchema = {}): any => {
      const isRequired = node.props.parsedRules.some((rule) => rule.name === 'required');
      const ignoredFormsIds = ['login', 'forgotten-password', 'reset-password'];
      const isInIgnoredForm = ignoredFormsIds.some((form) => node.parent?.context?.id === form);
      if (isRequired && !isInIgnoredForm) {
        // eslint-disable-next-line no-param-reassign
        sectionsSchema[legendOrLabel] = {
          children: [
            '$label',
            {
              $el: 'span',
              attrs: {
                class: 'text-danger-basic',
              },
              children: [' *'],
            },
          ],
        };
      }
      if (typeof schemaFn === 'function') return schemaFn(sectionsSchema);
    };
  });
};

// Add a tooltip to a checkbox option
// [Usage]
// :options="[
//   {
//     label: 'Option 1',
//     value: 'option1',
//     attrs: {
//       tooltip: 'Tooltip content',
//     },
// ]
export const checkboxTooltip = (node: FormKitNode): void => {
  const tooltips: HTMLElement[] = [];
  node.on('created', () => {
    if (node.props.type === 'checkbox') {
      setTimeout(() => {
        const checkboxTooltips = document.querySelectorAll('.checkbox-tooltip');
        checkboxTooltips.forEach((el: HTMLElement) => {
          createTooltip(
            el,
            {
              content: `<div class="max-w-[250px]">${el.getAttribute('content')}</div>`,
              html: true,
              triggers: ['hover'],
              placement: 'top-end',
            },
            {}
          );
          tooltips.push(el);
        });
      }, 200);
    }
  });
  node.on('destroyed', () => {
    tooltips.forEach((tooltip) => {
      destroyTooltip(tooltip);
    });
  });
};
