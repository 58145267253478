import { defineStore } from 'pinia';

import type { User } from '@/models/user.model';
import { axiosNeocityInstance } from '@/api/axios';
import { event } from 'vue-gtag';
import { useResetStore } from '@/utils/useResetStore';
import * as Sentry from '@sentry/vue';

export interface State {
  user?: User;
  authToken: string;
  clientId?: string;
}

type Credentials = {
  username: string;
  password: string;
};

export const useUser = defineStore({
  id: 'user',
  state: (): State => ({
    authToken: '',
    user: undefined,
    clientId: undefined,
  }),
  getters: {},
  actions: {
    setToken(token: string) {
      this.$patch({ authToken: token });
    },

    setClientId(clientId: string) {
      this.$patch({ clientId });
    },

    async login(credentials: Credentials): Promise<void> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .post('/admin/login', credentials)
          .then((response) => {
            this.setToken(response.data.id);
            event('login', { method: 'form' });
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    async getCurrentUser(): Promise<User> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .get('/admin/current')
          .then((response) => {
            this.user = response.data;
            Sentry.setUser({
              email: response.data.email,
              id: response.data.id,
              username: response.data.username,
            });
            resolve(response.data);
          })
          .catch(async (error) => {
            if (this.$state.authToken && this.$state.user) await this.logout();
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    async logout(): Promise<void> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .post('/admin/logout')
          .then(() => {
            event('logout');
            resolve();
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          })
          .finally(() => {
            const resetStore = useResetStore();
            resetStore.all();
            localStorage.clear();
            Sentry.setUser(null);
          });
      });
    },

    async requestNewPassword(credentials: { email: string }): Promise<void> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .post('/admin/reset', { email: credentials.email, backOfficeVersion: 2 })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    async resetPassword(credentials: { password: string; token: string }): Promise<void> {
      const config = {
        headers: {
          Authorization: credentials.token,
          'Content-type': 'application/x-www-form-urlencoded',
          'Cache-Control': 'reload',
        },
      };

      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .post('/admin/reset-password', `newPassword=${encodeURIComponent(credentials.password)}`, config)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    async getUserById(id: string): Promise<User> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .get(`/admin/${id}`)
          .then((response) => resolve(response.data))
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    hasRight(roles: string | string[]): boolean {
      const userRoles = this.user?.role;
      if (userRoles && roles && Array.isArray(roles)) return roles.some((role) => userRoles.includes(role));
      if (userRoles && roles && typeof roles === 'string') return userRoles.includes(roles);
      return false;
    },
  },
  persist: true,
});
