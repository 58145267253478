export default {
  collections: {
    addCollection: 'Ajouter une collecte',
    collectionsList: {
      zonesSelector: {
        placeholder: 'Sélectionner une zone',
        everyZones: 'Toutes les zones',
      },
      tabs: {
        everyCollections: 'Toutes les collectes',
        thisWeek: 'Cette semaine',
        thisMonth: 'Ce mois-ci',
        customDateRange: 'Dates personnalisées',
      },
      tables: {
        columns: {
          garbageTypes: 'Types de déchets',
          slot: 'Créneau',
          filters: {
            titles: {
              garbageTypes: 'Filtre déchets',
              slots: 'Filtre créneaux',
            },
          },
        },
        empty: {
          noCollections: 'Aucune collecte pour le moment.',
          noCollectionsFiltered: 'Aucune collecte ne correspond aux filtres sélectionnés.',
          noCollectionsThisWeek: 'Aucune collecte cette semaine.',
          noCollectionsThisMonth: 'Aucune collecte ce mois-ci.',
          noCollectionsCustomDateRange:
            'Aucune collecte ne correspond aux dates sélectionnées.<br>Nous vous invitons à consulter le planning sur une autre période.',
        },
        warnings: {
          collectionWithoutZone: "Cette collecte n'a pas de zone associée, elle n'est donc pas visible par les citoyens sur l'application mobile.",
          collectionWithoutSlot: "Cette collecte n'a pas de créneau associé, elle n'est donc pas visible par les citoyens sur l'application mobile.",
        },
        actions: {
          collectionEdit: 'Modifier la collecte',
          collectionDelete: 'Supprimer la collecte',
          collectionDateEdit: 'Modifier la date de passage',
          collectionDateDelete: 'Supprimer la date de passage',
        },
      },
      modals: {
        deleteCollection: {
          title: 'Supprimer la collecte',
          warning: {
            title: 'Êtes-vous sûr(e) de vouloir supprimer cette collecte ?',
            text: "Aucune date à venir n'est associée à cette collecte. | Attention, vous allez supprimer {count} date de collecte. | Attention, vous allez supprimer {count} dates de collectes.",
          },
          message:
            "La suppression est irréversible. | La suppression est irréversible. La date de collecte sera supprimée de votre interface d'administration et de votre application citoyenne. <br/>Les prochaines dates de collectes associées ne seront plus visibles par les citoyens. | La suppression est irréversible. Toutes les dates de collectes seront supprimées de votre interface d'administration et de votre application citoyenne. <br/>Les prochaines dates de collectes associées ne seront plus visibles par les citoyens.",
        },
        deleteCollectionDate: {
          title: 'Supprimer la date de collecte',
          warning: {
            title: 'Êtes-vous sûr(e) de vouloir supprimer cette date de collecte ?',
          },
          message:
            "La suppression est irréversible. Cette date sera supprimée de votre interface d'administration et de votre application mobile. Elle ne sera plus visible par les citoyens.",
        },
      },
    },
    collectionDetail: {
      details: {
        sectionTitle: 'Détails',
        garbageTypeSelector: {
          label: 'Type de déchets',
          placeholder: 'Choisir un type de déchets',
          errors: {
            required: 'Vous devez sélectionner une catégorie',
          },
        },
        zonesSelector: {
          label: 'Zones concernées',
          errors: {
            required: 'Vous devez sélectionner au moins une zone.',
            noZonesOptions: 'Aucune zone existante pour la fonctionnalité de collecte de déchets. Veuillez en créer une avant de continuer.',
          },
        },
      },
      reccurentCollection: {
        sectionTitle: 'Collecte récurrente',
        frequencySelector: {
          label: 'Passage',
          options: {
            daily: 'Tous les jours',
            weekly: 'Toutes les semaines',
            custom: 'Fréquence personnalisée',
          },
        },
        daysSelector: {
          errors: {
            required: 'Vous devez sélectionner au moins un jour de la semaine.',
          },
        },
        slotSelector: {
          label: 'Créneau',
          dayLabel: 'Créneau du {day}',
          placeholder: 'Choisir un créneau',
          errors: {
            required: 'Vous devez sélectionner un créneau',
          },
        },
        dateRangeSelector: {
          from: {
            label: 'Du',
            title: 'Date du premier passage',
          },
          to: {
            label: 'Au',
            title: 'Date du dernier passage',
          },
        },
        excludedCollectionDates: {
          toggleExcludedCollectionDates: {
            label: 'Exclure des dates',
            tooltip: 'Cette option vous permet de supprimer des dates de votre collecte récurrente, par exemple, des jours fériés.',
          },
          dateSelector: {
            label: 'Le',
            title: 'Date du passage',
          },
          addExcludedCollectionDate: 'Ajouter une date à exclure',
          warnings: {
            dateNotPlanned: 'Cette date ne fait pas partie de la planification.',
          },
        },
      },
      exceptionnalCollectionDates: {
        sectionTitle: 'Collecte exceptionnelle',
        sectionSubTitle: 'Les jours de collectes exceptionnelles seront indiqués en violet sur le calendrier.',
        toggleExceptionnalCollection: {
          label: 'Ajouter une collecte exceptionnelle',
        },
        slotSelector: {
          label: 'Créneau',
          placeholder: 'Choisir un créneau',
          errors: {
            required: 'Vous devez sélectionner un créneau',
          },
        },
        dateSelector: {
          label: 'Le',
          title: 'Date du passage',
        },
        addExceptionnalCollectionDate: 'Ajouter une date de collecte',
        overwriteCheckbox: {
          label: 'Remplacer la date déjà planifiée',
        },
        warnings: {
          dateAlreadyPlanned: 'Cette date de collecte est déjà planifiée et n’aura aucun impact sur la collecte récurrente.',
          dateAlreadyPlannedWithDifferentSlot:
            'Cette date est déjà planifiée sur un créneau différent, vous pouvez conserver les deux ou exclure la date de la collecte récurrente.',
        },
      },
    },
    collectionDateEdit: {
      toasts: {
        passed: {
          title: 'Collecte effectuée le {date}.',
        },
        deleted: {
          title: 'Date de collecte supprimée.',
        },
      },
      details: {
        sectionTitle: 'Détails',
        garbageTypeSelector: {
          label: 'Type de déchets',
          placeholder: 'Sélectionner un type de déchets',
          errors: {
            required: 'Vous devez sélectionner une catégorie',
          },
        },
        zonesSelector: {
          label: 'Zones concernées',
          errors: {
            required: 'Vous devez sélectionner au moins une zone.',
          },
        },
      },
      collectionDate: {
        sectionTitle: 'Date de collecte',
        slotsSelector: {
          label: 'Choisir un créneau',
          placeholder: 'Sélectionner un créneau',
          errors: {
            required: 'Vous devez sélectionner un créneau',
          },
        },
        dateSelector: {
          label: 'Le',
          title: 'Date du passage',
        },
      },
    },
  },
  deposits: {
    addDepositLocation: 'Ajouter une infrastructure',
    addDepositPoint: 'Ajouter un point de dépôt',
  },
  garbageTypes: {
    emptyList: 'Aucun type de déchets trouvé',
    actions: {
      edit: 'Modifier le type de déchets',
      delete: 'Supprimer le type de déchets',
      resetContent: 'Réinitialiser le contenu',
    },
    modals: {
      addTemplate: {
        title: 'Choisir un modèle',
        dropdownLabel: 'Type de déchets',
      },
      deleteType: {
        title: 'Supprimer le type de déchets',
        warning: {
          title: 'Êtes-vous sûr(e) de vouloir supprimer ce type de déchets ?',
          message:
            "Aucune collecte associée n'est associée à ce type de déchets. | Attention, vous allez supprimer {count} collecte associée. | Attention, vous allez supprimer {count} collectes associées.",
        },
        message:
          'La suppression est irréversible. | La suppression est irréversible. La collecte associée à ce type de déchets sera supprimée de votre interface d’administration et de votre application citoyenne : | La suppression est irréversible. Toutes les collectes associées à ce type de déchets seront supprimées de votre interface d’administration et de votre application citoyenne :',
      },
    },
    addGarbageType: 'Ajouter un type de déchets',
    pickExistingTemplate: 'Choisir un type de déchets parmi les modèles existants',
    createNewType: 'Créer un nouveau type de déchets',
    form: {
      title: {
        errors: {
          required: 'Veuillez saisir un nom',
        },
      },
      color: {
        errors: {
          required: 'Veuillez sélectionner une couleur',
        },
      },
      icon: {
        errors: {
          required: 'Veuillez sélectionner un icône',
        },
      },
      allowedGarbages: {
        label: 'Déchets acceptés',
        placeholder: 'Liste des déchets acceptés',
        errors: {
          required: 'Veuillez renseigner la liste des déchets acceptés',
        },
      },
      forbiddenGarbages: {
        label: 'Déchets refusés',
        placeholder: 'Liste des déchets refusés',
        errors: {
          required: 'Veuillez renseigner la liste des déchets refusés',
        },
      },
      description: {
        label: 'Description',
        placeholder: 'Description du type de déchets',
        errors: {
          required: 'Veuillez renseigner une description pour ce type de déchet',
        },
      },
    },
  },
  sortingGuide: {
    details: {
      title: 'Détails',
      forms: {
        title: {
          label: 'Titre',
          placeholder: 'Titre du guide du tri',
          requiredError: 'Veuillez entrer un nom valide',
        },
        description: {
          label: 'Description',
          placeholder: 'Description courte',
        },
      },
    },
    garbageTypes: {
      title: 'Types de déchets à afficher',
      empty: {
        message: "Vous n'avez aucun type de déchets à afficher. <br/>Pour configurer votre guide du tri, ajoutez au moins un type de déchets.",
        addGarbageType: 'Ajouter un type de déchets',
      },
    },
    shortCut: {
      title: 'Raccourci vers le guide du tri',
      subTitle: 'En activant cette option, les utilisateurs pourront accéder au guide du tri depuis la fonctionnalité "Collecte des déchets"',
      forms: {
        display: {
          label: 'Afficher le bouton',
        },
      },
    },
    preview: {
      title: 'Collecte des ordures ménagères',
    },
  },
  zones: {
    addZone: 'Ajouter une zone',
    modals: {
      delete: {
        warning: {
          title: 'Êtes-vous sûr(e) de vouloir supprimer cette zone ?',
          text: "Aucune collecte n'utilise cette zone. | Attention, vous allez modifier {count} collecte utilisant cette zone. | Attention, vous allez modifier {count} collectes utilisant cette zone.",
        },
        message:
          "La suppression de cette zone est irréversible. | La suppression de cette zone est irréversible. La collecte associée à cette zone sera masquée de votre application citoyenne jusqu'à l'attribution d'une nouvelle zone : | La suppression de cette zone est irréversible. Toutes les collectes associées uniquement à cette zone seront masquées de votre application citoyenne jusqu'à l'attribution d'une nouvelle zone :",
      },
    },
  },
  slots: {
    toast:
      'Vous pouvez renommer vos créneaux comme vous le souhaitez.<br> Attention, la sélection de couleur s’applique uniquement sur votre interface d’administration et n’est pas visible sur l’application.',
    title: 'Détails',
    forms: {
      label: {
        title: 'Titre',
        color: 'Couleur',
      },
      placeholder: {
        title: 'Matin',
        color: 'Choisir une couleur',
        slot: 'Choisir un modèle de créneau',
      },
      error: {
        title: 'Veuillez entrer un titre valide',
      },
      addASlotBtn: 'Ajouter un créneau',
      addASlotFromTemplate: "Ajouter à partir d'un modèle",
    },
    deleteSlotModal: {
      title: 'Supprimer le créneau',
      warningTitle: 'Êtes-vous sûr(e) de vouloir supprimer ce créneau ?',
      warningText:
        "Aucune date n'est associée à ce créneau. | Attention, vous allez supprimer {count} date associée. | Attention, vous allez supprimer {count} dates associées.",
      message:
        "La suppression est irréversible. | La suppression est irréversible. La date de collecte associée à ce créneau sera supprimée de votre interface d'administration et de votre application citoyenne. | La suppression est irréversible. Les dates de collectes associées à ce créneau seront supprimées de votre interface d'administration et de votre application citoyenne.",
    },
    noSlots: 'Vous n’avez aucun créneau à afficher.<br> Vous pouvez créer un créneau ou en choisir un parmi les modèles existants.',
    addSlotFromTemplate: {
      title: 'Choisir un modèle',
      text: 'Créneau',
    },
    addSlot: 'Ajouter un créneau',
  },
  toasts: {
    addInfrastructuresText:
      'Ajoutez les infrastructures ou équipements que vous souhaitez faire figurer dans page de gestion des déchets. <br/>Vous pouvez sélectionner des infrastructures de type « collecte des déchets » déjà créées ou ajouter de nouvelles adresses.',
  },
};
