<template>
  <div class="flex h-full w-full items-center justify-center">
    <svg v-if="props.quarter" class="animate-spin" :width="iconSize" :height="iconSize" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.438 8.563A.562.562 0 0 1 14.874 8c0-.928-.181-1.828-.54-2.677a6.88 6.88 0 0 0-3.66-3.659A6.835 6.835 0 0 0 8 1.125.562.562 0 1 1 8 0c1.08 0 2.128.21 3.114.63.953.401 1.808.98 2.542 1.714a7.987 7.987 0 0 1 1.714 2.542c.418.986.628 2.034.628 3.114a.56.56 0 0 1-.56.563Z"
        :class="props.color === 'inherit' ? 'fill-inherit' : 'fill-base-basic'"
      />
    </svg>
    <Vue3Lottie v-else-if="props.neocity" :animationData="NeoLoading" :height="customIconSize" :width="customIconSize" />
    <svg v-else class="animate-spin" :width="iconSize" :height="iconSize" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 40c-2.7 0-5.32-.527-7.785-1.57a19.932 19.932 0 0 1-6.356-4.29 19.932 19.932 0 0 1-4.289-6.355A19.874 19.874 0 0 1 0 20a1.405 1.405 0 1 1 2.813 0c0 2.32.453 4.57 1.351 6.691a17.161 17.161 0 0 0 3.684 5.465 17.095 17.095 0 0 0 5.465 3.684A17.087 17.087 0 0 0 20 37.187c2.32 0 4.57-.453 6.691-1.351a17.159 17.159 0 0 0 5.465-3.684 17.095 17.095 0 0 0 3.684-5.465A17.087 17.087 0 0 0 37.187 20c0-2.32-.453-4.57-1.351-6.691a17.203 17.203 0 0 0-3.684-5.465 17.097 17.097 0 0 0-5.465-3.684A17.088 17.088 0 0 0 20 2.812 1.405 1.405 0 1 1 20 0c2.7 0 5.32.527 7.785 1.57a19.932 19.932 0 0 1 6.356 4.29 19.964 19.964 0 0 1 4.285 6.355A19.873 19.873 0 0 1 39.996 20c0 2.7-.527 5.32-1.57 7.785a19.857 19.857 0 0 1-4.285 6.356A19.963 19.963 0 0 1 20 40Z"
        :class="props.color === 'inherit' ? 'fill-inherit' : 'fill-base-basic'"
      />
    </svg>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import NeoLoading from '@/assets/animations/NeoLoading.json';

const props = defineProps({
  size: {
    type: String,
    default: 'medium',
    validator(value: string) {
      return ['small', 'medium', 'large', 'xlarge'].includes(value);
    },
  },
  color: {
    type: String,
    default: 'blue',
    validator(value: string) {
      return ['blue', 'inherit'].includes(value);
    },
  },
  quarter: {
    type: Boolean,
    default: false,
  },
  neocity: {
    type: Boolean,
    default: false,
  },
});

const iconSize = computed((): string => {
  switch (props.size) {
    case 'small':
      return '12px';
    case 'medium':
      return '16px';
    case 'large':
      return '24px';
    case 'xlarge':
      return '40px';
    default:
      return '16px';
  }
});

const customIconSize = computed((): number => {
  switch (props.size) {
    case 'small':
      return 14;
    case 'medium':
      return 18;
    case 'large':
      return 26;
    case 'xlarge':
      return 42;
    default:
      return 18;
  }
});
</script>
