export default {
  collections: {
    addCollection: 'Add a collection',
    collectionsList: {
      zonesSelector: {
        placeholder: 'Sélectionner une zone',
        everyZones: 'Toutes les zones',
      },
      tabs: {
        everyCollections: 'All collections',
        thisWeek: 'This week',
        thisMonth: 'This month',
        customDateRange: 'Custom dates',
      },
      tables: {
        columns: {
          garbageTypes: 'Garbage types',
          slot: 'Slot',
          filters: {
            titles: {
              garbageTypes: 'Garbage filter',
              slots: 'Slot filter',
            },
          },
        },
        empty: {
          noCollections: 'No collections found.',
          noCollectionsFiltered: 'No collections match the selected filters.',
          noCollectionsThisWeek: 'No collections this week.',
          noCollectionsThisMonth: 'No collections this month.',
          noCollectionsCustomDateRange: 'No collections match the selected dates.<br>We invite you to check the schedule for another period.',
        },
        warnings: {
          collectionWithoutZone: 'This collection has no zone associated, so it is not visible to citizens on the mobile application.',
          collectionWithoutSlot: 'This collection has no slot associated, so it is not visible to citizens on the mobile application.',
        },
        actions: {
          collectionEdit: 'Edit collection',
          collectionDelete: 'Delete collecte',
          collectionDateEdit: 'Edit collection date',
          collectionDateDelete: 'Delete collection date',
        },
      },
      modals: {
        deleteCollection: {
          title: 'Delete collection',
          warning: {
            title: 'Are you sure you want to delete this collection ?',
            text: 'There is no incoming dates associated to this collection. | Warning, you will delete {count} collection date. | Warning, you will delete {count} collection dates.',
          },
          message:
            'The deletion is irreversible. | The deletion is irreversible. The collection date will be deleted from your administration interface and your citizen application. <br/>The next associated collection dates will no longer be visible to citizens. | The deletion is irreversible. All collection dates will be deleted from your administration interface and your citizen application. <br/>The next associated collection dates will no longer be visible to citizens.',
        },
        deleteCollectionDate: {
          title: 'Delete collection date',
          warning: {
            text: 'Are you sure you want to delete this collection date ?',
          },
          message:
            'The deletion is irreversible. This date will be deleted from your administration interface and your mobile application. It will no longer be visible to citizens.',
        },
      },
    },
    collectionDetail: {
      details: {
        sectionTitle: 'Details',
        garbageTypeSelector: {
          label: 'Garbage types',
          placeholder: 'Select a garbage type',
          errors: {
            required: 'You must select a category.',
          },
        },
        zonesSelector: {
          label: 'Concerned zones',
          errors: {
            required: 'You must select at least one zone.',
            noZonesOptions: 'There is no existing zone for the garbage collection feature. Please create one before continuing.',
          },
        },
      },
      reccurentCollection: {
        sectionTitle: 'Reccurent collection',
        frequencySelector: {
          label: 'Passage',
          options: {
            daily: 'Everyday',
            weekly: 'Every week',
            custom: 'Custom frequency',
          },
        },
        daysSelector: {
          errors: {
            required: 'You must select at least one day.',
          },
        },
        slotSelector: {
          label: 'Slot',
          dayLabel: '{day} slot',
          placeholder: 'Select a slot',
          errors: {
            required: 'You must select a slot',
          },
        },
        dateRangeSelector: {
          from: {
            label: 'From',
            title: 'First collection date',
          },
          to: {
            label: 'To',
            title: 'Last collection date',
          },
        },
        excludedCollectionDates: {
          toggleExcludedCollectionDates: {
            label: 'Exclude dates',
            tooltip: 'This option allows you to exclude certain dates from the collection schedule.',
          },
          dateSelector: {
            label: 'On',
            title: 'Collection date',
          },
          addExcludedCollectionDate: 'Add an excluded date',
          warnings: {
            dateNotPlanned: 'This date is not planned.',
          },
        },
      },
      exceptionnalCollectionDates: {
        sectionTitle: 'Exceptionnal collection',
        sectionSubTitle: 'Exceptionnal collection days will be displayed in purple on the calendar.',
        toggleExceptionnalCollection: {
          label: 'Add an exceptionnal collection',
        },
        slotSelector: {
          label: 'Slot',
          placeholder: 'Select a slot',
          errors: {
            required: 'You must select a slot',
          },
        },
        dateSelector: {
          label: 'On',
          title: 'Collection date',
        },
        addExceptionnalCollectionDate: 'Add a collection date',
        overwriteCheckbox: {
          label: 'Overwrite date already planned',
        },
        warnings: {
          dateAlreadyPlanned: 'This date is already planned, you can keep both or exclude the date from the reccurent collection.',
          dateAlreadyPlannedWithDifferentSlot:
            'This date is already planned with a different slot, you can keep both or exclude the date from the reccurent collection.',
        },
      },
    },
    collectionDateEdit: {
      toasts: {
        passed: {
          title: 'Collection done on {date}.',
        },
        deleted: {
          title: 'Collection date deleted.',
        },
      },
      details: {
        sectionTitle: 'Details',
        garbageTypeSelector: {
          label: 'Garbage types',
          placeholder: 'Select a garbage type',
          errors: {
            required: 'You must select a category.',
          },
        },
        zonesSelector: {
          label: 'Concerned zones',
          errors: {
            required: 'You must select at least one zone',
          },
        },
      },
      collectionDate: {
        sectionTitle: 'Collection date',
        slotsSelector: {
          label: 'Choose a slot',
          placeholder: 'Select a slot',
          errors: {
            required: 'You must select a slot',
          },
        },
        dateSelector: {
          label: 'On',
          title: 'Collection date',
        },
      },
    },
  },
  deposits: {
    addDepositLocation: 'Add an infrastructure',
    addDepositPoint: 'Add a deposit point',
  },
  garbageTypes: {
    emptyList: 'No garbage type found',
    actions: {
      edit: 'Edit the garbage type',
      delete: 'Delete the garbage type',
      resetContent: 'Reset the content',
    },
    modals: {
      addTemplate: {
        title: 'Choose a template',
        dropdownLabel: 'Garbage type',
      },
      deleteType: {
        title: 'Delete the garbage type',
        warning: {
          title: 'Are you sure you want to delete this garbage type ?',
          message:
            'There is no collection associated to this garbage type. | Warning, you will delete {count} associated collection. | Warning, you will delete {count} associated collections.',
        },
        message:
          'The deletion is irreversible. | The deletion is irreversible. The collection associated with this garbage type will be deleted from your administration interface and your citizen application | The deletion is irreversible. All collections associated with this garbage type will be deleted from your administration interface and your citizen application',
      },
    },
    addGarbageType: 'Add a garbage type',
    pickExistingTemplate: 'Choose a garbage type from existing templates',
    createNewType: 'Create a new garbage type',
    form: {
      title: {
        errors: {
          required: 'Please enter a name',
        },
      },
      color: {
        errors: {
          required: 'Please enter a color',
        },
      },
      icon: {
        errors: {
          required: 'Please enter an icon',
        },
      },
      allowedGarbages: {
        label: 'Allowed garbage',
        placeholder: 'List of allowed garbage',
        errors: {
          required: 'Please enter the list of allowed garbage',
        },
      },
      forbiddenGarbages: {
        label: 'Forbidden garbage',
        placeholder: 'List of forbidden garbage',
        errors: {
          required: 'Please enter the list of forbidden garbage',
        },
      },
      description: {
        label: 'Description',
        placeholder: 'Description of the garbage type',
        errors: {
          required: 'Please fill a description for the garbage type',
        },
      },
    },
  },
  sortingGuide: {
    details: {
      title: 'Details',
      forms: {
        title: {
          label: 'Title',
          placeholder: 'Sorting guide title',
          requiredError: 'Please enter a valid name',
        },
        description: {
          label: 'Description',
          placeholder: 'Short description',
        },
      },
    },
    garbageTypes: {
      title: 'Garbage types to display',
      empty: {
        message: "You don't have any waste types to display. <br/>To configure your sorting guide, add at least one type of waste",
        addGarbageType: 'Add a garbage type',
      },
    },
    shortCut: {
      title: 'Shortcut to the sorting guide',
      subTitle: 'By activating this option, users will be able to access the sorting guide from the "Waste collection" feature',
      forms: {
        display: {
          label: 'Display the button',
        },
      },
    },
    preview: {
      title: 'Household waste collection',
    },
  },
  zones: {
    addZone: 'Add a zone',
    modals: {
      delete: {
        warning: {
          title: 'Are you sure you want to delete this zone ?',
          text: 'There is no collection using this zone. | Warning, you will modify {count} collection using this zone. | Warning, you will modify {count} collections using this zone.',
        },
        message:
          'The deletion of this zone is irreversible. | The deletion of this zone is irreversible. The collection associated with this zone will be hidden from your citizen application until a new zone is assigned: | The deletion of this zone is irreversible. All collections associated only with this zone will be hidden from your citizen application until a new zone is assigned:',
      },
    },
  },
  slots: {
    toast:
      'You can rename your slots as you wish.<br>Please note that color selection only applies to your administration interface and is not visible on the application.',
    title: 'Details',
    forms: {
      label: {
        title: 'Title',
        color: 'Color',
      },
      placeholder: {
        title: 'Morning',
        color: 'Select a color',
        slot: 'Select a slot template',
      },
      error: {
        title: 'Please enter a valid title',
      },
      addASlotBtn: 'Add a slot',
      addASlotFromTemplate: 'Add from template',
    },
    deleteSlotModal: {
      title: 'Delete slot',
      warningTitle: 'Are you sure you want to delete this slot ?',
      warningText:
        'There is no date associated to this slot. | Please note that you will be deleting {count} associated date. | Please note that you will be deleting {count} associated dates.',
      message:
        'Deletion is irreversible. | Deletion is irreversible. The collection date associated with this slot will be deleted from your administration interface and your citizen application. | Deletion is irreversible. All collection dates associated with this slot will be deleted from your administration interface and your citizen application.',
    },
    noSlots: 'You have no slots to display.<br> You can create a slot or choose one from the existing templates.',
    addSlotFromTemplate: {
      title: 'Choose a model',
      text: 'Slot',
    },
    addSlot: 'Add a slot',
  },
  toasts: {
    addInfrastructuresText:
      'Add the infrastructures or equipment you wish to include on the waste management page. <br/>You can select existing waste collection facilities or add new addresses.',
  },
};
