import App from '@/App.vue';
import router from '@/router';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import FloatingVue from 'floating-vue';
import { defaultConfig, plugin as formKitPlugin } from '@formkit/vue';
import Notifications from 'notiwind';
import VueTablerIcons from 'vue-tabler-icons';
import PrimeVue from 'primevue/config';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import duration from 'dayjs/plugin/duration';
import VueGtag from 'vue-gtag';
import Vue3Lottie from 'vue3-lottie';
import formKitConfig from '@/formkit/formKitConfig';
import { i18n } from '@/locales';
import { registerLayouts } from '@/layouts/register';
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import '@/assets/styles/tailwind.css';
import 'floating-vue/dist/style.css';
import '@/assets/styles/tooltips.css';
import '@/assets/styles/dropdown.css';
import '@/assets/styles/debug.css';
import '@/assets/styles/forms.css';
import '@/assets/styles/neocity-icons.css';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import NeocityPrime from '@/assets/styles/primevue/presets/neocityprime';
import 'primeicons/primeicons.css';
import '@/assets/styles/calendar.css';
import '@/assets/styles/utils.css';
import 'vue3-lottie/dist/style.css';

import primevueEN from '@/locales/en-US/primevue';
import primevueFR from '@/locales/fr-FR/primevue';

const app = createApp(App);

dayjs.locale(i18n.global.locale.value === 'fr-FR' ? 'fr' : 'en');
dayjs.extend(localizedFormat);
dayjs.extend(duration);

if (import.meta.env.VITE_ISDEV !== 'true') {
  Sentry.init({
    app,
    dsn: 'https://50b59c63c13b4c85ad234ec15d64fa9f@o123514.ingest.sentry.io/4504915499417600',
    environment: import.meta.env.VITE_ISDEV !== 'true' ? 'development' : 'production',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: 0.8,
    debug: false,
  });
}

app.use(
  VueGtag,
  {
    enabled: import.meta.env.VITE_ISDEV !== 'true',
    config: { id: 'G-0JXB82Y6RM' },
  },
  router
);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.use(router);
registerLayouts(app);
app.use(pinia);
app.use(i18n);
app.use(FloatingVue, {
  themes: {
    'action-dropdown': {
      $extend: 'menu',
      placement: 'bottom-end',
      delay: { show: 20, hide: 20 },
      distance: 4,
      triggers: ['click', 'touch'],
      disposeTimeout: 40000,
    },
    picker: {
      $extend: 'dropdown',
      placement: 'bottom',
      delay: { show: 20, hide: 20 },
      distance: 4,
      triggers: ['click', 'touch'],
      disposeTimeout: 40000,
    },
  },
});
app.use(formKitPlugin, defaultConfig(formKitConfig));
app.use(VueTablerIcons);
app.use(PrimeVue, { locale: i18n.global.locale.value === 'fr-FR' ? primevueFR : primevueEN, unstyled: true, pt: NeocityPrime });
app.use(Notifications);
app.use(Vue3Lottie, { name: 'Vue3Lottie' });
app.component('PrismEditor', PrismEditor);
app.component('VueDatePicker', VueDatePicker);

app.mount('#app');
