export default {
  header: {
    dropdownMenu: {
      items: {
        account: 'My account',
        users: 'Users',
        appSettings: 'Application management',
        tutorials: 'Tutorials and guides',
        helpCenter: 'Help center',
      },
    },
  },

  chat: {
    publish: 'Publish the message',
    publishedBy: 'Published by',
  },

  sidebar: {
    category: 'Category',
    creationDate: 'Creation date',
    history: 'History',
    information: 'Information',
    results: 'Voting results',
    status: 'Status',
    votingEndDate: 'Voting end date',
    displayTypes: {
      status: 'the status',
      expireAt: 'the end date',
      themeId: 'the theme',
      parameter: 'the parameter',
      mediaId: 'the image',
    },
  },

  table: {
    columns: {
      title: 'Title',
      name: 'Name',
      description: 'Description',
      color: 'Color',
      results: 'Results',
      votes: 'Votes',
      createdAt: 'Created | Created',
      status: 'Status',
      date: 'Date',
      startDate: 'Start date',
      endDate: 'End date',
      zones: 'Zones',
      icon: 'Icon',
      usedBy: 'Used by',
      actions: 'Actions',
      category: 'Category',
      address: 'Address',
      updatedAt: 'Updated at',
      action: 'Action',
      usedOn: 'Used on',
    },
    filters: {
      titles: {
        status: 'Status filter',
        date: 'Date filter',
        category: 'Category filter',
      },
    },
    status: {
      status_edited_on: 'Status edited on',
    },
  },

  timeline: {
    edit: 'has changed',
    auto_edit: 'has been modified automatically',
    anonymous_edit: 'has been modified by an anonymous user',
    create: 'a créé',
    auto_create: 'was created automatically',
    anonymous_create: 'was created by an anonymous user',
    delete: 'a supprimé',
    auto_delete: 'has been deleted automatically',
    anonymous_delete: 'has been deleted by an anonymous user',
    createdFrom: 'has been created from',
    backOffice: 'the back-office',
    app: 'the mobile application',

    tag: {
      archived: 'Archived',
      inProgress: 'Vote in progress',
      notRetained: 'Not retained',
      pending: 'Pending',
      rejected: 'Rejected',
    },

    types: {
      idea: 'The idea',
    },
  },

  preview: {
    pushNotification: {
      now: 'now',
    },
  },

  richTextEditor: {
    defaultPlaceholder: 'Enter your text here',
    validation: {
      required: 'This field is required',
    },
    undo: {
      tooltip: 'Undo',
    },
    redo: {
      tooltip: 'Redo',
    },
    textFormat: {
      options: {
        heading1: 'Heading 1',
        heading2: 'Heading 2',
        heading3: 'Heading 3',
        heading4: 'Heading 4',
        heading5: 'Heading 5',
        heading6: 'Heading 6',
        paragraph: 'Paragraph',
      },
    },
    textAlign: {
      options: {
        left: 'Left',
        center: 'Center',
        right: 'Right',
        justify: 'Justify',
      },
    },
    bold: {
      tooltip: 'Bold',
    },
    italic: {
      tooltip: 'Italic',
    },
    underline: {
      tooltip: 'Underline',
    },
    strikethrough: {
      tooltip: 'Strikethrough',
    },
    resetFormat: {
      tooltip: 'Reset format',
    },
    unorderedList: {
      tooltip: 'Unordered list',
    },
    orderedList: {
      tooltip: 'Ordered list',
    },
    table: {
      tooltip: 'Insert a table',
    },
    separator: {
      tooltip: 'Insert a separator',
    },
    link: {
      tooltip: 'Insert a link',
      modal: {
        title: 'Insert a link',
        linkTypes: {
          external: 'External link',
          internal: 'Link to a page of the mobile app',
        },
        internalLabel: 'Page of the mobile app',
        labelLabel: 'Label',
        labelHelp: 'The label is the text that will be displayed in the link. If there is no label, the URL will be displayed.',
        urlLabel: 'URL',
        edit: 'Edit',
        validate: 'Insert',
      },
    },
    image: {
      tooltip: 'Insert an image',
      modal: {
        title: 'Insert an image',
        uploadButton: 'Choose a file',
        customDimensions: 'Custom dimensions',
        validate: 'Insert',
      },
    },
    iframe: {
      tooltip: 'Insert an iframe',
      modal: {
        title: 'Insert an iframe',
        srcLabel: 'URL',
        customDimensions: 'Custom dimensions',
        validate: 'Insert',
      },
    },
    video: {
      tooltip: 'Insert a video',
    },
    emoji: {
      tooltip: 'Insert an emoji',
    },
    code: {
      tooltip: 'Edit as code',
    },
  },
  openingHours: {
    labels: {
      open24h: 'Open 24h/24',
      openingHours: 'Opening hours',
    },
    placeholders: {
      description: 'Optional description',
    },
  },
  maps: {
    drawPolygonMap: {
      actions: {
        drawPolygon: 'Draw a polygon',
        deleteSeleted: 'Delete selecteds',
        importZone: 'Import zone',
      },
      legend: {
        currentZone: 'Current zone',
        selectedZones: 'Selected zones',
        existingZones: 'Existing zones',
      },
      tooltips: {
        info: 'Draw a zone by clicking on the <b>Draw a polygon</b> button.</br> Make sure the zone does not overlap an existing zone (represented in gray on the map).',
        valid: 'Congratulations ! Your drawing is valid',
        error: 'Your drawing is invalid. Make sure the new zone does not overlap an existing zone.',
        superAdminFeature: 'This feature is only available for super administrators.',
      },
      errors: {
        noZone: 'Please fill in a zone.',
        polygonLimitReached: 'You have reached the maximum number of polygons. Please delete one before drawing a new one.',
      },
    },
  },
};
