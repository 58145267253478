<template>
  <div v-if="showToast" ref="toast" :class="containerClasses">
    <div v-if="icon" class="flex items-center justify-center" :class="iconClasses">
      <Icon :id="icon" :class="textColorClasses" size="large" />
    </div>
    <div class="relative flex w-full flex-col justify-center gap-0.5" :class="textColorClasses">
      <span v-if="notification.title" :class="titleClasses">{{ notification.title }}</span>
      <p v-if="notification.text" :class="textClasses" v-html="notification.text"></p>
      <slot></slot>
      <Icon
        v-if="notification.closable || closable"
        id="x"
        :size="size"
        class="pointer-events-auto absolute right-0 top-0 cursor-pointer"
        :class="textColorClasses"
        @click="onClose"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import type { PropType } from 'vue';
import { useCookies } from '@vueuse/integrations/useCookies';

const toast = ref<HTMLElement>();

type Notification = {
  group: string;
  type: 'info' | 'success' | 'warning' | 'error' | 'tip';
  title: string;
  text?: string;
  closable?: boolean;
  textCompacted?: boolean;
};

type Closable = {
  identifier: string;
  closeDefinitively: boolean;
};

const emits = defineEmits(['close']);

const props = defineProps({
  notification: {
    type: Object as PropType<Notification>,
    default: () => ({
      group: 'notification',
      type: 'info',
      title: 'Notification',
      text: 'Message par défaut',
      closable: false,
      textCompacted: false,
    }),
    validator: (value: Notification) => {
      if (!value.group) throw new Error('group is required');
      if (!value.type) throw new Error('type is required');
      if (!['info', 'success', 'warning', 'error', 'tip'].includes(value.type)) throw new Error('type does not exist');
      return true;
    },
  },
  size: {
    type: String as () => 'small' | 'medium' | 'large',
    default: 'medium',
  },
  closable: {
    type: [Object, Boolean] as PropType<Closable | boolean>,
    default: false,
    validator: (value: Closable | boolean) => {
      if (typeof value === 'boolean') return true;
      if (!value.identifier) throw new Error('You must specify an unique identifier to close definitively the tooltip');
      return true;
    },
  },
});

const cookies = useCookies([`tooltip_${typeof props.closable !== 'boolean' ? props.closable.identifier : 'default'}`]);

const showToast = computed(() => {
  if (props.closable && typeof props.closable !== 'boolean' && props.closable.identifier) {
    if (cookies.get(`tooltip_${props.closable.identifier}`)) return false;
  }
  return true;
});

const onClose = (): void => {
  if (props.notification.closable) emits('close');
  else if (toast.value && props.closable) {
    toast.value.classList.add('hidden');
    if (typeof props.closable !== 'boolean' && props.closable.identifier && props.closable.closeDefinitively)
      cookies.set(`tooltip_${props.closable.identifier}`, true);
  }
};

const containerClasses = computed(() => ({
  'mb-4 flex w-full ': true,
  'bg-base-light': props.notification.type === 'info',
  'bg-success-light': props.notification.type === 'success',
  'bg-warning-light': props.notification.type === 'warning',
  'bg-danger-light': props.notification.type === 'error',
  'bg-base-basic p-4': props.notification.type === 'tip',
  'p-2.5 rounded-sm': props.size === 'medium',
  'p-4 rounded-md': props.size === 'large',
}));

const iconClasses = computed(() => ({
  'mr-2.5': props.size === 'medium',
  'mr-3': props.size === 'large',
}));

const textColorClasses = computed((): string => {
  switch (props.notification.type) {
    case 'info':
      return 'text-base-basic';
    case 'success':
      return 'text-success-basic';
    case 'warning':
      return 'text-warning-basic';
    case 'error':
      return 'text-danger-basic';
    case 'tip':
      return 'text-white';
    default:
      return 'text-base-basic';
  }
});

const titleClasses = computed(() => ({
  'text-base font-semibold': true,
  'pr-10': props.closable,
}));

const textClasses = computed(() => ({
  'font-normal': true,
  'text-sm': props.size === 'medium',
  'text-base': props.size === 'large',
  'pr-10': props.closable,
  'max-w-[970px]': props.notification.textCompacted,
}));

const icon = computed((): string => {
  switch (props.notification.type) {
    case 'info':
      return 'info-circle';
    case 'success':
      return 'circle-check';
    case 'error':
      return 'alert-circle';
    case 'warning':
      return 'alert-triangle';
    default:
      return '';
  }
});
</script>
