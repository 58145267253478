<template>
  <div class="flex">
    <SideNav />
    <div class="w-screen overflow-hidden">
      <div class="flex flex-col">
        <Header ref="header" />
        <main class="flex h-full flex-col" :style="{ height: bodyHeight + 'px' }">
          <slot></slot>
        </main>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, onUnmounted, provide, defineComponent } from 'vue';
import { useElementBounding, useWindowSize } from '@vueuse/core';

export default defineComponent({
  name: 'DefaultLayout',
  setup() {
    const header = ref(null);
    const headerHeight = useElementBounding(header).height;
    const { width: windowWidth, height: windowHeight } = useWindowSize();
    const bodyHeight = ref(0);

    const isMenuMinified = ref(false);
    const isMenuOpen = ref(false);

    provide('menu', {
      isMenuOpen,
      isMenuMinified,
    });

    const resizeMainContainer = (): void => {
      bodyHeight.value = windowHeight.value - headerHeight.value;
      if (windowWidth.value < 1024) isMenuMinified.value = false;
    };

    onMounted(() => {
      resizeMainContainer();
      window.addEventListener('resize', resizeMainContainer);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', resizeMainContainer);
    });
    return {
      header,
      bodyHeight,
    };
  },
});
</script>
