export default {
  label: {
    anonymousMode: 'Mode anonyme',
    civility: 'Civilité',
    color: 'Couleur',
    description: 'Description',
    email: 'Adresse e-mail',
    phoneNumber: 'Numéro de téléphone',
    firstname: 'Prénom',
    lastname: 'Nom',
    icon: 'Icône',
    mandatoryImage: 'Image obligatoire',
    moderation: 'Modération',
    theme: 'Thématique',
    title: 'Titre',
    type: 'Type',
    voteDuration: 'Durée de vote par défaut',
    image: 'Image',
    from: 'Du',
    to: 'Au',
    dateRange: 'Plage de dates',
    link: 'Lien',
    url: 'URL',
    width: 'Largeur',
    height: 'Hauteur',
    label: 'Libellé',
    category: 'Catégorie',
    address: 'Adresse',
    city: 'Ville',
    zipCode: 'Code postal',
    lat: 'Latitude',
    long: 'Longitude',
    phone: 'Téléphone',
    fax: 'Fax',
    website: 'Site web',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    file: 'Fichier',
    every: 'Tous les',
    repeatEvery: 'Répéter tou(te)s les',
    dates: 'Dates',
  },

  info: {
    mandatoryImage: "Les participants doivent obligatoirement ajouter une image lorsqu'ils soumettent leurs idées.",
    anonymousMode: 'Avec le mode anonyme, les participants soumettent leurs idées sans avoir à saisir leurs informations personnelles.',
    color: 'Couleur',
    icon: 'Icône',
    moderation:
      'Si la modération est activée, vous devez attribuer manuellement le statut “Vote en cours” pour afficher les idées proposées sur l’application.',
    title: 'Titre',
  },

  placeholder: {
    chooseTheme: 'Choisir une thématique',
    chooseCity: 'Choisir une ville',
    chooseType: 'Choisir un type',
    civility: 'Civilité',
    chooseCivility: 'Choisir une civilité',
    description: 'Description',
    descriptionContent: 'Contenu de la description',
    email: "adresse{'@'}email.com",
    firstname: 'Prénom',
    icon: 'Choisir un Icône',
    lastname: 'Nom',
    name: 'Nom',
    theme: 'Thématique',
    title: 'Titre',
    type: 'Type',
    search: 'Rechercher',
    searchEmoji: 'Rechercher un emoji',
    defaultSelect: 'Sélectionner',
  },

  help: {
    remainingCharacters: 'caractère restant | caractères restants',
  },

  units: {
    day: 'jour',
  },

  options: {
    all: 'Tous | Toutes',
    anonymous: 'Anonyme',
    user: 'Utilisateur',
    male: 'Homme',
    female: 'Femme',
    mr: 'Monsieur',
    mrs: 'Madame',
  },

  errors: {
    incomplete: 'Veuillez renseigner tous les champs.',
    hexadecimal: 'Veuillez renseigner un code hexadécimal.',
  },

  empty: {
    noResults: 'Aucun résultat',
  },

  actions: {
    selectAll: 'Tout sélectionner',
    unselectAll: 'Tout désélectionner',
    reset: 'Réinitialiser',
  },
};
