export default {
  navigation: {
    myAccount: 'My account',
    myInformation: 'Profile',
    preferences: 'Preferences',
    notifications: 'Notifications',
    plan: 'Plan',
    rolesAndUsers: 'Roles and users',
    users: 'Users',
    rolesManagement: 'Roles management',
    citiesManagement: 'Cities management',
    appManagement: 'Application management',
    tilesManagement: 'Tiles management',
    tree: 'Tree structure',
    stores: 'Stores',
    legal: 'Legal',
    tutorials: 'Tutorials and guides',
  },
};
