import { generateClasses } from '@formkit/themes';

const textClassification = {
  label: 'block text-sm mb-2 font-semibold text-neutral-black formkit-invalid:text-danger-basic text-left',
  inner:
    'flex bg-neutral-light focus-within:bg-white formkit-invalid:bg-white formkit-disabled:bg-neutral-cloud rounded-sm overflow-hidden border border-neutral-light formkit-disabled:border-neutral-cloud formkit-invalid:border-danger-basic focus-within:border-base-basic relative',
  input:
    'bg-transparent font-regular w-full text-base font-normal p-2 placeholder-neutral-silver text-neutral-black formkit-invalid:text-neutral-black formkit-disabled:text-neutral-grey formkit-disabled:cursor-not-allowed outline-none leading-normal',
  help: 'text-xs text-neutral-black mt-2 font-semibold',
  message: 'text-xs mt-2 text-danger-basic font-semibold',
};

const boxClassification = {
  fieldset: 'rounded-md',
  options: 'flex flex-col gap-3',
  legend: 'block text-sm mb-2 font-semibold text-neutral-black formkit-invalid:text-danger-basic text-left',
  wrapper: 'flex items-center cursor-pointer',
  help: 'text-xs text-neutral-black mt-1 mb-2 font-semibold',
  inner: 'flex items-center',
  input:
    'form-check-input cursor-pointer appearance-none h-4 w-4 border border-neutral-dark rounded-[2px] bg-white checked:bg-base-basic checked:border-transparent focus:outline-none focus:ring-0 transition duration-200',
  label: 'text-sm font-semibold text-neutral-dark formkit-invalid:text-danger-basic ml-2 mb-0 select-none',
  message: 'text-xs mt-2 text-danger-basic font-semibold',
  optionHelp: 'text-xs text-neutral-grey mb-2 font-semibold',
};

const formKitTheme = {
  classes: generateClasses({
    text: textClassification,
    date: {
      ...textClassification,
      input: textClassification.input.concat(' cursor-pointer'),
    },
    'datetime-local': textClassification,
    checkbox: boxClassification,
    colorPicker: {
      label: textClassification.label,
      inner: textClassification.inner,
      help: textClassification.help,
      message: textClassification.message,
      input: textClassification.input,
    },
    iconPicker: {
      wrapper: 'w-full',
      label: textClassification.label,
      help: textClassification.help,
      message: textClassification.message,
      inner: textClassification.inner,
    },
    switchInput: {
      label: textClassification.label,
      inner: textClassification.inner,
      help: textClassification.help,
      input: textClassification.input,
      message: textClassification.message,
    },
    datePicker: {
      label: textClassification.label,
      inner: textClassification.inner.concat(' !overflow-visible w-full'),
      help: textClassification.help,
      input: textClassification.input,
      message: textClassification.message,
    },
    fileUpload: {
      label: textClassification.label,
      help: textClassification.help,
      message: textClassification.message,
    },
    email: textClassification,
    dropdown: {
      label: textClassification.label,
      message: textClassification.message,
    },
    file: {
      label: 'block mb-1 font-bold text-sm',
      inner: 'cursor-pointer',
      input:
        'text-neutral-black text-sm mb-1 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:bg-base-basic file:text-white hover:file:bg-base-basic',
      noFiles: 'block text-neutral-black text-sm mb-1',
      fileItem: 'block flex text-neutral-black text-sm mb-1',
      removeFiles: 'ml-auto text-base-basic text-sm',
    },
    month: textClassification,
    number: textClassification,
    numberWithUnit: textClassification,
    password: textClassification,
    radio: {
      ...boxClassification,
      input:
        "form-check-input cursor-pointer appearance-none h-4 w-4 border border-neutral-dark rounded-full bg-white checked:after:block relative after:content-[''] after:absolute after:w-2 after:h-2 after:hidden after:bg-base-basic checked:border-base-basic after:rounded-full after:overflow-hidden after:top-[50%] after:left-[50%] after:translate-x-[-50%] after:translate-y-[-50%] focus:outline-none focus:ring-0 transition duration-200",
    },
    range: {
      inner: '',
      input: 'form-range appearance-none w-full h-2 p-0 bg-transparent rounded-full focus:outline-none focus:ring-0 focus:shadow-none',
    },
    search: textClassification,
    select: {
      ...textClassification,
      input: textClassification.input.concat(' cursor-pointer mr-2'),
    },
    submit: {},
    tel: textClassification,
    textarea: {
      ...textClassification,
    },
    time: textClassification,
    url: textClassification,
    week: textClassification,
    form: {
      message: textClassification.message.concat(' text-center mt-4'),
    },
  }),
};

export default formKitTheme;
