<template>
  <NotificationGroup group="notification">
    <div class="pointer-events-none fixed inset-0 z-50 flex items-start justify-end p-6 sm:pt-36">
      <div class="w-full max-w-[340px]">
        <Notification
          v-slot="{ notifications, close }"
          enter="transform ease-out duration-300 transition"
          enterFrom="-translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          move="transition duration-500"
          moveDelay="delay-300"
        >
          <div v-for="notification in notifications" :key="notification.id">
            <Toast :notification="notification" @close="close" />
          </div>
        </Notification>
      </div>
    </div>
  </NotificationGroup>
</template>
