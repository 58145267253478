export default {
  accept: 'Oui',
  addRule: 'Ajouter une règle',
  am: 'am',
  apply: 'Appliquer',
  cancel: 'Annuler',
  choose: 'Choisir',
  chooseDate: 'Choisir une date',
  chooseMonth: 'Choisir un mois',
  chooseYear: 'Choisir une année',
  clear: 'Réinitialiser',
  completed: 'Terminé',
  contains: 'Contient',
  custom: 'Personnalisé',
  dateAfter: 'La date est postérieure à',
  dateBefore: 'La date est antérieure à',
  dateFormat: 'dd/mm/yy',
  dateIs: 'La date est',
  dateIsNot: "La date n'est pas",
  dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
  dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
  emptyFilterMessage: 'Aucun résultat',
  emptyMessage: 'Aucune option disponible',
  emptySearchMessage: 'Aucun résultat trouvé',
  emptySelectionMessage: 'Aucun élément sélectionné',
  endsWith: 'Termine par',
  equals: 'Est égal à',
  fileSizeTypes: ['o', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'],
  filter: 'Filtre',
  firstDayOfWeek: 1,
  gt: 'Supérieur à',
  gte: 'Supérieur ou égal à',
  lt: 'Moins de',
  lte: 'Inférieur ou égal à',
  matchAll: 'Tout correspondre',
  matchAny: 'Correspondre à un',
  medium: 'Moyen',
  monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
  monthNamesShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jui', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
  nextDecade: 'Décennie suivante',
  nextHour: 'Heure suivante',
  nextMinute: 'Minute suivante',
  nextMonth: 'Mois suivant',
  nextSecond: 'Seconde suivante',
  nextYear: 'Année suivante',
  noFilter: 'Pas de filtre',
  notContains: 'Ne contient pas',
  notEquals: "N'est pas égal à",
  now: 'Maintenant',
  passwordPrompt: 'Entrez un mot de passe',
  pending: 'En attente',
  pm: 'pm',
  prevDecade: 'Décennie précédente',
  prevHour: 'Heure précédente',
  prevMinute: 'Minute précédente',
  prevMonth: 'Mois précédent',
  prevSecond: 'Seconde précédente',
  prevYear: 'Année précédente',
  reject: 'Non',
  removeRule: 'Supprimer une règle',
  searchMessage: '{0} résultats trouvés',
  selectionMessage: '{0} éléments sélectionnés',
  showMonthAfterYear: false,
  startsWith: 'Commence par',
  strong: 'Fort',
  today: "Aujourd'hui",
  upload: 'Télécharger',
  weak: 'Faible',
  weekHeader: 'Sm',
  aria: {
    cancelEdit: "Annule l'édition",
    close: 'Fermer',
    collapseLabel: 'Effondrement',
    collapseRow: 'Ligne repliée',
    editRow: 'Édite une ligne',
    expandLabel: 'Développer',
    expandRow: 'Ligne dépliée',
    falseLabel: 'Faux',
    filterConstraint: 'Contrainte de filtrage',
    filterOperator: 'Opérateur de filtrage',
    firstPageLabel: 'Première page',
    gridView: 'Vue en grille',
    hideFilterMenu: 'Masque le menu des filtres',
    jumpToPageDropdownLabel: 'Aller à la page',
    jumpToPageInputLabel: 'Aller à la page',
    lastPageLabel: 'Dernière page',
    listView: 'Vue en liste',
    moveAllToSource: 'Tout déplacer vers la source',
    moveAllToTarget: 'Tout déplacer vers la cible',
    moveBottom: 'Déplacer tout en bas',
    moveDown: 'Déplacer vers le bas',
    moveTop: 'Déplacer tout en haut',
    moveToSource: 'Déplacer vers la source',
    moveToTarget: 'Déplacer vers la cible',
    moveUp: 'Déplacer vers le haut',
    navigation: 'Navigation',
    next: 'Suivant',
    nextPageLabel: 'Page suivante',
    nullLabel: 'Non défini',
    otpLabel: 'Veuillez saisir le caractère de mot de passe à usage unique {0}',
    pageLabel: 'Page {page}',
    passwordHide: 'Masquer le mot de passe',
    passwordShow: 'Montrer le mot de passe',
    previous: 'Précédent',
    previousPageLabel: 'Page précédente',
    rotateLeft: 'Tourner vers la gauche',
    rotateRight: 'Tourner vers la droite',
    rowsPerPageLabel: 'Lignes par page',
    saveEdit: "Sauvegarde l'édition",
    scrollTop: 'Défiler tout en haut',
    selectAll: 'Tout sélectionner',
    selectLabel: 'Sélectionner',
    selectRow: 'Ligne sélectionnée',
    showFilterMenu: 'Montre le menu des filtres',
    slide: 'Diapositive',
    slideNumber: '{slideNumber}',
    star: '1 étoile',
    stars: '{star} étoiles',
    trueLabel: 'Vrai',
    unselectAll: 'Tout désélectionner',
    unselectLabel: 'Désélectionner',
    unselectRow: 'Ligne désélectionnée',
    zoomImage: "Zoomer l'image",
    zoomIn: 'Zoomer',
    zoomOut: 'Dézoomer',
  },
};
