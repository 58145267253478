import { defineStore } from 'pinia';

import { axiosNeocityInstance } from '@/api/axios';

import { useUser } from '@/stores/user';

import * as Sentry from '@sentry/vue';

interface State {
  activeSurveysCount: number | null;
  activeSurveysCountLastUpdate: number | null;
}

export const useSurveys = defineStore({
  id: 'surveys',
  state: (): State => ({
    activeSurveysCount: null,
    activeSurveysCountLastUpdate: null,
  }),
  getters: {
    clientId(): string | undefined {
      const userStore = useUser();
      return userStore.$state.clientId;
    },
  },
  actions: {
    getActiveSurveysCount(): Promise<number> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .get('/survey/count', {
            params: { where: { client: this.clientId, status: 'current' } },
          })
          .then((response) => {
            this.activeSurveysCount = response.data.count;
            this.activeSurveysCountLastUpdate = Date.now();
            resolve(response.data.count);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
  },
  persist: true,
});
