import { defineStore } from 'pinia';

import * as Sentry from '@sentry/vue';

import { useUser } from '@/stores/user';

import { axiosNeocityInstanceV3 } from '@/api/axios';
import { GarbageCollection, GarbageCollectionDate, GarbageCollectionDateForm, Slot, GarbageType } from '@/models/garbage.model';
import { WeekDay } from '@/models/common.model';

interface State {
  garbageTypesTemplates: GarbageType[];
  slots: Slot[];
}

export const useGarbage = defineStore({
  id: 'garbage',
  state: (): State => ({
    garbageTypesTemplates: [] as GarbageType[],
    slots: [] as Slot[],
  }),
  getters: {
    clientId(): string | undefined {
      const userStore = useUser();
      return userStore.$state.clientId;
    },
  },
  actions: {
    // Collections
    getCollections(page = 1, limit = 30, filters?: any, sort?: any): Promise<GarbageCollection[]> {
      const params = {
        page,
        limit,
        sort: JSON.stringify(sort.reduce((acc, { key, value }) => ({ ...acc, [key]: value.toLowerCase() }), {})),
        where: JSON.stringify({ ...filters, deleted: false }),
      };
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .get(`/clients/${this.clientId}/garbage-collections`, { params })
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
    getCollection(id: string): Promise<GarbageCollection> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .get(`/clients/${this.clientId}/garbage-collections/${id}`)
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
    getCollectionDatesPreview(collectionSettings: GarbageCollection): Promise<{ date: string; slot: string }[]> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .post(`/clients/${this.clientId}/garbage-collections/date`, collectionSettings)
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
    createCollection(fields: GarbageCollection): Promise<GarbageCollection> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .post(`/clients/${this.clientId}/garbage-collections`, fields)
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
    updateCollection(collectionId: string, fields: Partial<GarbageCollection>): Promise<GarbageCollection> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .patch(`/clients/${this.clientId}/garbage-collections/${collectionId}`, fields)
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
    deleteCollection(id: string): Promise<void> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .delete(`/clients/${this.clientId}/garbage-collections/${id}`)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    // Collection Dates
    getCollectionDates(page = 1, limit = 30, filters?: any, sort?: any): Promise<GarbageCollectionDate[]> {
      const params = {
        page,
        limit,
        sort: JSON.stringify(sort.reduce((acc, { key, value }) => ({ ...acc, [key]: value.toLowerCase() }), {})),
        where: JSON.stringify({ ...filters, deleted: false }),
      };
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .get(`/clients/${this.clientId}/garbage-collection-dates`, { params })
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
    getCollectionDate(id: string): Promise<GarbageCollectionDate> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .get(`/clients/${this.clientId}/garbage-collection-dates/${id}`)
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
    updateCollectionDate(fields: GarbageCollectionDateForm): Promise<GarbageCollectionDate> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .patch(`/clients/${this.clientId}/garbage-collection-dates/${fields._id}`, fields)
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
    deleteCollectionDate(id: string): Promise<void> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .delete(`/clients/${this.clientId}/garbage-collection-dates/${id}`)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
    countCollectionDates(filters?: any): Promise<number> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .get(`/clients/${this.clientId}/garbage-collection-dates/count`, { params: { where: JSON.stringify(filters) } })
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
    collectionHasSlot(collection: GarbageCollection): boolean {
      const { frequency } = collection;
      const slotsIds = this.slots.map((slot) => slot._id);
      if (frequency === 'everyDay') return slotsIds.includes(collection.planning.everyDay?.slot);
      if (frequency === 'everyWeek' && collection?.planning?.everyWeek?.slots)
        return !Object.entries(collection?.planning?.everyWeek?.slots)
          .filter((entry) => collection?.planning?.everyWeek?.days?.includes(entry[0] as WeekDay))
          .every((entry) => !slotsIds.includes(entry[1]));
      if (frequency === 'custom') {
        if (collection.planning.custom?.repeatEveryBasis === 'daily') return slotsIds.includes(collection.planning.custom.slot);
        if (collection.planning.custom?.repeatEveryBasis === 'weekly' && collection?.planning?.custom?.slots)
          return !Object.entries(collection?.planning?.custom?.slots)
            .filter((entry) => collection?.planning?.custom?.days?.includes(entry[0] as WeekDay))
            .every((entry) => !slotsIds.includes(entry[1]));
      }
      return false;
    },

    // Garbage Types
    getGarbageTypes(page = 1, limit = 30, filters?: string): Promise<GarbageType[]> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .get(`/clients/${this.clientId}/garbage-types?page=${page}&limit=${limit}&sort={"createdAt":"desc"}&where=${filters}`)
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    getGarbageType(garbageTypeId: string): Promise<GarbageType> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .get(`/clients/${this.clientId}/garbage-types/${garbageTypeId}`)
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    createGarbageType(fields: Partial<GarbageType>): Promise<GarbageType> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .post(`/clients/${this.clientId}/garbage-types`, fields)
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    patchGarbageType(garbageTypeId: string, fields: Partial<GarbageType>): Promise<GarbageType | undefined> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .patch(`/clients/${this.clientId}/garbage-types/${garbageTypeId}`, fields)
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    deleteGarbageType(garbageTypeId: string): Promise<GarbageType | undefined> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .delete(`/clients/${this.clientId}/garbage-types/${garbageTypeId}`)
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    getGarbageTypesTemplates(): Promise<GarbageType[]> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .get(`/clients/${this.clientId}/garbage-types/template`)
          .then((response) => {
            if (!response.data) return;
            this.garbageTypesTemplates = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    // Slots
    getSlots(): Promise<Slot[]> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .get(`/clients/${this.clientId}/garbage-slots`)
          .then((response) => {
            if (!response.data) return;
            this.slots = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    postSlot(fields: Slot): Promise<Slot> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .post(`/clients/${this.clientId}/garbage-slots`, fields)
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    patchSlot(slotId: string, fields: Slot): Promise<Slot | undefined> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .patch(`/clients/${this.clientId}/garbage-slots/${slotId}`, fields)
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    deleteSlot(slotId: string): Promise<void> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .delete(`/clients/${this.clientId}/garbage-slots/${slotId}`)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    getGarbageSlotsTemplates(): Promise<Slot[]> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .get(`/clients/${this.clientId}/garbage-slots/templates`)
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    // Feature access
    userCanAccessSubfeature(subfeature: 'collections' | 'depositPoints' | 'settings'): boolean {
      const userStore = useUser();
      const rolesByPass = ['superadmin', 'admin', 'organisation.owner', 'organisation.admin'];
      if (rolesByPass.some((role) => userStore.$state.user?.role.includes(role))) return true;
      return userStore.$state.user?.garbageAccess?.[subfeature] || false;
    },
  },
  persist: true,
});
