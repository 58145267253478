<!-- This component is used to display a tag indicating the current environment (local or dev) in the header of the application. -->
<template>
  <Tag v-if="isLocalEnv || isDevEnv" :color="isLocalEnv ? 'green' : 'apricot'">
    <div class="flex items-center gap-2">
      <Icon :id="isLocalEnv ? 'device-desktop' : 'test-pipe'" />
      {{ isLocalEnv ? $t('common.environments.local') : `${$t('common.environments.dev')} ${isDevPreview ? ' - Preview' : ' - Dev'}` }}
    </div>
  </Tag>
</template>

<script lang="ts" setup>
const isLocalEnv = window.location.hostname === 'localhost';
const isDevEnv = import.meta.env.VITE_ISDEV === 'true';

const isDevPreview = import.meta.env.VITE_ISDEV === 'true' && import.meta.env.VITE_DEV_CONTEXT === 'preview';
</script>
