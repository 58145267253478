export default {
  add_idea: 'Ajouter une idée',
  add_category: 'Ajouter une thématique',
  create_category: 'Créer une thématique',
  add_recipient: 'Ajouter un destinataire',
  save_category: 'Enregistrer la thématique',
  save_idea: "Enregistrer l'idée",
  without_category: 'Sans thématique',
  theIdea: "l'idée",
  generate_IframeCode: 'Générer le code de l’Iframe',
  status: {
    pending: 'En attente',
    voting: 'Vote en cours',
    studying: "En cours d'étude",
    notRetained: 'Non retenue',
    inProgress: 'En cours de réalisation',
    realised: 'Réalisée',
    rejected: 'Rejetée',
    archived: 'Archivée',
  },
  vote: {
    voting: 'Vote en cours',
    cancelled: 'Vote annulé',
  },
  filters: {
    show_only_pending: 'Afficher uniquement les idées "En attente"',
  },
  errors: {
    get_ideas_failed: 'Impossible de récupérer les idées',
  },
  empty: {
    no_messages: 'Aucun message publié pour le moment.',
    no_results: 'Aucune idée pour le moment.',
    no_results_with_filters: 'Aucune idée ne correspond à votre recherche.',
    no_results_with_pending_filter: 'Aucune idée avec le statut "En attente".',
    no_recipients: 'Aucun destinataire pour le moment.',
    no_default_recipients: 'Aucun destinataire par défaut pour le moment.',
    no_thematics: 'Aucune thématique pour le moment.',
    no_thematics_with_filters: 'Aucune thématique ne correspond à votre recherche.',
  },
  modals: {
    status: {
      checkbox: 'Avertir le participant du changement de statut',
      desc: 'Vous pouvez <strong>avertir le participant</strong> du changement de statut via une notification en cochant la case ci-dessous. ',
      title: 'Changement de statut',
    },
    delete_category: {
      title: 'Supprimer la thématique',
      text: 'Voulez-vous supprimer la thématique *{theme}* ?',
      warning:
        "0 |{count} idée associée à cette thématique sera conservée mais n'aura plus de thématique. |{count} idées associées à cette thématique seront conservées mais n'auront plus de thématique.",
    },
    iframe: {
      tip: 'Nous vous invitons à ajouter un champ “e-mail” ou “numéro de téléphone” nous permettant de vérifier les doublons. Dans le cas contraire, vous vous exposez au risque de participations multiples.',
      htmlcode: 'Code HTML de la participation citoyenne',
      backgroundcolor: 'Couleur de l’arrière-plan',
    },
  },
};
