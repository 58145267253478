export default {
  cancel: 'Cancel',
  delete: 'Delete',
  duplicate: 'Duplicate',
  login: 'Login',
  logout: 'Logout',
  save: 'Save',
  update: 'Update',
  continue: 'Continue',
  validate: 'Validate',
  back: 'Back',
  createAndAssign: 'Create and assign',
  choose: 'Choose',
  add: 'Add',
  import: 'Import',
  retry: 'Retry',
};
