<template>
  <div class="mx-auto w-full overflow-hidden rounded-sm">
    <Disclosure>
      <DisclosureButton
        class="flex w-full items-center justify-between rounded-sm p-4 hover:bg-white/[.1]"
        :class="{ '!bg-white/[.2]': (isActive && !subMenu.length) || (isMenuMinified && isActive), '!w-fit !p-4': isMenuMinified }"
        @click="onLinkClick"
      >
        <div class="flex items-center text-left text-white">
          <Icon v-if="icon" :id="icon" size="large" />
          <span v-show="!isMenuMinified" class="ml-4 text-base font-semibold">
            <slot />
          </span>
        </div>
        <VTooltip v-if="external && !isMenuMinified" :placement="'top-start'" :distance="16" :triggers="['hover', 'click', 'touch']">
          <Icon id="external-link" class="text-white" />
          <template #popper>
            <div class="max-w-[260px]">{{ $t('redirection.external_link_tooltip') }}</div>
          </template>
        </VTooltip>
        <VTooltip v-else-if="!isMenuMinified && superAdmin" :placement="'top-start'" :distance="16" :triggers="['hover', 'click', 'touch']">
          <Icon id="shield-bolt" class="text-white" />
          <template #popper>
            <div class="max-w-[260px]">{{ $t('menu.tooltips.superAdminOnly') }}</div>
          </template>
        </VTooltip>
        <Icon v-else-if="subMenu.length && !isMenuMinified" :id="isOpen ? 'chevron-up' : 'chevron-down'" class="text-white" />
      </DisclosureButton>
      <div v-show="isOpen && !isMenuMinified" class="mt-2">
        <DisclosurePanel v-if="subMenu.length" static class="disclosurePanel text-sm font-semibold text-white">
          <ul role="list" class="flex flex-col gap-y-2">
            <template v-for="item in subMenu" :key="item.name">
              <li v-if="item?.validate()" class="flow-root">
                <router-link
                  :to="item.to"
                  class="flex items-center rounded-sm px-4 py-2.5 text-sm font-semibold text-white transition duration-150 ease-in-out hover:bg-white/[.1]"
                  @click="isMenuOpen = false"
                >
                  {{ $t(item.name) }}
                </router-link>
              </li>
            </template>
          </ul>
        </DisclosurePanel>
      </div>
    </Disclosure>
  </div>
</template>

<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { useRoute, useRouter } from 'vue-router';
import { computed, onBeforeMount, ref, inject } from 'vue';
import type { Ref } from 'vue';
import { ISubMenuItem } from '@/models/menu.model';

const currentRoute = useRoute();
const router = useRouter();

const props = defineProps({
  icon: {
    type: String,
    default: 'home',
  },
  to: {
    type: String,
    default: null,
  },
  external: {
    type: String,
    default: null,
  },
  subMenu: {
    type: Array as () => ISubMenuItem[],
    default: () => [],
  },
  defaultChildren: {
    type: Number,
    default: 0,
  },
  superAdmin: {
    type: Boolean,
    default: false,
  },
});

const { isMenuOpen, isMenuMinified } = inject<{ isMenuOpen: Ref<boolean>; isMenuMinified: Ref<boolean> }>('menu');

const isOpen = ref(false);

const isActive = computed(() => {
  if (props.external || !currentRoute) return false;
  if (currentRoute?.matched[0]?.path?.includes(props.to)) return true;
  return false;
});

const onLinkClick = (): void => {
  if (!props) return;
  if (props.external) window.location.href = props.external;
  else if (props.to) {
    isMenuOpen.value = false;
    router.push({ path: props.to });
  } else if (props.subMenu.length) {
    if (isMenuMinified.value) isOpen.value = true;
    else isOpen.value = !isOpen.value;
    if (isMenuMinified.value) router.push({ path: props.subMenu[props.defaultChildren].to });
    isMenuMinified.value = false;
  }
};

const isOpenAtStart = (): boolean => {
  if (!props.subMenu.length || !currentRoute) return false;
  return props.subMenu.some((item) => currentRoute.path.includes(item.to));
};

onBeforeMount(() => {
  isOpen.value = isOpenAtStart();
});
</script>

<style>
.disclosurePanel .router-link-active {
  @apply !bg-white/[.2] text-white;
}
</style>
