import { createI18n } from 'vue-i18n';
import messagesFR from '@/locales/fr-FR';
import messagesEN from '@/locales/en-US';

export const i18n = createI18n({
  legacy: false,
  locale: 'fr-FR',
  fallbackLocale: 'en-US',
  messages: {
    'fr-FR': messagesFR,
    'en-US': messagesEN,
  },
});

export const i18nt = i18n.global.t;
