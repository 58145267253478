import Axios from 'axios';

export const axiosNeocityInstance = Axios.create({
  baseURL: import.meta.env.VITE_APP_APIV2_URL,
  headers: { 'X-API-Version': '2.33', allclients: false },
  timeout: 600000,
});

axiosNeocityInstance.interceptors.request.use(
  (config) => {
    const userStorage: string | null = localStorage.getItem('user');
    const parsedUserStorage = JSON.parse(userStorage as string);
    const authToken = parsedUserStorage ? parsedUserStorage.authToken : '';
    const userRoles = parsedUserStorage?.user?.role || [];
    const newConfig = config;
    if (authToken) newConfig.headers.Authorization = authToken;
    if (userRoles && ['organisation.owner', 'superadmin'].some((role) => userRoles.includes(role))) newConfig.headers.allclients = true;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosNeocityInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export const axiosNeocityInstanceV3 = Axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    version: 1,
  },
  timeout: 600000,
});

axiosNeocityInstanceV3.interceptors.request.use(
  (config) => {
    const userStorage: string | null = localStorage.getItem('user');
    const parsedUserStorage = JSON.parse(userStorage as string);
    const authToken = parsedUserStorage ? parsedUserStorage.authToken : '';
    const newConfig = config;
    if (authToken) newConfig.headers.Authorization = authToken;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosNeocityInstanceV3.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);
