<template>
  <VMenu :triggers="['touch', 'hover']" theme="action-dropdown">
    <slot />
    <template #popper="{ hide }">
      <div
        class="relative flex w-full flex-col items-start gap-y-2 overflow-hidden rounded-sm border border-base-soft bg-white p-2"
        :style="{ width }"
      >
        <template v-for="item in items" :key="item.label">
          <template v-if="!item.hidden">
            <div v-if="item?.isSeparator" class="h-[1px] w-full border-b border-base-soft"></div>
            <div
              v-else
              class="flex w-full flex-row items-center gap-4 p-2"
              :class="{ 'cursor-pointer': !item.disabled, 'cursor-not-allowed': item.disabled }"
              @click="onClick(item, hide)"
            >
              <Icon :id="item.iconId" :class="getColorClass(item)" size="large" :stroke="1" />
              <div v-if="item.label" class="text-sm" :class="getColorClass(item)">{{ $t(item.label) }}</div>
            </div>
          </template>
        </template>
      </div>
    </template>
  </VMenu>
</template>

<script lang="ts" setup>
import type { DropdownMenuItem } from '@/models/organisms.model';
import { PropType } from 'vue';

defineProps({
  items: {
    type: Array as PropType<DropdownMenuItem[]>,
    required: true,
  },
  width: {
    type: String,
    default: '240px',
  },
});

const emit = defineEmits(['itemClicked']);

const getColorClass = (item: DropdownMenuItem): string => {
  if (item.disabled) return 'text-neutral-grey';
  if (!item.color) return 'text-neutral-dark';
  return `text-${item.color}`;
};

const onClick = (item: DropdownMenuItem, hide): void => {
  if (item.disabled || item.isSeparator) return;
  if (item.callback) item.callback();
  if (hide) hide();
  emit('itemClicked', item);
};
</script>
