export default {
  actions: 'Actions',
  activated: 'Activé',
  deactivated: 'Désactivé',
  open: 'Ouvert',
  closed: 'Fermé',
  displayed: 'Affiché',
  hidden: 'Caché',
  firstname: 'Prénom',
  lastname: 'Nom',
  user: 'Utilisateur',
  weight: 'Poids',
  undefined: 'Indéfini | Indéfinie',

  states: {
    default: 'Par défaut',
    custom: 'Personnalisé',
  },

  civility: {
    female: 'Madame',
    male: 'Monsieur',
  },

  userType: {
    admin: 'Administrateur',
    anonymous: 'Anonyme',
    user: 'Utilisateur',
  },

  days: {
    monday: 'Lundi',
    1: 'Lundi',
    tuesday: 'Mardi',
    2: 'Mardi',
    wednesday: 'Mercredi',
    3: 'Mercredi',
    thursday: 'Jeudi',
    4: 'Jeudi',
    friday: 'Vendredi',
    5: 'Vendredi',
    saturday: 'Samedi',
    6: 'Samedi',
    sunday: 'Dimanche',
    0: 'Dimanche',
  },

  environments: {
    local: 'Environnement local',
    dev: 'Environnement de développement',
  },

  profile: 'Profil',

  settings: 'Paramètres',

  helpCenter: "Centre d'aide",

  platforms: {
    android: 'ANDROID',
    ios: 'IOS',
    web: 'Web',
  },

  CGUAppleBanner: {
    title: 'IMPORTANT - Mise à jour des CGU de votre compte Apple Developer',
    message:
      "Il vous est primordial d'accepter ces nouvelles CGU afin que votre application mobile continue d'être disponible sur l'App Store, et pour que nous puissions réaliser toutes les mises à jour nécessaires au bon fonctionnement de votre application ! <br/>Afin d'accepter ces nouvelles CGU :<br/><ul><li>- Connectez-vous à votre compte Apple Developer via le lien suivant: <a href='https://developer.apple.com/account' style='text-decoration: underline;'>https://developer.apple.com/account</a></li><li>- Cliquez sur le bouton “Review”, puis sur “Accept”</li><li>- Et voilà !</li></ul><br/><i>(Veuillez ne pas tenir compte de cette information si vous avez déjà accepté les nouvelles CGU.)</i><br/>Pour rappel, votre collectivité est responsable et propriétaire de son compte Apple Developer. Neocity n'a pas les droits pour accepter ces nouvelles CGU.<br/>Pour toute question, nous vous remercions de bien vouloir contacter votre Chef.fe de projet.",
  },

  clientStatus: {
    client: 'Client',
    prospect: 'Prospect',
    archived: 'Archivé',
    test: 'Test',
    churned: 'Résilié',
    unknown: 'Inconnu',
  },
  fancyColors: {
    purple: 'Violet',
    cyan: 'Turquoise',
    aqua: 'Bleu clair',
    blue: 'Bleu azur',
    navy: 'Bleu marine',
    grey: 'Gris',
    apricot: 'Orange',
    pink: 'Rose',
    red: 'Rouge',
    mustard: 'Jaune',
    black: 'Noir',
    brown: 'Marron',
    green: 'Vert',
  },
};
