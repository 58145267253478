import { defineStore } from 'pinia';

import { axiosNeocityInstance } from '@/api/axios';

import { useUser } from '@/stores/user';

import * as Sentry from '@sentry/vue';

interface State {
  reportsCountByStatus: {
    finished: number | null;
    open: number | null;
    pending: number | null;
    rejected: number | null;
  };
  reportsCountByStatusLastUpdate: number | null;
}

export const useReports = defineStore({
  id: 'reports',
  state: (): State => ({
    reportsCountByStatus: {
      finished: null,
      open: null,
      pending: null,
      rejected: null,
    },
    reportsCountByStatusLastUpdate: null,
  }),
  getters: {
    clientId(): string | undefined {
      const userStore = useUser();
      return userStore.$state.clientId;
    },
  },
  actions: {
    getReportsCount(status: string): Promise<number> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .get('/signalements/count', { params: { where: { clientId: this.clientId, status } } })
          .then((response) => resolve(response.data.count))
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
    async getReportsCountByStatus(): Promise<void> {
      return Promise.all(
        Object.keys(this.$state.reportsCountByStatus).map(async (status: string) => {
          const count = await this.getReportsCount(status);
          this.reportsCountByStatus[status] = count;
        })
      )
        .then(() => {
          this.reportsCountByStatusLastUpdate = Date.now();
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    },
  },
  persist: true,
});
