import { defineStore } from 'pinia';

import { axiosNeocityInstance } from '@/api/axios';

import { useUser } from '@/stores/user';
import * as Sentry from '@sentry/vue';

import { Notification, NotificationPayload, AudiencePayload } from '@/models/notifications.model';
import { AxiosError } from 'axios';

interface State {
  scheduledNotificationsCount: number | null;
  scheduledNotificationsLastUpdate: number | null;
}

export const useNotifications = defineStore({
  id: 'notifications',
  state: (): State => ({
    scheduledNotificationsCount: null,
    scheduledNotificationsLastUpdate: null,
  }),
  getters: {
    clientId(): string | undefined {
      const userStore = useUser();
      return userStore.$state.clientId;
    },
    userId(): string | undefined {
      const userStore = useUser();
      return userStore.$state.user?.id;
    },
  },
  actions: {
    postNotification(notification: NotificationPayload): Promise<Notification> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .post('/notification/push/', notification)
          .then((response) => resolve(response.data))
          .catch((error: Error | AxiosError) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    postRecurrentNotification(notification: NotificationPayload): Promise<Notification> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .post('/notification/push/recurrent/', notification)
          .then((response) => resolve(response.data))
          .catch((error: Error | AxiosError) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    getNotifications(limit = 30, skip = 0, order = 'desc', filters?: unknown, searchInput?: string): Promise<Notification[]> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .get(`/push/`, {
            params: {
              filter: {
                where: {
                  client: this.clientId,
                  ...filters,
                  or: [{ 'pushData.title': { like: searchInput, options: 'i' } }, { 'pushData.message': { like: searchInput, options: 'i' } }],
                },
                order: order === 'desc' ? 'sendAt DESC' : 'sendAt ASC',
                limit,
                skip,
              },
            },
          })
          .then((response) => resolve(response.data))
          .catch((error: Error | AxiosError) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    getNotification(id: string): Promise<Notification> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .get(`/push/findOne`, {
            params: {
              filter: {
                where: {
                  client: this.clientId,
                  id,
                },
              },
            },
          })
          .then((response) => resolve(response.data))
          .catch((error: Error | AxiosError) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    searchNotifications(searchInput: string): Promise<Notification[]> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .get(`/push/`, {
            params: {
              filter: {
                where: {
                  client: this.clientId,
                  or: [{ 'pushData.title': { like: searchInput, options: 'i' } }, { 'pushData.message': { like: searchInput, options: 'i' } }],
                },
                fields: {
                  id: true,
                  pushData: true,
                  sendAt: true,
                },
                order: 'sendAt DESC',
              },
            },
          })
          .then((response) => resolve(response.data))
          .catch((error: Error | AxiosError) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    patchNotification(notificationId: string, data: Partial<Notification>): Promise<Notification> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .patch(`/push/${notificationId}`, { ...data })
          .then((response) => resolve(response.data))
          .catch((error: Error | AxiosError) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    patchRecurrentNotificationsGroup(recurrentId: string, data: NotificationPayload): Promise<Notification> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .post(`/notification/update/clientId/${this.clientId}/recurrentId/${recurrentId}`, { ...data })
          .then((response) => resolve(response.data))
          .catch((error: Error | AxiosError) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    duplicateNotification(notificationId: string): Promise<Notification | undefined> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .get(`/push/${notificationId}`)
          .then((response) => {
            if (!response.data) return;
            if (response.data.id) delete response.data.id;
            if (response.data.createdAt) delete response.data.createdAt;
            if (response.data.updatedAt) delete response.data.updatedAt;
            if (response.data.updatedAt) delete response.data.updatedAt;
            if (response.data.count) delete response.data.count;
            if (response.data.open) delete response.data.open;
            if (response.data.hide) response.data.hide = false;
            if (response.data.userId) response.data.userId = this.userId;
            if (response.data.status) response.data.status = 'pending';
            this.postNotification(response.data)
              .then((notification) => resolve(notification))
              .catch((error: Error | AxiosError) => reject(error));
          })
          .catch((error: Error | AxiosError) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    deleteNotification(notificationId: string): Promise<void> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .delete(`/push/${notificationId}`)
          .then((response) => resolve(response.data))
          .catch((error: Error | AxiosError) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    deleteRecurrentNotificationsGroup(recurrentId: string): Promise<void> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .post(`/notification/delete/clientId/${this.clientId}/recurrentId/${recurrentId}`)
          .then((response) => resolve(response.data))
          .catch((error: Error | AxiosError) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    cancelNotification(notificationId: string): Promise<void> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .post(`/notification/cancel/${notificationId}`)
          .then((response) => resolve(response.data))
          .catch((error: Error | AxiosError) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    cancelRecurrentNotificationsGroup(recurrentId: string): Promise<void> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .post(`/notification/cancel/clientId/${this.clientId}/recurrentId/${recurrentId}`)
          .then((response) => resolve(response.data))
          .catch((error: Error | AxiosError) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    countNotifications(filters: any, searchInput?: string): Promise<number> {
      const params = { where: { client: this.clientId, ...filters } };
      if (searchInput)
        params.where.or = [{ 'pushData.title': { like: searchInput, options: 'i' } }, { 'pushData.message': { like: searchInput, options: 'i' } }];
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .get(`/push/count`, { params })
          .then((response) => resolve(response.data.count))
          .catch((error: Error | AxiosError) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    getAudience(params: AudiencePayload): Promise<{
      count: number;
      total: number;
    }> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .post('/firebase_device/audience', {
            ...params,
            clientId: this.clientId,
          })
          .then((response) => resolve(response.data))
          .catch((error: Error | AxiosError) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
  },
  persist: true,
});
