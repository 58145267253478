export default {
  send_notification: 'Envoyer une notification',
  blocks: {
    messageTitle: 'Votre message',
    categoriesTitle: "À qui s'adresse cette notification",
    categoriesText: 'Les abonnés aux catégories sélectionnées recevront la notification',
    targetTitle: 'Page cible',
    targetText: 'Choisissez la page de destination au clic sur la notification',
    sendingDateTitle: "Date d'envoi",
    sendingDateText: 'Envoyez maintenant ou programmez votre envoi',
  },
  tooltips: {
    categories:
      "L'audience correspondant aux catégories sélectionnées s'affiche à droite de l'écran. Il s'agit du nombre d'utilisateurs abonnés aux catégories sélectionnées.",
    target: 'Vous pouvez sélectionner une page existante parmis les types proposés ou ajouter un lien externe.',
    date: "Vous pouvez choisir d'envoyer la notification maintenant, la programmer pour une date ultérieure ou définir une récurrence.",
    estimation: "Vous allez atteindre jusqu'à {estimation} utilisateurs de votre application avec les paramètres que vous avez choisis !",
    restrictedCategory: 'Cette catégorie est restreinte. Seuls les utilisateurs du(des) profil(s) "{profiles}" recevront la notification.',
    openingRate:
      "Cette donnée prend uniquement en compte les clics sur la bannière de notification. Les ouvertures depuis le centre de notifications de l'application ne sont pas comptabilisés.",
  },
  forms: {
    placeholder: {
      search: 'Rechercher ...',
      notificationTitle: 'Titre de la notification',
      notificationMessage: 'Contenu de la notification',
      news: "Liste d'actualités",
      agenda: "Liste d'événements",
      page: 'Liste de rubriques',
      sendingDate: "Date de l'envoi",
      sendingHour: "Heure de l'envoi",
      send: 'Envoyer',
      sendEvery: 'Envoyer tous les',
      at: 'À',
      from: 'Du',
      until: "Jusqu'au",
      lastReccurence: 'Dernière répétition',
    },
    label: {
      notificationTitle: 'Titre',
      notificationMessage: 'Message',
      createdBy: 'Créée par',
      news: 'Sélectionnez une actualité',
      agenda: 'Sélectionnez un événement',
      page: 'Sélectionnez une rubrique',
      external: "Entrez l'adresse de la page",
      notificationSent: 'Notifications envoyées',
      notificationOpened: 'Notifications ouvertes',
      notificationOpeningRate: "Taux d'ouverture",
      customisedFrequency: 'Fréquence personnalisée',
      selectedDates: 'Dates sélectionnées',
      categories: 'Catégories',
      profiles: 'Profils',
      audienceEstimation: 'Estimation de votre audience',
    },
    errors: {
      titleRequired: 'Vous devez renseigner un titre',
      messageRequired: 'Vous devez renseigner un message',
      categoriesRequired: "Vous devez sélectionner au moins une catégorie d'abonnés",
      targetRequired: 'La catégorie sélectionnée ne contient aucun abonné | Les catégories sélectionnées ne contiennent aucun abonné',
      emptyDays: 'Vous devez sélectionner au moins un jour de la semaine.',
    },
    empty: {
      news: "Aucune actualité n'a été trouvée",
      events: "Aucun événement n'a été trouvé",
      pages: "Aucune page n'a été trouvée",
      searchResults: 'Aucune notification ne correspond à votre recherche',
    },
  },
  table: {
    columns: {
      status: 'Statut',
      date: 'Date & Heure',
      message: 'Message',
      categories: 'Catégories',
      target: 'Page Cible',
      sent: 'Envoyées',
      opened: 'Ouvertes',
    },
    tooltips: {
      defaultReccurrent: "Cette notification fait partie d'un groupe de notifications récurrentes.",
      recurrenceCount: 'Notification récurrente ({index}/{total} occurrences)',
    },
  },
  targets: {
    home: 'Accueil',
    actu: 'Actualité',
    agenda: 'Événement',
    page: 'Rubrique',
    external: 'Lien externe',
  },
  sendingModes: {
    now: 'Maintenant',
    later: 'Plus tard',
    recurrent: 'Envoi récurrent',
  },
  scheduleModes: {
    days: 'Tous les jours',
    weeks: 'Toutes les semaines',
    custom: 'Personnaliser',
  },
  sendingInfo: {
    laterOn: 'Les abonnés recevront la notification le {date} à {time}.',
    recurrent: {
      days: 'Les abonnés recevront la notification tous les jours à {time} du {startDate} au {endDate}.',
      weeks: 'Les abonnés recevront la notification tous les {days} à {time} du {startDate} au {endDate}.',
      custom: 'Les abonnés recevront la notification à {time} aux dates suivantes :',
    },
    editDateTime: "Vous pouvez modifier la date d'envoi en dupliquant cette notification et en supprimant l'originale.",
  },
  status: {
    cancelled: 'Annulée',
    pending: 'Programmée',
    sent: 'Envoyée',
    sending: "En cours d'envoi",
  },
  actions: {
    duplicate: 'Dupliquer la notification',
    display: "Afficher la notification sur l'application",
    hide: "Masquer la notification sur l'application",
    delete: 'Supprimer la notification',
    cancel: "Annuler l'envoi de la notification",
    edit: 'Éditer la notification',
    send: 'Envoyer la notification',
    save: 'Sauvegarder les modifications',
    schedule: 'Programmer la notification',
  },
  modals: {
    delete_notification: {
      title: 'Supprimer la notification',
      text: 'Êtes-vous sûr de vouloir supprimer cette notification ?',
      warning:
        "Cette action est irréversible, la notification sera supprimée de l'application de vos utilisateurs et de votre interface d'adminisatration.",
    },
    cancel_notification: {
      title: 'Annuler la notification',
      text: 'Voulez-vous annuler la notification *{title}* ?',
      warning: 'Cette action est irréversible, la notification ne sera plus envoyée à vos utilisateurs.',
    },
    recurrentGroup: {
      delete: {
        title: 'Supprimer une notification récurrente',
        text: "Êtes-vous sûr de vouloir supprimer cette notification ? Cette notification fait partie d'un groupe de notifications récurrentes.",
        options: {
          self: 'Supprimer cette notification seulement',
          all: 'Supprimer toutes les notifications du groupe',
        },
      },
      cancel: {
        title: 'Annuler une notification récurrente',
        text: "Êtes-vous sûr de vouloir annuler l'envoi de cette notification ? Cette notification fait partie d'un groupe de notifications récurrentes.",
        options: {
          self: 'Annuler cette notification seulement',
          all: 'Annuler toutes les notifications du groupe',
        },
      },
      edit: {
        title: 'Éditer une notification récurrente',
        text: "Vous venez d'apporter des modifications à une notification faisant partie d'un groupe de notifications récurrentes.",
        options: {
          self: 'Éditer cette notification seulement',
          all: 'Éditer toutes les notifications du groupe',
        },
      },
    },
  },
  toasts: {
    list: {
      text: "Découvrez le <a href=\"https://neocitybacko.s3.amazonaws.com/neocity/Guide%20des%20bonnes%20pratiques%20Notifications%20Push.pdf\" target='_blank' class='underline'>guide des notifications Neocity</a> : regroupant une multitude de conseils pour rédiger et programmer vos notifications afin d'informer et d'alerter au mieux et au meilleur moment vos utilisateurs",
    },
    featureDisabled: {
      title: "Vous n'avez pas activé la fonctionnalité Notifications. Veuillez contacter un administrateur Neocity",
    },
    notificationSent: {
      title: 'Notification envoyée le : {date} à {hour}',
    },
    notificationCancelled: {
      title: 'Notification annulée le : {date} à {hour}',
    },
  },
  empty: {
    past: 'Aucune notification passée.',
    inComing: 'Aucune notification à venir.',
    no_results: 'Aucune notification pour le moment.',
    no_results_with_filters: 'Aucune notification ne correspond à votre recherche.',
    no_selected_dates: 'Aucune date sélectionnée.',
  },
};
