import { defineStore } from 'pinia';

import { axiosNeocityInstanceV3 } from '@/api/axios';
import * as Sentry from '@sentry/vue';

import { useUser } from '@/stores/user';

import { UploadResponse } from '@/models/upload.model';
import type { Media } from '@/models/medias.model';

export const useMedias = defineStore({
  id: 'medias',
  state: () => ({}),
  getters: {
    clientId(): string | undefined {
      const userStore = useUser();
      return userStore.$state.clientId;
    },
  },
  actions: {
    getMedia(mediaId: string): Promise<Media> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .get(`/clients/${this.clientId}/medias/${mediaId}`)
          .then((response) => {
            if (!response.data) reject();
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    deleteMedia(mediaId: string): Promise<Media> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .delete(`/clients/${this.clientId}/medias/${mediaId}`)
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    postMedia(file: FormData): Promise<UploadResponse> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .post(`/clients/${this.clientId}/medias`, file, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
  },
});
