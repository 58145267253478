export default {
  infoTooltip: {
    text: "Find all the data relating to your activity.<br/> For more detailed information, please visit <a class='underline' href='/statistics'>Statistics</a>.",
  },
  overview: {
    sectionTitle: 'Overview',
    dateFilter: {
      tabs: {
        monthly: '30 days',
        weekly: '7 days',
        yesterday: 'Yesterday',
      },
    },
    downloads: {
      title: 'Download | Downloads',
    },
    sessions: {
      title: 'Session | Sessions',
    },
    pageViews: {
      title: 'Page view | Page views',
    },
    sessionDuration: {
      title: 'Session duration',
    },
    errors: {
      noBigQueryTableId: 'Missing google project id.',
      requestFailed: 'An error occurred while retrieving data.',
    },
  },
  activity: {
    sectionTitle: 'Activity',
    reports: {
      title: 'Reports',
      status: {
        open: 'Untreated',
        pending: 'Pending',
        rejected: 'Rejected',
        finished: 'Finished',
      },
    },
    surveys: {
      title: 'Surveys',
      activeSurveys: 'Active survey | Active surveys',
      noSurveys: {
        title: 'No active surveys',
        text: 'Create one now !',
        cta: 'Create',
      },
    },
    notifications: {
      title: 'Notifications',
      scheduledNotifications: 'Scheduled notification | Scheduled notifications',
      noNotifications: {
        title: 'No scheduled notifications',
        text: 'Create one now !',
        cta: 'Send',
      },
    },
  },
  evolutions: {
    sectionTitle: 'Neocity Updates',
    application: {
      title: 'Mobile Application',
    },
    backOffice: {
      title: 'Back Office',
    },
    filters: {
      all: 'All',
      pending: 'In coming',
      done: 'Available',
    },
    publishedOn: 'Published on',
    errors: {
      emptyInComing: {
        title: 'Neocity has something new in store !',
        text: 'Stay tuned 😉',
      },
      error: {
        title: 'Error.',
        text: 'No data available.',
      },
    },
  },
  prospectSection: {
    title: 'New administration interface',
    text: "To consult your dashboard, please go to the old administration interface.<br/>You'll be able to track the progress of your application.",
    cta: 'Go to the old interface',
  },
};
