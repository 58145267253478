<template>
  <div :class="iconSizeClass">
    <component :is="id + '-icon'" v-if="id" :size="iconSize" :stroke-width="stroke"></component>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  id: {
    type: String,
    default: '',
    required: true,
  },
  size: {
    type: String,
    default: 'medium',
  },
  stroke: {
    type: Number,
    default: 1.5,
  },
});

const iconSize = computed((): string => {
  switch (props.size) {
    case 'small':
      return '12';
    case 'medium':
      return '16';
    case 'large':
      return '24';
    case 'extraLarge':
      return '36';
    default:
      return props.size;
  }
});

const iconSizeClass = computed((): string => {
  switch (props.size) {
    case 'small':
      return 'w-3 h-3';
    case 'medium':
      return 'w-4 h-4';
    case 'large':
      return 'w-6 h-6';
    case 'extraLarge':
      return 'w-9 h-9';
    default:
      return `w-[${props.size}px] h-[${props.size}px]`;
  }
});
</script>
