<template>
  <Modal
    v-model="showDirtyModal"
    :title="$t('errors.common.warning')"
    :cancelText="$t('actions.cancel')"
    :okText="$t('actions.continue')"
    :okButtonProps="{ type: 'primary', color: 'danger' }"
    @onOk="continueWithoutSaving"
    @onCancel="continueEditing"
  >
    {{ $t('errors.save.quitWithoutSave') }}
  </Modal>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useEventBus } from '@vueuse/core';

const showDirtyModal = ref(false);

const saveEvent = useEventBus<string>('save');

onMounted(() => {
  saveEvent.on((_) => {
    if (_ === 'showDirtyModal') showDirtyModal.value = true;
  });
});

const continueWithoutSaving = (): void => {
  showDirtyModal.value = false;
  saveEvent.emit('quitWithoutSave');
};

const continueEditing = (): void => {
  showDirtyModal.value = false;
  saveEvent.emit('continueEditing');
};
</script>
