<!-- This component is used to display a tag indicating the current client status (displayed only for superadmins). -->
<template>
  <Tag v-if="isSuperAdmin" class="hidden capitalize sm:block" :color="statusColor || 'black'">
    {{ $t(statusLabel || 'common.clientStatus.unknown') }}
  </Tag>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useClient } from '@/stores/client';
import { useUser } from '@/stores/user';

import type { FancyColor } from '@/models/colors.model';

const clientStore = useClient();
const userStore = useUser();

const currentClientStatus = computed(() => clientStore.client?.status);
const isSuperAdmin = computed(() => userStore?.user?.role.includes('superadmin'));

const clientStatusInfos = {
  client: {
    label: 'common.clientStatus.client',
    color: 'green',
  },
  prospect: {
    label: 'common.clientStatus.prospect',
    color: 'apricot',
  },
  archived: {
    label: 'common.clientStatus.archived',
    color: 'grey',
  },
  test: {
    label: 'common.clientStatus.test',
    color: 'brown',
  },
  churned: {
    label: 'common.clientStatus.churned',
    color: 'red',
  },
};

const statusLabel = computed<string>(() => (currentClientStatus.value ? clientStatusInfos[currentClientStatus.value]?.label : undefined));
const statusColor = computed<FancyColor>(() => (currentClientStatus.value ? clientStatusInfos[currentClientStatus.value]?.color : 'black'));
</script>
