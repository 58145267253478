<template>
  <Menu as="div" class="relative inline-block w-full text-left" :class="{ 'cursor-not-allowed': props.context.disabled }">
    <div ref="input">
      <FormKit
        v-model="inputValue"
        :type="context.inputType"
        :suffixIcon="context.suffixIcon"
        outerClass="w-full"
        :wrapperClass="context.wrapperClass"
        :placeholder="context.placeholder"
        @input="onInputChanged"
        @focus="setFocus"
        @focusout="setFocus(false)"
      ></FormKit>
    </div>

    <MenuItems
      v-show="
        searchTriggered &&
        isFocused &&
        ((results.length && props?.context?.value?.length >= 4) || isLoading || (!isTyping && !results.length && props?.context?.value?.length >= 4))
      "
      class="absolute right-0 z-20 mt-2 w-full origin-top-right divide-y rounded-sm bg-white shadow-lg focus:outline-none"
      static
    >
      <div class="p-2">
        <div v-if="isLoading">
          <Loading class="my-4"></Loading>
        </div>
        <div v-else class="max-h-[200px] overflow-y-scroll">
          <template v-if="results.length">
            <MenuItem v-for="result in results as Result[]" :key="result.id" class="my-1" @click.prevent="onSelect(result)">
              <button
                class="flex w-full items-center gap-2 overflow-hidden text-ellipsis whitespace-nowrap rounded-sm p-2 text-left hover:bg-neutral-light"
              >
                <span :class="labelClasses.label1Class">{{ result?.label1 }}</span>
                -
                <span v-if="result?.label2" :class="labelClasses.label2Class">{{ result?.label2 }}</span>
                <span v-if="result?.label3" :class="labelClasses.label3Class">({{ result?.label3 }})</span>
              </button>
            </MenuItem>
          </template>
          <span v-else-if="!isTyping" class="p-2 text-sm text-neutral-dark">
            {{ context.emptyText || $t('forms.empty.noResults') }}
          </span>
        </div>
      </div>
    </MenuItems>
  </Menu>
</template>

<script setup lang="ts">
import { Menu, MenuItem, MenuItems } from '@headlessui/vue';
import { computed, ref } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import { get } from 'underscore';

const isLoading = ref(false);
const results = ref([]);
const isFocused = ref(false);
const isTyping = ref(false);
const searchTriggered = ref(false);

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
});

const inputValue = computed({
  get: () => props.context.value,
  set: (value) => {
    props.context.node.input(value);
  },
});

interface Result {
  id: string;
  label1: string;
  label2?: string;
  label3?: string;
  value?: string;
}

const labelClasses = computed(() => ({
  label1Class: props?.context?.label1Class || 'text-sm font-semibold text-neutral-grey',
  label2Class: props?.context?.label2Class || 'text-sm text-neutral-grey',
  label3Class: props?.context?.label3Class || 'text-sm text-neutral-silver',
}));

const serializedResults = (resultsToSerialized: any): any =>
  resultsToSerialized.map((result: { id: string; label1: string; label2: string; label3: string }) => {
    let label1: string | undefined;
    let label2: string | undefined;
    let label3: string | undefined;
    if (props.context.label1Path) label1 = get(result, props.context.label1Path.split('.'), '');
    if (props.context.label2Path) label2 = get(result, props.context.label2Path.split('.'), '');
    if (props.context.label3Path) label3 = get(result, props.context.label3Path.split('.'), '');
    return {
      id: result.id,
      label1,
      label2: label2 ? `${label2.slice(0, 50)}...` : '',
      label3: label3 ? `${label3.slice(0, 30)}...` : '',
    };
  });
const search = useDebounceFn(async () => {
  isTyping.value = false;
  if (!props?.context?.value?.length || props?.context?.value?.length < 4) {
    results.value = [];
    return;
  }
  isLoading.value = true;
  const resultsFound = await props.context.findMethod(props?.context?.value);
  results.value = serializedResults(resultsFound);
  searchTriggered.value = true;
  isLoading.value = false;
}, 500);

const onInputChanged = (value: string): void => {
  if (!isFocused.value) return;
  props.context.node.input(value);
  isTyping.value = true;
  search();
};

const setFocus = (value = true): void => {
  if (props.context.disabled) return;
  // eslint-disable-next-line
  if (!value) setTimeout(() => (isFocused.value = value), 200);
  else isFocused.value = value;
};

const onSelect = (item: Result): void => {
  props.context.node.input(item.label1);
  props?.context?.node?.emit('itemSelected', item.id);
  setFocus(false);
};
</script>
