export default {
  header: {
    dropdownMenu: {
      items: {
        account: 'Mon compte',
        users: 'Utilisateurs',
        appSettings: "Gestion de l'application",
        tutorials: 'Tutoriels et guides',
        helpCenter: "Centre d'aide",
      },
    },
  },

  chat: {
    publish: 'Publier le message',
    publishedBy: 'Publié par',
  },

  sidebar: {
    category: 'Thématique',
    creationDate: 'Date de création',
    history: 'Historique',
    information: 'Informations',
    results: 'Résultats du vote',
    status: 'Statut',
    votingEndDate: 'Date de fin du vote',
    displayTypes: {
      status: 'le statut',
      expireAt: 'la date de fin',
      themeId: 'la thématique',
      parameter: 'le paramètre',
      mediaId: "l'image",
    },
  },

  table: {
    columns: {
      title: 'Titre',
      name: 'Nom',
      description: 'Description',
      color: 'Couleur',
      results: 'Résultats',
      votes: 'Votes',
      createdAt: 'Créé le | Créée le',
      status: 'Statut',
      date: 'Date',
      startDate: 'Date de début',
      endDate: 'Date de fin',
      zones: 'Zones',
      icon: 'Icône',
      usedBy: 'Utilisé par | Utilisée par',
      actions: 'Actions',
      category: 'Catégorie',
      address: 'Adresse',
      updatedAt: 'Date de mise à jour',
      action: 'Action',
      usedOn: 'Utilisé sur | Utilisée sur',
    },
    filters: {
      titles: {
        status: 'Filtre statut',
        date: 'Filtre date',
        category: 'Filtre catégorie',
      },
    },
    status: {
      status_edited_on: 'Statut modifié le',
    },
  },

  timeline: {
    edit: 'a modifié',
    auto_edit: 'a été modifié(e) automatiquement',
    anonymous_edit: 'a été modifié(e) par un utilisateur anonyme',
    create: 'a créé',
    auto_create: 'a été créé(e) automatiquement',
    anonymous_create: 'a été créé(e) par un utilisateur anonyme',
    delete: 'a supprimé',
    auto_delete: 'a été supprimé(e) automatiquement',
    anonymous_delete: 'a été supprimé(e) par un utilisateur anonyme',
    createdFrom: 'a été créé(e) depuis',
    backOffice: "l'interface d'administration",
    app: "l'application mobile",

    tag: {
      archived: 'Archivé',
      inProgress: 'Vote en cours',
      notRetained: 'Non retenu',
      pending: 'En attente',
      rejected: 'Rejeté',
    },

    types: {
      idea: "L'idée",
    },
  },

  preview: {
    pushNotification: {
      now: 'maintenant',
    },
  },

  richTextEditor: {
    defaultPlaceholder: 'Saisissez votre texte ici',
    validation: {
      required: 'Ce champ est obligatoire',
    },
    undo: {
      tooltip: 'Annuler',
    },
    redo: {
      tooltip: 'Rétablir',
    },
    textFormat: {
      options: {
        heading1: 'Titre 1',
        heading2: 'Titre 2',
        heading3: 'Titre 3',
        heading4: 'Titre 4',
        heading5: 'Titre 5',
        heading6: 'Titre 6',
        paragraph: 'Paragraphe',
      },
    },
    textAlign: {
      options: {
        left: 'Gauche',
        center: 'Centre',
        right: 'Droite',
        justify: 'Justifié',
      },
    },
    bold: {
      tooltip: 'Gras',
    },
    italic: {
      tooltip: 'Italique',
    },
    underline: {
      tooltip: 'Souligné',
    },
    strikethrough: {
      tooltip: 'Barré',
    },
    resetFormat: {
      tooltip: 'Supprimer le formatage',
    },
    unorderedList: {
      tooltip: 'Liste à puces',
    },
    orderedList: {
      tooltip: 'Liste numérotée',
    },
    table: {
      tooltip: 'Insérer un tableau',
    },
    separator: {
      tooltip: 'Insérer un séparateur',
    },
    link: {
      tooltip: 'Insérer un lien',
      modal: {
        title: 'Insérer un lien',
        linkTypes: {
          external: 'Lien externe',
          internal: "Lien vers une page de l'application",
        },
        internalLabel: 'Page de l’application',
        labelLabel: 'Libellé',
        labelHelp: "Le texte qui s'affichera. Si aucun libellé n'est renseigné, le lien sera affiché tel quel.",
        urlLabel: 'URL',
        edit: 'Modifier',
        validate: 'Insérer',
      },
    },
    image: {
      tooltip: 'Insérer une image',
      modal: {
        title: 'Insérer une image',
        uploadButton: 'Choisir un fichier',
        customDimensions: 'Dimensions personnalisées',
        validate: 'Insérer',
      },
    },
    iframe: {
      tooltip: 'Insérer un iframe',
      modal: {
        title: 'Insérer un iframe',
        srcLabel: 'URL',
        customDimensions: 'Dimensions personnalisées',
        validate: 'Insérer',
      },
    },
    video: {
      tooltip: 'Insérer une vidéo',
    },
    emoji: {
      tooltip: 'Insérer un emoji',
    },
    code: {
      tooltip: 'Éditer le code',
    },
  },
  openingHours: {
    labels: {
      open24h: 'Ouvert 24h/24',
      openingHours: 'Horaires',
    },
    placeholders: {
      description: 'Description facultative',
    },
  },
  maps: {
    drawPolygonMap: {
      actions: {
        drawPolygon: 'Dessiner un polygone',
        deleteSeleted: 'Supprimer la sélection',
        importZone: 'Importer une zone',
      },
      legend: {
        currentZone: 'Zone active',
        selectedZones: 'Zones sélectionnées',
        existingZones: 'Zones existantes',
      },
      tooltips: {
        info: 'Dessinez une zone en cliquant sur le bouton <b>Dessiner un polygone</b>.</br> Veillez à ce que la zone ne chevauche pas une zone existante (représentées en gris sur la carte).',
        valid: 'Félicitations ! Votre tracé est valide',
        error: 'Votre tracé est invalide. Veillez à ce que la nouvelle zone ne chevauche pas une zone existante.',
        superAdminFeature: 'Fonctionnalité réservée aux super-administrateurs',
      },
      errors: {
        noZone: 'Veuillez renseigner une zone.',
        polygonLimitReached:
          'Vous avez atteint le nombre maximum de polygones que vous pouvez dessiner. Vous pouvez en supprimer un pour en dessiner un nouveau.',
      },
    },
  },
};
