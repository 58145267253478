export default {
  add_idea: 'Add an idea',
  add_category: 'Add a theme',
  create_category: 'Create a theme',
  add_recipient: 'Add a recipient',
  save_category: 'Save the Category',
  save_idea: 'Save the idea',
  without_category: 'Without theme',
  theIdea: 'the idea',
  generate_IframeCode: 'Generate Iframe code',
  status: {
    pending: 'Pending',
    voting: 'Voting',
    studying: 'Studying',
    notRetained: 'Not retained',
    inProgress: 'In progress',
    realised: 'Realised',
    rejected: 'Rejected',
    archived: 'Archived',
  },
  vote: {
    voting: 'Voting',
    cancelled: 'Vote cancelled',
  },
  filters: {
    show_only_pending: 'Show only "Pending" ideas',
  },
  errors: {
    get_ideas_failed: 'Impossible to recover the ideas',
  },
  empty: {
    no_messages: 'No message published for the moment.',
    no_results: 'No idea at the moment.',
    no_results_with_filters: 'No idea corresponds to your search.',
    no_results_with_pending_filter: 'No idea with "Pending" status.',
    no_recipients: 'No recipient at the moment.',
    no_default_recipients: 'No default recipient for the moment.',
    no_thematics: 'No theme for the moment.',
    no_thematics_with_filters: 'There are no themes that match your search.',
  },
  modals: {
    status: {
      checkbox: 'Notify the participant of the change in status',
      desc: 'You can <strong>notify the participant</strong> of the status change by checking the box below.',
      title: 'Status change',
    },
    delete_category: {
      title: 'Delete the category',
      text: 'Do you want to delete the {theme} category?',
      warning:
        '0 |{count} idea associated with this category will be kept but will no longer have a category. |{count} ideas associated with this category will be kept but will no longer have a category.',
    },
    iframe: {
      tip: 'Please add an "e-mail" or "telephone number" field to enable us to check for duplicates. Otherwise, you run the risk of multiple entries.',
      htmlcode: 'HTML code for citizen participation',
      backgroundcolor: 'Background color',
    },
  },
};
