<template>
  <div
    class="relative flex h-screen items-center justify-center"
    :class="{
      'bg-neutral-black': backgroundColor === 'dark',
    }"
  >
    <slot />
    <div v-if="showCopyRight" class="absolute bottom-8 left-[50%] z-10 translate-x-[-50%] text-xs text-neutral-grey">
      © 2014-{{ new Date().getFullYear() }} Neocity S.A.S
    </div>
    <img
      class="absolute left-0 top-0 z-0 h-full w-full object-cover object-center"
      :src="backgroundColor === 'light' ? hexaBackgroundLight : hexaBackgroundDark"
      alt=""
    />
  </div>
</template>

<script setup lang="ts">
import hexaBackgroundLight from '@/assets/img/hexa-background-light.svg';
import hexaBackgroundDark from '@/assets/img/hexa-background-dark.svg';
</script>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RestrictedLayout',
  props: {
    backgroundColor: {
      type: String,
      default: 'light',
      validator: (value: string) => ['dark', 'light'].includes(value),
    },
    showCopyRight: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
