<template>
  <component
    :is="componentName"
    class="group flex w-fit items-center justify-center"
    :class="[styleClasses, backgroundColorClass]"
    :href="url || '#'"
    :target="target"
  >
    <Icon v-if="iconId" :id="iconId" :class="iconColorClass" :size="size" :stroke="iconStroke" />
  </component>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import type { PropType } from 'vue';
import type { FancyColor } from '@/models/colors.model';
import { getFancyColorClass } from '@/utils/colors';

const props = defineProps({
  iconId: {
    type: String,
    default: 'bell',
    required: true,
  },
  iconStroke: {
    type: Number,
    default: 1,
  },
  url: {
    type: String,
    default: '',
  },
  target: {
    type: String,
    default: '_self',
  },
  size: {
    type: String,
    default: 'medium',
    validator(value: string) {
      return ['small', 'medium', 'large', 'extraLarge'].includes(value);
    },
  },
  color: {
    type: String as PropType<FancyColor>,
    default: 'blue',
  },
  variant: {
    type: String,
    default: 'circle',
    validator(value: string) {
      return ['circle', 'rounded'].includes(value);
    },
  },
});

const iconColorClass = computed(() => getFancyColorClass(props.color, 'text'));
const backgroundColorClass = computed(() => getFancyColorClass(props.color, 'background'));

const styleClasses = computed(() => ({
  'rounded-full': props.variant === 'circle',
  'rounded-md': props.variant === 'rounded',
  'p-1': props.size === 'small',
  'p-2': props.size === 'medium' || props.size === 'large',
  'p-3': props.size === 'extraLarge',
}));

const componentName = computed(() => (props.url ? 'a' : 'div'));
</script>
