<template>
  <Component :is="componentName" :disabled="disabled" :to="to" :class="buttonClasses" type="button">
    <Icon v-if="loading && !disabled" id="loader-quarter" class="animate-spin" :size="iconSize"></Icon>
    <Icon v-else-if="icon" :id="icon" :size="iconSize" :stroke="iconStroke" />
    <slot v-if="!iconOnly" />
  </Component>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue';

import Link from '@/components/atoms/Link.vue';

const props = defineProps({
  type: {
    type: String,
    default: 'primary',
    validator(value: string) {
      return ['primary', 'secondary', 'tertiary', 'link'].includes(value);
    },
  },
  color: {
    type: String,
    default: 'default',
    validator(value: string) {
      return ['default', 'success', 'danger', 'warning'].includes(value);
    },
  },
  size: {
    type: String,
    default: 'medium',
    validator(value: string) {
      return ['small', 'medium', 'large'].includes(value);
    },
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: '',
  },
  iconStroke: {
    type: Number,
    default: 1.5,
  },
  iconPosition: {
    type: String,
    default: 'left',
    validator(value: string) {
      return ['left', 'right'].includes(value);
    },
  },
  loading: {
    type: Boolean,
    default: false,
  },
  to: {
    type: [String, Object],
    default: '',
  },
  noPadding: {
    type: Boolean,
    default: false,
  },
  disableClick: {
    type: Boolean,
    default: false,
  },
  // This property is optional, should be used only when the button has a conditional default slot content
  iconOnly: {
    desc: 'enables a smaller icon size when the button has no text',
    type: Boolean,
    default: false,
  },
});

const slots = useSlots();

const componentName = props.to ? Link : 'button';

const buttonClasses = computed(() => ({
  // Global
  'flex flex-row justify-center items-center gap-2 font-semibold rounded-sm transition duration-200 cursor-pointer': true,

  // Global Types
  'text-white fill-white': props.type === 'primary',
  'text-neutral-black bg-neutral-light': props.type === 'secondary',
  'text-neutral-black': props.type === 'link',

  // Default Color
  'bg-base-basic hover:bg-base-dark': props.color === 'default' && props.type === 'primary',
  'hover:text-base-basic hover:fill-base-basic':
    props.color === 'default' && (props.type === 'secondary' || props.type === 'link') && !props.disabled,
  'bg-base-light text-base-basic hover:text-base-dark': props.color === 'default' && props.type === 'tertiary',

  // Success Color
  'bg-success-basic hover:bg-success-dark': props.color === 'success' && props.type === 'primary',
  '!text-success-basic hover:!text-success-dark fill-success-basic hover:fill-success-dark':
    props.color === 'success' && (props.type === 'secondary' || props.type === 'link') && !props.disabled,
  'bg-success-light text-success-basic hover:text-success-dark': props.color === 'success' && props.type === 'tertiary',

  // Danger Color
  'bg-danger-basic hover:bg-danger-dark': props.color === 'danger' && props.type === 'primary',
  '!text-danger-basic hover:!text-danger-dark fill-danger-basic hover:fill-danger-dark':
    props.color === 'danger' && (props.type === 'secondary' || props.type === 'link') && !props.disabled,
  'bg-danger-light text-danger-basic hover:text-danger-dark': props.color === 'danger' && props.type === 'tertiary',

  // Warning Color
  'bg-warning-basic hover:bg-warning-dark': props.color === 'warning' && props.type === 'primary',
  '!text-warning-basic hover:!text-warning-dark fill-warning-basic hover:fill-warning-dark':
    props.color === 'warning' && (props.type === 'secondary' || props.type === 'link') && !props.disabled,
  'bg-warning-light text-warning-basic hover:text-warning-dark': props.color === 'warning' && props.type === 'tertiary',

  // Disabled
  '!text-neutral-silver !hover:!text-neutral-silver cursor-not-allowed': props.disabled,
  '!bg-neutral-metal hover:bg-neutral-metal': props.disabled && props.type === 'primary',
  '!bg-neutral-light hover:bg-neutral-light': props.disabled && props.type === 'tertiary',

  // Sizes
  'py-1.5 px-5 text-xs': props.size === 'small',
  '!py-1 !px-1': props.size === 'small' && (!slots.default || props.iconOnly) && (props.icon || props.loading),
  'py-2 px-5 text-sm': props.size === 'medium',
  '!py-[5px] !px-[5px]': props.size === 'medium' && (!slots.default || props.iconOnly) && (props.icon || props.loading),
  'py-2.5 px-5 text-sm': props.size === 'large',
  '!py-[7px] !px-[7px]': props.size === 'large' && (!slots.default || props.iconOnly) && (props.icon || props.loading),

  // No Padding
  '!p-0': props.noPadding,

  // Icon Position
  'flex-row-reverse': props.iconPosition === 'right',

  // Disable Click
  'pointer-events-none': props.disableClick,
}));

const iconSize = computed(() => {
  switch (props.size) {
    case 'small':
      return !slots.default || props.iconOnly ? 'medium' : 'small';
    case 'medium':
      return !slots.default || props.iconOnly ? 'large' : 'medium';
    case 'large':
      return !slots.default || props.iconOnly ? 'large' : 'medium';
    default:
      return !slots.default || props.iconOnly ? 'large' : 'medium';
  }
});
</script>
