<template>
  <a
    v-if="isA"
    :class="linkClass"
    :target="props.blank ? '_blank' : '_self'"
    :rel="props.blank ? 'noopener' : ''"
    :tab-index="props.disabled ? -1 : null"
    :aria-disabled="props.disabled"
    :href="props.to || '#'"
    @click="onClick"
  >
    <slot />
  </a>
  <RouterLink v-else :class="linkClass" :to="props.to" exact @click="onClick">
    <slot />
  </RouterLink>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const props = defineProps({
  to: { type: [String, Object], default: '' },
  button: Boolean,
  blank: Boolean,
  active: Boolean,
  activeClass: String,
  disabled: Boolean,
});

const emits = defineEmits(['click']);

const isA = ref(typeof props.to === 'string');

const linkClass = (): string => {
  const activeClass = props.activeClass || (props.button ? '' : 'text-base-basic');
  if (props.active) return activeClass;
  if (activeClass) return `transition-all hover:${activeClass}`;

  return '';
};

const onClick = (e: MouseEvent): void => {
  if (!props.to) e.preventDefault();
  if (!props.disabled) emits('click', e);
};
</script>
