export default {
  infoTooltip: {
    text: "Retrouvez l'ensemble des données relatives à votre activité.<br/> Pour plus d'informations détaillées, nous vous invitons à consulter la rubrique <a class='underline' href='/statistics'>Statistiques</a>.",
  },
  overview: {
    sectionTitle: "Vue d'ensemble",
    dateFilter: {
      tabs: {
        monthly: '30 jours',
        weekly: '7 jours',
        yesterday: 'Hier',
      },
    },
    downloads: {
      title: 'Téléchargement | Téléchargements',
    },
    sessions: {
      title: 'Ouverture | Ouvertures',
    },
    pageViews: {
      title: 'Page vue | Pages vues',
    },
    sessionDuration: {
      title: 'Durée de session',
      tooltip: 'Correspond à la durée de session par utilisateur en moyenne.',
    },
    errors: {
      noBigQueryTableId: 'Identifiant de projet google manquant.',
      requestFailed: 'Une erreur est survenue lors de la récupération des données.',
    },
  },
  activity: {
    sectionTitle: 'Activité',
    reports: {
      title: 'Signalements',
      status: {
        open: 'Non traité | Non traités',
        pending: 'En cours',
        rejected: 'Rejeté | Rejetés',
        finished: 'Terminé | Terminés',
      },
    },
    surveys: {
      title: 'Sondages',
      activeSurveys: 'Sondage en cours | Sondages en cours',
      noSurveys: {
        title: 'Aucun sondage en cours',
        text: 'Créez-en un dès maintenant !',
        cta: 'Créer',
      },
    },
    notifications: {
      title: 'Notifications',
      scheduledNotifications: 'Notification programmée | Notifications programmées',
      noNotifications: {
        title: 'Aucune notification programmée',
        text: 'Créez-en une dès maintenant !',
        cta: 'Envoyer',
      },
    },
  },
  evolutions: {
    sectionTitle: 'Évolutions Neocity',
    application: {
      title: 'Application mobile',
    },
    backOffice: {
      title: "Interface d'administration",
    },
    filters: {
      all: 'Toutes',
      pending: 'À venir',
      done: 'Disponibles',
    },
    publishedOn: 'Publié le',
    errors: {
      emptyInComing: {
        title: 'Des nouveautés se préparent chez Neocity !',
        text: 'Restez attentifs 😉',
      },
      error: {
        title: 'Erreur.',
        text: 'Aucune donnée.',
      },
    },
  },
  prospectSection: {
    title: "Nouvelle interface d'administration",
    text: "Pour consulter votre tableau de bord, nous vous invitons à vous rendre sur l'ancienne interface d'administration.<br />Vous pourrez y retrouver la progression de votre application.",
    cta: 'Accéder à l’ancienne interface d’administration',
  },
};
