import { defineStore } from 'pinia';

import { axiosNeocityInstance } from '@/api/axios';

import { useUser } from '@/stores/user';

import * as Sentry from '@sentry/vue';

import type { App } from '@/models/app.model';

interface State {
  app: App | null;
}

export const useApps = defineStore({
  id: 'apps',
  state: (): State => ({
    app: null,
  }),
  getters: {
    clientId(): string | undefined {
      const userStore = useUser();
      return userStore.$state.clientId;
    },
    userId(): string | undefined {
      const userStore = useUser();
      return userStore.$state.user?.id;
    },
  },
  actions: {
    getApp(clientId: string): Promise<App[]> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .get('/apps/findOne', {
            params: {
              filter: {
                where: {
                  clientId,
                },
              },
            },
          })
          .then((response) => {
            if (!response.data) return;
            this.app = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            this.app = null;
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
  },
  persist: true,
});
