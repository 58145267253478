export default {
  addInfrastructure: 'Ajouter une infrastructure',
  forms: {
    blocks: {
      presentation: {
        title: 'Présentation',
      },
      contact: {
        title: 'Coordonnées',
      },
      schedule: {
        title: 'Horaires',
      },
      informations: {
        title: 'Informations',
      },
    },
    placeholder: {
      infrastructureName: "Supermarché de l'Avenue Principale",
      infrastructureNameGarbage: "Déchèterie de l'Avenue Principale",
      category: 'Choisir une catégorie',
      chooseAnIcon: 'Choisir une icône',
      acceptedWaste: 'Liste des déchets acceptés',
      rejectedWaste: 'Liste des déchets refusés',
      landfill: 'Déchèterie',
      search: 'Rechercher ...',
    },
    label: {
      infrastructureName: 'Nom de l’infrastructure',
      tempInfra: 'Infrastructure temporaire',
      displaySchedule: 'Afficher les horaires',
      acceptedWaste: 'Déchets acceptés',
      rejectedWaste: 'Déchets refusés',
      wasteDescription: 'Description',
      addCategory: 'Ajouter une catégorie',
      createCateory: 'Créer une catégorie',
      details: 'détails',
      title: 'titre',
      from: 'Du',
      until: "Jusqu'au",
      from2: 'De',
      until2: "Jusqu'à",
      pickInfrastructruesFromExisitng: 'Choisir une infrastructure parmi vos infrastructures existantes',
      createNewInfrastructure: 'Créer une nouvelle infrastructure',
      importInfraViaCsv: 'Importer des infrastructures via un fichier CSV',
      on: 'Le',
      addAnInfrastructure: 'Ajouter une infrastructure',
      addOns: 'Modules complémentaires',
      addOnsOptions: {
        garbage: 'Collecte des déchets',
      },
    },
    errors: {
      nameRequired: 'Veuillez entrer un nom valide',
      categoryRequired: 'Veuillez sélectionner une catégorie',
      colorRequired: 'Veuillez sélectionner une couleur',
      iconRequired: 'Veuillez sélectionner une icône',
      acceptedWaste: 'Veuillez renseigner la liste des déchets acceptés',
      rejectedWaste: 'Veuillez renseigner la liste des déchets refusés',
      saveSchedule: 'Veuillez enregistrer les horaires',
    },
  },
  scheduleModes: {
    schedule: 'Horaires',
    exceptionalSchedule: 'Horaires exceptionnels',
    repeatSchedule: 'Toute date ajoutée sera répétée chaque année.',
    closedOnPublicHolidays: 'Fermé les jours feriés',
    date: 'Date',
    addADate: 'Ajouter une date',
    addADateRange: 'Ajouter une plage de dates',
    addASchedule: 'Ajouter un horaire',
  },
  empty: {
    no_results: 'Aucune infrastructure pour le moment.',
    no_results_with_filters: 'Aucune infrastructure ne correspond à votre recherche.',
    no_categories: 'Aucune catégorie',
    no_address: 'Adresse non renseignée',
  },
  importCsv: {
    importInfrastructures: 'Importer des infrastructures',
    importTooltip: 'Importez votre fichier CSV pour créer vos infrastructures.',
    facilitateInfraImport: 'Pour faciliter l’import d’infrastructures,',
    downloadXlsx: 'téléchargez le modèle par défaut au format .xlsx',
    convertToCsv: 'Convertissez ensuite le fichier au format .csv à l’import.',
    import: 'Importer',
    confirm: 'Confirmer',
    importCsvFile: 'Importer un fichier csv',
    importReport: 'Compte rendu de l’importation,',
    infrastructuresFound: 'infrastructure trouvée | infrastructures trouvées',
    importedInfrastructures: 'infrastructure importée | infrastructures importées',
    infrastructuresContainingErrors: 'infrastructure contenant des erreurs | infrastructures contenant des erreurs',
    defaultValueAssign: 'La valeur par défaut a été assignée aux champs en erreur.',
    nonImportedInfras: 'Infrastructure non importée | Infrastructures non importées',
  },
  toasts: {
    addInfra:
      'Vous pouvez trier vos infrastructures par ordre alphabétique croissant ou décroissant. Attention, dans l’application mobile, les infrastructures seront affichées par défaut dans l’ordre alphabétique, par catégorie puis par nom.',
  },
  modals: {
    deleteInfrastructure: {
      title: "Supprimer l'infrastructure | Supprimer { count } infrastructures",
      toast: 'Êtes-vous sûr(e) de vouloir supprimer cette infrastructure ? | Êtes-vous sûr(e) de vouloir supprimer ces infrastructures ?',
      description:
        "La suppression est irréversible. L'infrastructure sera supprimée de votre interface d'administration et de votre application citoyenne. | La suppression est irréversible. Les infrastructures seront supprimées de votre interface d'administration et de votre application citoyenne.",
    },
  },
  addOnNames: {
    garbage: 'Gestion des déchets',
    parking: 'Parking',
    parkingPMR: 'Parking PMR',
  },
  actions: {
    editInfrastructure: "Modifier l'infrastructure",
    deleteInfrastructure: "Supprimer l'infrastructure",
  },
};
