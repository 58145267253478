<template>
  <div
    class="flex w-full cursor-pointer items-center overflow-hidden rounded-sm bg-neutral-light px-2.5 py-2 formkit-disabled:border-neutral-cloud formkit-disabled:bg-neutral-cloud"
    @click.stop="handleInput(!props.context._value)"
  >
    <span
      class="font-regular w-full cursor-pointer select-none bg-transparent text-base font-normal text-neutral-black placeholder-neutral-silver outline-none formkit-disabled:cursor-not-allowed formkit-disabled:text-neutral-grey formkit-invalid:text-neutral-black"
    >
      {{ props.context._value ? onText : offText }}
    </span>
    <Switch :modelValue="props.context._value" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
});

const onText = computed(() => props?.context?.onText || t('common.activated'));
const offText = computed(() => props?.context?.offText || t('common.deactivated'));

const handleInput = (value: boolean): void => {
  props.context.node.input(value);
};
</script>
