export default {
  fileUpload: {
    uploadImage: 'Upload an image',
    weight: 'Weight',
    size: 'Size',
    no_image: 'No image',
    defaultTitle: "Header's image",
    errors: {
      restrictions: {
        title: 'Unable to upload the image',
        message: 'Please upload an image less than {maxSize} in {formats} format',
      },
    },
  },

  iconpicker: {
    title: 'Icon',
    search_placeholder: 'Search for an icon',
    no_result: 'No result',
  },

  timelineitem: {
    no_history: 'No history to display',
  },

  statistic: {
    error: 'Error.',
    periods: {
      yesterday: 'yesterday',
      lastWeek: 'on 7 days',
      lastMonth: 'on 30 days',
    },
  },

  doughnutChart: {
    total: 'total',
  },
};
